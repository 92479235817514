import styled from 'styled-components';

// eslint-disable-next-line camelcase

export const Heading = styled.h1`
  position: static;
  width: 712px;
  height: 58px;

  font-family: Cabin;
  font-style: normal;

  font-weight: bold;
  font-size: 48px;
  line-height: 120%;
  /* identical to box height, or 58px */

  display: flex;
  align-items: center;
  letter-spacing: -0.003em;

  color: ${(props) => props.color || ''};
  margin: ${(props) => props.m || ' '};

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
   @media (max-width: 375px) {
    height: ${(props) => props.h375 || '86px'};
    width: ${(props) => props.w375 || '327px '};
    font-size: 36px;
     margin-top:22px;
  }
  @media (max-width: 700px) {
    height: ${(props) => props.h700 || 'auto'};
    width: ${(props) => props.w700 || 'auto'};
    margin: 58px 12px 36px 14px;
    font-size: 36px;
    margin-top:22px;
  }
`;

export const HeadingTwo = styled.h2`
  position: static;
  width: 660px;
  height: 36px;
  left: 0px;
  top: 0px;

  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  /* identical to box height, or 36px */

  display: ${(props) => props.display || 'flex'}; 
  align-items: center;
  letter-spacing: -0.003em;

  color: ${(props) => props.color || ' '};

  /* Inside auto layout */

  margin: ${(props) => props.m || ' 0px 12px'};

 @media (max-width: 700px) {
    position: static;
     width: 380px;
    height: ${(props) => props.h700 || ' '};
    font-size:${(props) => props.fz700 || '26px '};
    margin-left: ${(props) => props.ml700 || ' '};
    margin-bottom: ${(props) => props.mb700 || '  '};
   margin-top: ${(props) => props.mt700 || '  '};
    margin-right: ${(props) => props.mr700 || '  '};
 } 

  @media (max-width: 425px) {
   width: ${(props) => props.w425 || '330px'};
   height: ${(props) => props.h425 || ' '};
    margin-left: ${(props) => props.ml425 || ' '};
   margin: ${(props) => props.m425 || ' '};
  }
  @media (max-width: 395px) {
   width: ${(props) => props.w395 || '300px'};
    height: ${(props) => props.hm || ' '};
    margin-left: ${(props) => props.ml395 || ' '};
  }
   @media (max-width: 375px) {
   width: ${(props) => props.w375 || '276px'};
   height: ${(props) => props.hm || ' '};

  margin-left: ${(props) => props.ml375 || ' '};

  } 
  
`;

export const ContainerSections = styled.div`

margin-top:${(props) => props.mtWeb || ' '};


  @media (max-width: 700px) {
      margin-top:${(props) => props.mtMob || ' '};
      width: 100%;
 }
`;

export const Description = styled.p`

  width: 712px;
  height: 69px;
  font-style: normal;
  font-weight: normal;
  font-size:${(props) => props.fz || '20px '};
  line-height: ${(props) => props.lh || '23px '};
  display: ${(props) => props.display || 'flex'};
  align-items: center;
  letter-spacing: -0.003em;
  color: ${(props) => props.color || ' '};
  margin: ${(props) => props.m || '24px 0px '};
  
  @media (max-width: 700px) {
    display: block;
    width:${(props) => props.w700 || '92%'};
    height: ${(props) => props.h700 || 'auto'};
    font-size:${(props) => props.fz700 || ' '};
    margin: ${(props) => props.mD700 || ' '};
    padding-top: ${(props) => props.p700 || ''};
 }
`;

export const ContentGeneric = styled.div`
  display: ${(props) => props.dis || ''};
  margin-top: ${(props) => props.mt || ''};
  flex-direction: ${(props) => props.fd || 'row'};
  align-items: ${(props) => props.alignItems || ''};
  align-self: ${(props) => props.alignSelf || ''};
  text-align: ${(props) => props.textAlign || ''};
  margin: ${(props) => props.margin || '0px 0px 48px'};
  margin-bottom:${(props) => props.mb || ''};
  padding: ${(props) => props.padding || ''};
  width: ${(props) => props.width || ''};
  max-width: ${(props) => props.maxWidth || ''};
  max-height: ${(props) => props.maxHeight || ''};
  min-height: ${(props) => props.minHeight || ''};
  height: ${(props) => props.height || ''};
  position: ${(props) => props.position || ''};
  background-color: ${(props) => props.background || ' '};
  background: ${(props) => props.backg || ''};
  top: ${(props) => props.top || ''};
  right: ${(props) => props.right || ''};
  left: ${(props) => props.left || ''};
  bottom: ${(props) => props.bottom || ''};
  overflow: ${(props) => props.overflow || ''};
  border-radius: ${(props) => props.borderR || ''};
  border: ${(props) => props.borderC || ''};
  padding-top:${(props) => props.pt || ''};
  justify-content: ${(props) => props.justifyC || ''};
  gap: ${(props) => props.gap || ''};
  color: ${(props) => props.color || ''};
  z-index: ${(props) => props.zIndex || ''};
  box-shadow: ${(props) => props.boxShadow || ''};
   @media (max-width: 375px) {
     margin: ${(props) => props.margin || '0px 0px 38px'};
    position: ${(props) => props.positionSm || ''};
    top: ${(props) => props.topSm || ''};
    bottom: ${(props) => props.bottomSm || ''};
    width: ${(props) => props.w375 || ''};
   
  }
  @media (max-width: 700px) {
    margin: ${(props) => props.margin700 || '0px 0px 0px'};
   position: ${(props) => props.position700 || ''};
   top: ${(props) => props.top700 || ''};
   bottom: ${(props) => props.bottom700 || ''};
   width: ${(props) => props.w700 || ''};
    height: ${(props) => props.height700 || ''};
    right: ${(props) => props.right700 || ''};
   padding-top:${(props) => props.ptmobile || ''};
 }
  @media (max-height: 600px) {
    height: ${(props) => props.heightXS || ''};
  }
  @media (min-width: 530px) {
    margin: ${(props) => props.marginSm || ''};
    position: ${(props) => props.positionSm || ''};
    top: ${(props) => props.topSm || ''};
    bottom: ${(props) => props.bottomSm || ''};
    width: ${(props) => props.widthSm || ''};
  }
  @media (min-width: 414px) {
    margin: ${(props) => props.marginSm4 || ''};
    position: ${(props) => props.positionSm4 || ''};
    top: ${(props) => props.topSm4 || ''};
    bottom: ${(props) => props.bottomSm4 || ''};
    width: ${(props) => props.widthSm4 || ''};
  }
  @media (max-width: 700px) {
    margin: ${(props) => props.marginM || ''};
    position: ${(props) => props.positionM || ''};
    top: ${(props) => props.topM || ''};
    bottom: ${(props) => props.bottomM || ''};
    width: ${(props) => props.widthM || ''};
    padding: ${(props) => props.paddingM || ''};
  }
  @media (max-width: 767px) {
    margin: ${(props) => props.marginMxS || ''};
    position: ${(props) => props.positionMxS || ''};
    top: ${(props) => props.topMxS || ''};
    bottom: ${(props) => props.bottomMxS || ''};
    width: ${(props) => props.widthMxS || ''};
    display: ${(props) => props.displayxS || ''};
    padding: ${(props) => props.paddingMxS || ''};
  }
  @media (min-width: 768px) {
    margin: ${(props) => props.marginMd || ''};
    position: ${(props) => props.positionMd || ''};
    top: ${(props) => props.topMd || ''};
    bottom: ${(props) => props.bottomMd || ''};
    width: ${(props) => props.widthMd || ''};
    height: ${(props) => props.heightMd || ''};
    right: ${(props) => props.rightMd || ''};
    padding: ${(props) => props.paddingMd || ''};
  }
  @media (min-width: 925px) {
    margin: ${(props) => props.marginLg || ''};
    position: ${(props) => props.positionLg || ''};
    top: ${(props) => props.topLg || ''};
    bottom: ${(props) => props.bottomLg || ''};
  }
  @media (min-width: 1405px) {
    margin: ${(props) => props.marginXl || ''};
    position: ${(props) => props.positionXl || ''};
    top: ${(props) => props.topXl || ''};
    bottom: ${(props) => props.bottomXl || ''};
  }
`;

export const VideoI = styled.iframe`
   width: 711px;
   height: 389px;
   border:none;
   @media (max-width: 375px) {
    width: 320px;
    height:204px;
  }
  @media (max-width: 700px) {
    width: 90%;
    height:204px;
    margin: 14px;
    display: flex;
  }
`;

export const Container1 = styled.div`
/* 70% w */
 width: ${(props) => props.w || ' '};
  margin: ${(props) => props.margin || ' '};
  margin-top: ${(props) => props.mt || ' '};
  margin-left: ${(props) => props.ml || ''};
  display:  ${(props) => props.dis || 'flex'};
  flex-direction: ${(props) => props.fd || 'column '};
  /* gap: 24px; */
  background: transparent;

    @media (max-width: 375px) {
    width: ${(props) => props.w375 || ' '};
    margin-left: ${(props) => props.mleft375 || ' '};
    margin: ${(props) => props.margin375 || ' '};
    display:  ${(props) => props.dis375 || ' '};
    height:${(props) => props.h375 || ' '};
   
  }
  @media (max-width: 700px) {
    width: ${(props) => props.w700 || ' '};
    margin-left: ${(props) => props.mleft700 || ' '};
    margin: ${(props) => props.margin700 || ' '};
    display:  ${(props) => props.dis700 || ' '};
    height:${(props) => props.h700 || ' '};
    
   
  }
  
     @media (min-width: 768px) {
   display:  ${(props) => props.dis768 || ' '};
  } 
  /* @media (min-width: 769px) {
    width: ${(props) => props.width || '100%'};
    /* 24px auto 0 auto *
    margin: ${(props) => props.margin || ''};
    display: flex;
    flex-direction: ${(props) => props.fd769 || 'column '};
    /* gap: 24px; 
  } */
`;

// PlaticaConExpertos

export const ContainerExpertos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 712px;
  height: 140.09px;
  align-self: stretch;
  margin: 24px 0px;
    @media (max-width: 375px) {
     width:372px;  
     margin: 0px 10px 30px 10px;; 
  }
  @media (max-width: 700px) {
    width:100%;   
 }
`;

export const Text3 = styled.p`
  width: 558px;
  height: 42px;
  /* left: 0px;
top: 49.04px; */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.003em;
  color: #80460c;
  margin:${(props) => props.m || '0px 24px'};
     @media (max-width: 375px) {
     width:${(props) => props.w375 || '200px'};
     height: ${(props) => props.h375 || '126px '};
     font-size:${(props) => props.fz375 || ' '};
     margin: ${(props) => props.mD375 || '10px 0px 2px 17px'};
     
  }
  @media (max-width: 700px) {
    width:${(props) => props.w700 || '100%'};
    height: ${(props) => props.h700 || 'auto'};
    font-size:${(props) => props.fz700 || ' '};
    margin: ${(props) => props.mD700 || '20px 0px 2px 13px'};
    
 }
`;

export const ImgExperto = styled.img`
  width: 130px;
  height: 140.09px;
  margin: 0px 0px 0px 0px;
  @media (max-width: 769px) {
    margin: ${(props) => props.marginMob || ''};
  }
  @media (max-width: 700px) {
    width: 100px;
    margin: ${(props) => props.marginM || 'auto'};
  }
`;

export const CardGenericTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 21px;
  width: 712px;
  height: 203.64px;

  background: rgba(255, 255, 255, 0.69);
  /* Coru_Grey2 */

  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 16px;

  /* Inside auto layout */
  align-self: stretch;
  margin-top: 12px;
   @media (max-width: 375px) {
    width: ${(props) => props.w375 || '306px '};
    margin-left: ${(props) => props.mleft375 || ' '};
    margin: ${(props) => props.margin375 || ' '};
    height: auto;
  }
  @media (max-width: 700px) {
    width: ${(props) => props.w700 || '95%'};
    margin-left: ${(props) => props.mleft700 || ' '};
    margin: ${(props) => props.margin700 || '12px auto'};
    height: auto;
  }
`;

// Style Conoce soluciones rápidas

export const CardWo = styled.div`
  display: flex;
  display: row;
  margin: 10px 0px;
  width: 548px;
  padding: 0px;
  bottom: 35.19%;
  height: 128px;
  background: #ffffff;
  box-shadow: -4px 4px 12px rgba(57, 58, 128, 0.15),
    4px 4px 12px rgba(57, 58, 128, 0.15);
  border-radius: 16px;
   @media (max-width: 375px) {
    width: 90%;
    height: 110px;
    margin: 24px auto auto auto;
  }
@media (max-width: 700px) {
    width: 90%;
    height: 110px;
    margin: 24px auto auto auto;
  }
  

  
  
`;

export const Text2 = styled.p`
  width: ${(props) => props.width || '370px'} ;
  font-style: normal;
  font-weight:${(props) => props.fw || 'bold'};
  font-size: ${(props) => props.fz || '20px'};
  line-height: 120%;
  letter-spacing: -0.3px;
  color: ${(props) => props.color || '#07210A'};
  margin: ${(props) => props.m || '45px 0px 0px 20px '};
    @media (max-width: 375px) {
      font-size:16px;
      margin: ${(props) => props.m375 || ' '};
  
  }

 @media (max-width: 425px) {
  font-size: ${(props) => props.fz || '16px'};
  margin: ${(props) => props.m425 || ' '};
  width: ${(props) => props.w425 || '50%'};
  
  }

  @media (max-width: 700px) {
    font-size: ${(props) => props.fz || '16px'};
    margin: ${(props) => props.m700 || ' '};
    /* width: 100%; */
}
`;

export const ImgW = styled.img`
  width: 51px;
  height: 56px;
  display:  ${(props) => props.display || ''};
  z-index: ${(props) => props.zIndex || ''};
  margin: ${(props) => props.margin || '36px 0px 0px 58px'};
  @media (max-width: 768px) {
    width: ${(props) => props.widthMds || ''};
    width: ${(props) => props.widthMds || ''};
  }
  @media (max-width: 700px) {
    margin: ${(props) => props.marginmd || '23px 66px 0px 40px'};
  }
  @media (max-width: 425px) {
    margin: ${(props) => props.marginsm || '26px 8px 0px 75px'};
  }
  @media (max-width: 390px) and  (max-width: 388px)  {
     margin: ${(props) => props.marginxs || '24px 8px 0px 16%'};
  }

  @media (max-width: 387px) {
   margin: ${(props) => props.marginxxs || '24px 8px 0px 15%'};
 }

  @media (max-width: 373px) {
   margin: ${(props) => props.marginxxxs || ' 24px 8px 0px 40px'};
 }
`;
export const ImgGeneric = styled.img`
  width: ${(props) => props.width || '51px'};
  height: ${(props) => props.height || '56px'};
  display:  ${(props) => props.display || ''};
  position:  ${(props) => props.position || ''};
  z-index: ${(props) => props.zIndex || ''};
  margin: ${(props) => props.margin || '36px 0px 0px 58px'};
  border-bottom-left-radius: ${(props) => props.borderBottomL || ''};
  border-bottom-right-radius: ${(props) => props.borderBottomR || ''};
  @media (max-width: 768px) {
    width: ${(props) => props.widthMds || ''};
    height: ${(props) => props.heightMds || ''};
    right: ${(props) => props.rightMds || ''};
    margin-top: ${(props) => props.marginTopMds || ''};
    position:  ${(props) => props.positionMds || ''};
  }
`;
// Qué dicen los medios de Coru

export const CardGeneric = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 12px;
  padding: 0px 0px 12px;
  width: 229.33px;
  height: 250px;
  left: 0px;
  top: 0px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #c6d8f7 0%, #cfd3f7 100%);
  box-shadow: -4px 4px 12px rgba(57, 58, 128, 0.15),
    4px 4px 12px rgba(57, 58, 128, 0.15);
  border-radius: 16px;
  /* margin: 0px 12px; */
    @media (max-width: 375px) {
       margin-right: -189px;
       width: 285.33px;
       margin-top: 0px;
    }
    @media (max-width: 700px) {
      margin-right: auto;
      width: 278px;
      margin-top: auto;
      margin:auto;
   }
   @media (max-width: 285px) {
    margin-right: auto;
    width: 90%;
    margin-top: 0px;
 }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left:12px;
  width: 197.33px;
  height: auto;
  left: 16px;
  margin-top: 12px;
  margin-bottom: 15px;
  /* margin-bottom:14px; */
`;
export const ImgPrensa = styled.img`

  
  

`;

export const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 126.48px;
  height: 46px;
  margin: 5px 13px;
  
`;

export const TitleCard = styled.h6`
  width: 117.33px;
  height: 24px;
  left: 0px;
  top: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #393a80;
  margin: 2px 0px;
   @media (max-width: 375px) {
        text-align: start; 
    
  }
`;

export const DateCard = styled.p`
  width: 126.33px;
  height: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #393a80;
  margin: 0px 0px;
   @media (max-width: 375px) {
        text-align: start; 
    
  }
`;

export const TextSmall = styled.p`
  position: static;
  width: 197.33px;
  height: 140px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #393a80;
  margin: 10px 10px 0px 20px;
    @media (max-width: 375px) {
        text-align: start; 
      margin: 10px 20px 0px 30px;
      width: 237.33px;
    }
    @media (max-width: 700px) {
      text-align: start; 
      margin: 10px auto 0px auto;
      width: 80%;
    }
    @media (max-width: 285px) {
      text-align: start; 
      margin: 10px auto 0px auto;
      width: 90%;
    }
`;

export const TextSmallPink = styled.p`
  position: static;
  width: 670px;
  left: calc(50% - 670px / 2);
  top: 60.98%;
  bottom: 10.31%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  color: #ac2e5b;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
   @media (max-width: 375px) {
     margin: ${(props) => props.marginSm || ''};
    position: ${(props) => props.positionSm || ''};
    top: ${(props) => props.topSm || ''};
    bottom: ${(props) => props.bottomSm || ''};
    width: ${(props) => props.w375 || '252px'};
  }
  @media (max-width: 700px) {
    margin: ${(props) => props.margin700 || ''};
   position: ${(props) => props.position700 || ''};
   top: ${(props) => props.top700 || ''};
   bottom: ${(props) => props.bottom700 || ''};
   width: ${(props) => props.w700 || '100%'};
 }
`;

export const ImgStars = styled.img`
  width: 116px;
  height: 20px;
  margin: 6px 0px;

  @media (max-width: 769px) {
    margin: ${(props) => props.marginMob || ''};
  }
`;

export const ImgTrustPilot = styled.img`
  width: 130px;
  height: 60px;
  margin-left: 12px;
  margin-top:3px;
   @media (max-width: 375px) {
  width: 130px;
  height: 60px;
  margin-left: 19px;
  margin-top:3px;
  }
`;

export const ContainerVideo = styled.video`
  position: static;
  width: 711px;
  height: 389.9px;
  left: 0px;
  top: 0px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
`;

export const DescriptionVideo = styled.p`
  position: static;
  width: 712px;
  height: 38px;
  left: 0px;
  top: 401.9px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.003em;

  color: #80460c;

  /* Inside auto layout */

  margin: 12px 0px;
   @media (max-width: 375px) {
      width:322px;
      height:57px;  
      margin: 15px 0px;
      font-size: 15px;  
  }
  @media (max-width: 700px) {
    width: 92%;
    height: auto;
    margin: 12px auto; 
    font-size: 15px;  
  }
`;

export const Logo = styled.img`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.alignItems || 'center'};
  padding: 0px;

  position: static;
  width: ${(props) => props.w || '150px'};
  height: ${(props) => props.h || '47px'}; 
   margin: ${(props) => props.m || ''};
`;

export const Span = styled.span`
  padding: ${({ padding }) => padding || '3px 8px'};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

// Carousel

export const DivFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.fd || ''};
  justify-content: ${(props) => props.jc || 'space-around'};
  align-items: ${(props) => props.alignItems || ''};
  margin: ${(props) => props.margin || 'auto'};
  margin-top: ${(props) => props.margintop || ' '};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || ' '};
  cursor: ${(props) => props.cursor || ' '};
  @media (max-width: 768px) {
    margin-top: ${(props) => props.margintopM || ' '};
  }
`;

export const Shapes = styled.div`
  position: ${(props) => props.positionD || ' '};
  width: ${(props) => props.width || '484.74px'};
  height: ${(props) => props.heightD || ' '};
  left: ${(props) => props.left || '-13px'};
  top: ${(props) => props.top || '60px'};
  background: ${(props) => props.background || ' '};
  display: ${(props) => props.displayD || ' '};
  margin-top: ${(props) => props.mtopD || ' '};
  margin-bottom: ${(props) => props.mb || ''};

  @media (max-width: 768px) {
    margin-top: ${(props) => props.mtopM || ' '};
    position: ${(props) => props.position || ' '};
    height: ${(props) => props.height || '788.74px'};
    display: ${(props) => props.displayM || ' '};
    z-index: ${(props) => props.zindex || ' '};
  }

  @media (max-width: 480px) {
    height: ${(props) => props.heightD480 || ' '};
  }

  @media (max-width: 320px) {
    height: ${(props) => props.heightD320 || ' '};
  }
`;

export const Container = styled.div`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.float || 'auto'};
  margin-top: ${(props) => props.mt || '0%'};
  margin-bottom: ${(props) => props.mb || 'auto'};
  margin-left: ${(props) => props.mleft || 'auto'};
  display: flex;
  display: ${(props) => props.displayweb || ''};
  border-bottom: ${(props) => props.bb || ''};
  flex-wrap: wrap;
  padding: ${(props) => props.padding || ''};
  padding-top: ${(props) => props.paddingTop || ''};
  border: ${(props) => props.border || ''};
  border-top: ${(props) => props.bordertop || ''};
  background: ${(props) => props.background || ''};
  border-radius: ${(props) => props.radius || ''};
  border-top-right-radius: ${(props) => props.btopRradius || ''};
  border-top-left-radius: ${(props) => props.btopLradius || ''};
  box-shadow: ${(props) => props.shadow || ''};
  box-sizing: ${(props) => props.boxSizing || ''};
  cursor: ${(props) => props.cursor || ''};
  height: ${(props) => props.height || ''};
  filter: ${(props) => props.filter || ''};
  @media (max-width: 768px) {
    width: ${(props) => props.widthMobile || '100%'};
    display: ${(props) => props.displaymobile || ''};
    margin: ${(props) => props.mMobile || ''};
    margin-left: ${(props) => props.mlMobile || 'auto'};
    margin-right: ${(props) => props.mRMobile || 'auto'};
    margin-bottom: ${(props) => props.mbMobile || props.mb};
    margin-top: ${(props) => props.mtMobile || ''}; //1rem
    padding: ${(props) => props.paddingMobile || ''};
  }
`;

// Quiestion

export const ResText = styled.p`
 
 position: static;
width: 692px;
left: calc(50% - 692px/2);
top: 11.63%;
bottom: 11.63%;

/* Mobile/Body Copy Small */

font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 160%;
/* or 22px */

display: flex;
align-items: center;
letter-spacing: -0.3px;

color: #AC2E5B;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;

  @media (max-width: 375px) {
   width: 200px;
   
   font-size: 14px;
  }

  @media (max-width: 700px) {
    width: 100%;
    font-size: 14px;
   }


 
 `;

export const QuestionT = styled.p`
 
 position: static;
width: 629px;
height: 34px;
left: 28px;
top: 8px;

/* Mobile/Headline 3 */

font-family: Cabin;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 120%;
/* or 24px */

display: flex;
align-items: center;
letter-spacing: -0.3px;

color: #AC2E5B;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 1;
margin: 0px 15px;


@media (max-width: 375px) {
   width: 200px;
   height: 34px;
   font-size: 14px;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: 34px;
    font-size: 14px;
   }


`;

export const ContainerList = styled.ul`
 display: ${(props) => props.dis || 'none'};
 list-style: ${(props) => props.lstyle || ' '};
 margin:0px;
 padding-left:15px ;
`;

export const ContainerListOl = styled.ol`
 display: ${(props) => props.disOl || 'none'};
 margin:0px;
 padding-left:15px ;
`;

export const Ancor = styled.a`
 color: #F64282 ;
 cursor: pointer;
text-decoration:underline;
&:hover {
  color: #F64282 ;
  text-decoration:underline; 
    }
`;

export const CardModal = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding:15px;
gap: 13px;

position: relative;
width: 590px;
height: 385.03px;

background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 53.65%, #FCEBEB 100%);
/* OPTION SHADOW */

box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
border-radius: 16px;
@media (max-width: 425px) {
   width: 272px;
   height:419.03px;
   
  }
`;

export const PrfModal = styled.p`
width: 90%;
height: 29px;
font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 120%;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.3px;
color: #393A80;
margin-top: 30px;
margin-bottom:20px;
margin-left:auto;
margin-right:auto;
@media (max-width: 425px) {
   width: 224px;
   margin-left:auto;
   margin-left:auto;
   margin-bottom:55px;
   font-size: 20px;
  }

`;

export const ImgM = styled.img`
width: 260px;


@media (max-width: 425px) {
   width: 262px;
 
  }

`;

export const Button = styled.button`

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 16px;

width: 102px;
height: 32px;

/* PRIMARY/PINK */

background: #F64282;
border-radius: 8px;

/* Inside auto layout */

flex: none;
order: 3;
flex-grow: 0;
@media (max-width: 425px) {
   width: 272px;
  
   
  }
`;

export const Close = styled.p`

width: 11.17px;
height: 12px;

/* TEXT/DARK BLUE */

color: #393A80;
font-family: 'Cabin';
font-style: normal;
font-weight: 700;
/* Inside auto layout */
font-size: 20px;
flex: none;
order: 0;
flex-grow: 0;

`;

export const CloseDiv = styled.div`
display:flex;
flex-direction:row;
justify-content: end;
width: 100%;
margin-right:20px;
 
`;
