import React, { Fragment } from 'react';
// import { useMutation } from '@apollo/client';
// import TagManager from 'react-gtm-module';
import ClickButton from '../../../Helpers/HookHelpers';
import Facebook from './../Assets/img/Facebook.svg';

import Youtube from './../Assets/img/Youtube.svg';
import Instagram from './../Assets/img/Instagram.svg';
import Twittwer from './../Assets/img/Twitter.svg';
import Linkedin from './../Assets/img/Linkedin.svg';
import TikTok from './../Assets/img/TikTok.svg';
import {
  Image,
  //   FooterVerticals,
  FooterViralidad,
} from '../Assets/styles/Footer.styled';
// import { Facebook } from '@material-ui/icons';
// import { shareCoru } from '../../Helpers/index';
// import { viralityUTM } from '../../Helpers/HelperUTM';
// import { INSERT_DAT_VIRALITY, UPDATE_HISTORY_COLLECTION, CREATE_SESSION } from '../../mutations';
// import { getDateStandart } from '../../Helpers/general';

const ShareSection = ({ section }) => {

  const { actions } = ClickButton();
  // const path = window.location.pathname;

  // const shareTraining = (socialNetwork) => {
  //   if (path === '/') {
  //     shareHome(socialNetwork);
  //   }
  // };

  function shareHome(socialNetwork) {
    let action = 'ClickButtonCoruHome';
    let collection = 'clickbuttonhomecoru';

    if (section === 'LPBrasil') {
      action = 'ClickButtonLPBrasil';
      collection = 'LogClickButtonLpCoruBrasil';
    }

    let coruRedirectURL;
    switch (socialNetwork) {
      case 'Messenger': {
        actions({
          action,
          variables:
            section === 'LPBrasil' ?
              'ClickFb_LpCoruBrasil' :
              `Footer${socialNetwork}`,
          collection,
        });
        // It seems that custom message is no longer suported by the facebook API
        // if you find a way to implement it, please do it.
        coruRedirectURL = 'https://coru.com/shorter?id=qUHiX6VXq7TZP0n';
        const url = `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${coruRedirectURL}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}`;
        window.open(url);
        break;
      }
      case 'MessengerMob':
        actions({
          action,
          variables:
            section === 'LPBrasil' ?
              'ClickFb_LpCoruBrasil' :
              `Footer${socialNetwork}`,
          collection,
        });
        coruRedirectURL = 'https://coru.com/shorter?id=qUHiX6VXq7TZP0n';
        window.open(
          `fb-messenger://share/?link=${coruRedirectURL}&app_id=248019326476226`,
        );
        break;
      case 'Facebook':
        actions({
          action,
          variables:
            section === 'LPBrasil' ?
              'ClickFb_LpCoruBrasil' :
              `Footer${socialNetwork}`,
          collection,
        });
        coruRedirectURL = 'https://coru.com/shorter?id=P6Ca2ipVL5Vq87H';
        window.open(`https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(coruRedirectURL)}&redirect_uri=${encodeURIComponent('https://www.facebook.com/')}&display=popup`);
        break;
      case 'Whatsapp':
        actions({
          action,
          variables:
            section === 'LPBrasil' ?
              'ClickWa_LpCoruBrasil' :
              `Footer${socialNetwork}`,
          collection,
        });
        {
          coruRedirectURL = 'https://coru.com/shorter?id=yVn6F9Odtw0LnBH';
          const whatsappMessage = `¡Obtén el poder de tu dinero! Coru es la forma más inteligente de tomar tus decisiones financieras. Nuestro coach financiero te llevará de la mano para entender tus necesidades. Regístrate y alcanza tus metas financieras. ${coruRedirectURL}`;
          window.open(`https://api.whatsapp.com/send?text=${encodeURI(whatsappMessage)}`, 'popup', 'width=600', 'height=600');
          break;
        }
      case 'Instagram':
        console.log('redes sociales');
        actions({
          action,
          variables:
            section === 'LPBrasil' ?
              'ClickIg_LpCoruBrasil' :
              `Footer${socialNetwork}`,
          collection,
        });
        if (section === 'LPBrasil') {
          window.open(`https://www.instagram.com/coru_br/`);
        } else {
          window.open(`https://www.instagram.com/coru_mx/`);
        }
        break;
      case 'Linkedin':
        actions({
          action,
          variables:
            section === 'LPBrasil' ?
              'ClickLd_LpCoruBrasil' :
              `Footer${socialNetwork}`,
          collection,
        });
        if (section === 'LPBrasil') {
          window.open(`https://www.linkedin.com/company/coru-br/`);
        } else {
          window.open(`https://www.linkedin.com/company/coru-com/`);
        }
        break;
      case 'Youtube':
        actions({
          action,
          variables:
            section === 'LPBrasil' ?
              'ClickFt_LpCoruBrasil' :
              `Footer${socialNetwork}`,
          collection,
        });
        window.open(`https://www.youtube.com/c/CoruMX`);
        break;
      case 'Twittwer':
        actions({
          action,
          variables:
            section === 'LPBrasil' ?
              'Clicktw_LpCoruBrasil' :
              `Footer${socialNetwork}`,
          collection,
        });
        window.open(`https://twitter.com/Coru_mx`);
        break;
      case 'TikTok':
        actions({
          action,
          variables:
            section === 'LPBrasil' ?
              'ClickTt_LpCoruBrasil' :
              `Footer${socialNetwork}`,
          collection,
        });
        window.open(`https://www.tiktok.com/@coru.mx`);
        break;
      default:
        break;
    }
  }

  return (

    <Fragment>
      {/* <FooterVerticals widthM="100%" width="91%" left="auto" className="roboto">Comparte en:</FooterVerticals> */}
      <FooterViralidad width="91%" left="auto" style={section === 'LPBrasil' ? {justifyContent: 'center'} : ''} >
        <a target="_blank" onClick={() => shareHome('Facebook')} style={section === 'LPBrasil' ? {display: 'none'} : ''}>
          <Image
            src={Facebook}
            marginRight="17px"
            className="img-fluid cursor-p"
            width="34.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
          // displayMobile="none"
          />
        </a>
        <a target="_blank" onClick={() => shareHome('Instagram')}>
          <Image
            src={Instagram}
            marginRight="17px"
            className="img-fluid cursor-p"
            width="34.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
            displayMobile="block"
          />
        </a>
        <a target="_blank" onClick={() => shareHome('Linkedin')} >
          <Image
            src={Linkedin}
            marginRight="17px"
            className="img-fluid cursor-p"
            width="34.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
            displayMobile="block"
          />
        </a>
        <a target="_blank" onClick={() => shareHome('Youtube')} style={section === 'LPBrasil' ? {display: 'none'} : ''}>
          <Image
            src={Youtube}
            marginRight="17px"
            className="img-fluid cursor-p"
            width="34.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
            displayMobile="block"
          />
        </a>
        <a target="_blank" onClick={() => shareHome('Twittwer')} style={section === 'LPBrasil' ? {display: 'none'} : ''}>
          <Image
            src={Twittwer}
            marginRight="17px"
            className="img-fluid cursor-p"
            width="34.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
            displayMobile="block"
          />
        </a>
        <a target="_blank" onClick={() => shareHome('TikTok')} style={section === 'LPBrasil' ? {display: 'none'} : ''}>
          <Image
            src={TikTok}
            marginRight="17px"
            className="img-fluid cursor-p"
            width="34.06px"
            marginTop="2px"
            bottom="30px"
            displayWeb="block"
            bottomMObile="block"
            displayMobile="block"
          />
        </a>
      </FooterViralidad>
    </Fragment>
  );
};

export default ShareSection;
