/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Header from "./Components/Header";
import CuentaButton from "./utils/KandaButton";
import Footer from "./Components/Footer";
import "./Assets/styles/styles.css";
import { makeStyles } from "@material-ui/core/styles";
import ConoceSolucionesRapidas from "./LpSections/ConoceSolucionesRapidas";
import LosQueOtros from "./LpSections/SectionQuestions ";
import Carrousel from "./Components/Carrousel";
import Tarjetas from "./Assets/img/tarjetas.svg";
import { AnimatedBg, Transition } from "scroll-background";
import Modal from "@material-ui/core/Modal";
import { SectionVideoPrincipal, SectionVideo } from "./LpSections/Sectionvideo";
import { GET_CAMPAING_MODAL } from "../../queries";
import {
  CreaTuPlanFinanciero,
  QueDicenLosMedios,
  QuienesYaProbaronCoru,
  PlaticaConExpertos,
} from "./LpSections/Sections";
import {
  ContentGeneric,
  ContainerSections,
  CardModal,
  PrfModal,
  Close,
  CloseDiv,
  ImgM,
} from "./Assets/styles/StyledGeneric.styled";
import ClickButton from "../../Helpers/HookHelpers";
import ModalAviso from "./Components/ModalAviso";
import KandaButton from "./utils/KandaButton";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
  },
}));

const LPKanda = (props) => {
  const { initializeNow, initializeLogin, shareCoru } = props;
  const { actions } = ClickButton();
  const [infoModal, setInfoModal] = useState();
  const [modalStyle] = React.useState(getModalStyle);
  const URLKanda = sessionStorage.getItem("url_kanda");
  const Shortened = sessionStorage.getItem("utm_seccion") || "";
  const classes = useStyles();

  const [getCampaignControlK] = useLazyQuery(GET_CAMPAING_MODAL, {
    onCompleted({ getCampaignControl }) {
      const respuesta = JSON.parse(getCampaignControl);
      if (respuesta.length !== 0) setInfoModal(respuesta[0]);
    },
    onError(err) {
      // eslint-disable-next-line
      console.log("Error al obtener campaña", err);
    },
  });

  useEffect(() => {
    getCampaignControlK({ variables: { idModal: "ModalHome" } });
  }, []);

  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goUtm = () => {
    const click = `ComenzarBuscarMejorTarjeta`;
    actions({
      action: "ClickButtonCollection",
      variables: click,
      redirection: infoModal.Utm,
      href: true,
    });
  };

  const ModalMessage = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <CardModal>
          <CloseDiv>
            {" "}
            <Close onClick={handleClose} style={{ cursor: "pointer" }}>
              X
            </Close>
          </CloseDiv>
          <ImgM src={infoModal.Imagen} />
          <PrfModal>{infoModal.Titulo}</PrfModal>
          <KandaButton
            action={() => goUtm()}
            width="25%"
            height="49px"
            typeBtn="active"
            w425="152px"
          >
            Da clic aquí
          </KandaButton>
        </CardModal>
      </div>
    </Modal>
  );

  const handleClick = (seccion, name) => {
    switch (name) {
      case "Conoce_más":
        actions({
          action: "ClickButtonCoruHome",
          variables: `${seccion}${name}`,
          redirection: `${URLKanda}`,
          href: true,
        });
        break;
      case "Regístrate":
        actions({
          action: "ClickButtonCoruHome",
          variables: `${seccion}${name}`,
          redirection: `${URLKanda}/lp/master-class-v2`,
          href: true,
        });
        break;
      case "Whatsapp_Xcoaches":
        actions({
          action: "ClickButtonCoruHome",
          variables: `${seccion}${name}`,
        });
        // eslint-disable-next-line no-case-declarations
        const whatsappMessage = `¡Hola! 👋 Quiero hablar con un experto sobre mis dudas financieras 🤩`;
        window.open(
          `https://api.whatsapp.com/send?phone=5215541693124&text=${whatsappMessage}`,
          "popup",
          "width=600",
          "height=600"
        );
        break;
      case "CreaCuenta":
        actions({
          action: "ClickButtonCoruHome",
          variables: `${seccion}${name}`,
          redirection: `/personal-Information-v2`,
          href: true,
        });
        initializeLogin();
        break;
      case "Trustpilot":
        actions({
          action: "ClickButtonCoruHome",
          variables: `${seccion}${name}`,
        });
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    let link;
    let location;
    const winmed = window.matchMedia("(max-width: 700px)");
    if (Shortened !== "") {
      link = `${Shortened}`;
      location = document.querySelector(link).offsetTop;
    }
    if (winmed.matches) {
      window.scrollTo({
        left: 0,
        top: location - 170,
      });
    } else {
      window.scrollTo({
        left: 0,
        top: location - 190,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentGeneric dis="flex" fd="column">
      <Header initializeLogin={initializeLogin} initializeNow={initializeNow} />

      <AnimatedBg>
        <Transition from="#e2f3fc" to="#e7e7fb">
          <section className="Gradiante1">
            <SectionVideoPrincipal
              tituloPrincial="Coaching Financiero para todos"
              handleClick={handleClick}
            />
            <ContainerSections mtWeb="18px" mtMob="64px">
              <CreaTuPlanFinanciero
                tituloSecundario="Crea Tu Plan Financiero"
                shareCoru={shareCoru}
              />
            </ContainerSections>
          </section>
        </Transition>

        <Transition from="#e7e7fb" to="#e7e7fb" position={0.5}>
          <section className="Gradiante2">
            <ContainerSections mtWeb="72px" mtMob="122px">
              <ConoceSolucionesRapidas
                tituloSecundario="Conoce soluciones rápidas"
                handleClick={handleClick}
                shareCoru={shareCoru}
              />
            </ContainerSections>
          </section>
        </Transition>

        <Transition from="#e7e7fb" to="#fdf3e8" position={1}>
          <section className="Gradiante3">
            <ContainerSections mtWeb="72px" mtMob="152px">
              <SectionVideo
                tituloSecundario="Aprende de finanzas personales"
                handleClick={handleClick}
                shareCoru={shareCoru}
              />
            </ContainerSections>
            <ContainerSections mtWeb="48px" mtMob="98px">
              <PlaticaConExpertos
                tituloSecundario="Platica con expertos financieros"
                handleClick={handleClick}
                shareCoru={shareCoru}
              />
            </ContainerSections>
          </section>
        </Transition>

        <Transition from="#fdf3e8" to="#feeaf1" position={0.5}>
          <section className="Gradiante4">
            <ContainerSections mtWeb="48px" mtMob="120px">
              <Carrousel tituloSecundario="Conoce a nuestros aliados" />
            </ContainerSections>
            <ContainerSections mtWeb="20px" mtMob="98px">
              <QuienesYaProbaronCoru
                tituloSecundario="Quiénes ya probaron Coru®"
                handleClick={handleClick}
              />
            </ContainerSections>
          </section>
        </Transition>

        <section className="Gradiante5">
          <ContainerSections mtWeb="30px" mtMob="80px">
            <QueDicenLosMedios tituloSecundario="Qué dicen los medios de Coru" />
          </ContainerSections>
          <ContainerSections mtWeb="30px" mtMob="100px">
            <LosQueOtros tituloSecundario="Lo que otros han preguntado" />
          </ContainerSections>
        </section>
      </AnimatedBg>
      {open && infoModal ? <ModalMessage /> : null}
      <Footer />

      <div className="CrearCuentaBtn">
        <CuentaButton
          action={() => handleClick("CrearCuentaMobile", "CreaCuenta")}
          ml700="0px"
          typeBtn="active"
        >
          Crear Cuenta
        </CuentaButton>
      </div>
    </ContentGeneric>
  );
};

export default LPKanda;
