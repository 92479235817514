import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import TextField from "@material-ui/core/TextField";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  Main,
  Col11,
  Container,
  Col3,
  ParagrapTwo,
  ContentButton,
  Col12,
  ParagraphOne,
  SubtitleOne,
  LinksFooter,
  CorrecionCorreo,
} from "../../components/common/common.styled";
import "../../assets/styles/WelcomeValidation.css";
import "../../assets/styles/TextCustom.css";
import {
  LOGIN,
  UPDATE_SESSION,
  CREATE_SESSION,
  CREATE_CLICK,
} from "../../mutations";
import { GET_NICKNAME_KANDA, GET_URL_KANDA } from "../../queries";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import GeneralLoader from "../../components/common/GeneralLoader";
// Modal
import { getQueryVariable } from "../../Helpers";
import ModalLogin, { ModalRegisteredUser } from "./ModalLogin";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "380px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    borderRadius: "5px",
  },
}));
function Login() {
  const [form, setForm] = useState({
    email: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
  });

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      console.log(createClick);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [focusContrasena, setFocusContrasena] = useState(false);
  const [error, setError] = useState({
    email: false,
    pass1: false,
    pass2: false,
    pass3: false,
    pass4: false,
    emailvalid: -1,
    passwordvalid: -1,
  });

  const [texterror, settexterror] = useState({
    email: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
    usuario: "",
  });
  const [redirectToKanda, setRedirectToKanda] = useState(false);
  const [redirectToWoKanda, setRedirectToWoKanda] = useState(false);
  const [nickname, setNickname] = useState("");
  const [urlKanda, setUrlKanda] = useState("");

  const [texterrorLogin] = useState();
  const [mostrarLoad, setMostrarLoad] = useState(false);
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [TypeCorreo, SetTypeCorreo] = useState({
    text: "",
    display: "",
    text_correct: "",
  });

  const [getUrlKanda] = useLazyQuery(GET_URL_KANDA, {
    onCompleted({ getUrlKanda }) {
      try {
        const obj = JSON.parse(getUrlKanda.response)[0];
        console.log("RESPONSE GET_URL_KANDA,", obj);
        setUrlKanda(obj.Path);
      } catch (error) {
        console.log("Error during query !!! ==>>", error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getNicknameKanda] = useLazyQuery(GET_NICKNAME_KANDA, {
    onCompleted({ getNicknameKanda }) {
      try {
        const obj = JSON.parse(getNicknameKanda.response)[0] || "";
        setNickname(obj.nickname);
      } catch (error) {
        console.log("Error during query !!! ==>>", error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted({ dynamicMongoSessionUpdate }) {
      if (dynamicMongoSessionUpdate.statusCode === 200) {
        console.log(dynamicMongoSessionUpdate.response);
      }
    },
  });

  const handleOpen = (text) => {
    settexterror({
      ...texterror,
      usuario: text,
    });
    setOpen(true);
  };
  const [login] = useMutation(LOGIN, {
    onCompleted({ login }) {
      console.log(login.token);
      if (login.token === "El usuario no existe") {
        setForm({
          email: "",
          pass1: "",
          pass2: "",
          pass3: "",
          pass4: "",
        });
        setError({
          email: false,
          pass1: false,
          pass2: false,
          pass3: false,
          pass4: false,
          emailvalid: -1,
          passwordvalid: -1,
        });
        document.querySelector("input[name='email']").focus();
        handleOpen(
          "Los datos ingresados no corresponden a un usuario registrado. Por favor verifique los datos."
        );
        setMostrarLoad(false);
      } else if (login.token === "Contraseña incorrecta") {
        setForm({
          ...form,
          pass1: "",
          pass2: "",
          pass3: "",
          pass4: "",
        });
        setError({
          ...error,
          pass1: false,
          pass2: false,
          pass3: false,
          pass4: false,
          emailvalid: -1,
          passwordvalid: -1,
        });
        document.querySelector("input[name='pass1']").focus();
        handleOpen(
          "La contraseña ingresada es incorrecta, por favor intenta de nuevo."
        );
        setMostrarLoad(false);
      } else {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        let hours = today.getHours();
        let minutes = today.getMinutes();
        let seconds = today.getSeconds();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        if (hours < 10) hours = `0${hours}`;
        if (minutes < 10) minutes = `0${minutes}`;
        if (seconds < 10) seconds = `0${seconds}`;
        today = `${mm}-${dd}-${yyyy}`;
        const todayLogin = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const createClickInput = {
          input: {
            id: sessionStorage.getItem("id_session"),
            column: "inicioSesion",
            collection: "sessions",
            timeStamp: todayLogin,
          },
        };
        if (sessionStorage.getItem("id_session")) {
          createClickFront({ variables: createClickInput });
        }

        const dataJson = {
          email: sessionStorage.getItem("Email1"),
          timestamp: today,
          IDUser: login.idUser,
          idSession: login.idSession,
        };
        const filterJson = {
          _id: sessionStorage.getItem("id_session"),
        };
        const dataString = JSON.stringify(dataJson);
        const filterString = JSON.stringify(filterJson);
        const mongoUpdateInput = {
          input: {
            collection: "sessions",
            data: dataString,
            filter: filterString,
          },
        };
        if (sessionStorage.getItem("id_session")) {
          updateSession({ variables: mongoUpdateInput });
        }
        sessionStorage.setItem("token", login.token);
        sessionStorage.setItem("FirstName", login.firstName);
        sessionStorage.setItem("MiddleName", login.middleName);
        sessionStorage.setItem("FathersLastName", login.fathersLastName);
        sessionStorage.setItem("MothersLastName", login.mothersLastName);
        sessionStorage.setItem("idUser", login.idUser);
        sessionStorage.setItem("idCont", login.idCont);
        sessionStorage.setItem("idDatSession", login.idSession);
        sessionStorage.setItem("idCatRelAction", login.idCatRelAction);
        console.log(login.idCatRelAction);
        sessionStorage.setItem("ProfilePicture", login.ProfilePicture);

        localStorage.setItem("token", login.token);
        localStorage.setItem("FirstName", login.firstName);
        localStorage.setItem("MiddleName", login.middleName);
        localStorage.setItem("FathersLastName", login.fathersLastName);
        localStorage.setItem("MothersLastName", login.mothersLastName);
        localStorage.setItem("idUser", login.idUser);
        localStorage.setItem("idCont", login.idCont);
        localStorage.setItem("idDatSession", login.idSession);
        localStorage.setItem("idCatRelAction", login.idCatRelAction);
        console.log(login.idCatRelAction);
        localStorage.setItem("ProfilePicture", login.ProfilePicture);
        sessionStorage.setItem("session", true);
        sessionStorage.setItem("PrimerVis", true);
        sessionStorage.setItem("showModal", false);
        if (login.BirthDate != null) {
          const { BirthDate } = login;
          const partsBirthDate = BirthDate.split("/");
          const day = partsBirthDate[0];
          const mont = partsBirthDate[1];
          const year = partsBirthDate[2];
          sessionStorage.setItem("day", day);
          sessionStorage.setItem("month", mont);
          sessionStorage.setItem("year", year);
          sessionStorage.setItem("DataPerson", true);
        }

        if (login.Gender != null) {
          sessionStorage.setItem("Gender", login.Gender);
        }
        if (redirectToWoKanda) {
          const pathnameP = window.location.pathname;
          const pathname = pathnameP.split('/')[1];
          const replaceRedirect = redirectToWoKanda.replaceAll('.', '/');
          const url = urlKanda.replace('/kanda', replaceRedirect);
          window.open(`${url}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda&idUser=${login.idUser}&idCont=${login.idCont}`, '_self');
        } else if (redirectToKanda) {
          const pathnameP = window.location.pathname;
          const pathname = pathnameP.split("/")[1];
          window.open(
            `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda&idUser=${login.idUser}&idCont=${login.idCont}`,
            "_self"
          );
        } else if (sessionStorage.getItem("LPpageplanes") === "true") {
          window.location.href = "/wo/first-investment";
        } else {
          window.location.href = "/the-training-feed/";
        }
        setMostrarLoad(false);
      }
    },
  });

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem("id_session", dynamicMongoSession.response);
        localStorage.setItem("id_session", dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const idga = sessionStorage.getItem("ID_GA");
    const dataJson = {
      idga,
      campaign: sessionStorage.getItem("campaign"),
      medium: sessionStorage.getItem("medium"),
      source: sessionStorage.getItem("source"),
      timestamp: new Date().toLocaleString(),
      content: sessionStorage.getItem("content"),
    };
    const dataString = JSON.stringify(dataJson);
    const mongoInput = {
      input: {
        collection: "sessions",
        data: dataString,
      },
    };
    if (!sessionStorage.getItem("id_session")) {
      createSession({ variables: mongoInput });
    }

    const idUser = getQueryVariable("idUser");
    const idCont = getQueryVariable("idCont");
    const redirect = getQueryVariable("redirectToKanda");
    const redirecto = getQueryVariable('WoRedirectTo');
    if (idUser && idUser !== "" && idCont && idCont !== "") {
      getNicknameKanda({
        variables: {
          idUser: parseInt(idUser),
        },
      });
    }

    if (redirecto) {
      setRedirectToWoKanda(redirecto);
      getUrlKanda({
        variables: { appName: 'Kanda' },
      });
    }
    /*
    if (redirect) {
      getUrlKanda({
        variables: { appName: "Kanda" },
      });
    } */
    if (redirect) {
      setRedirectToKanda(redirect);
      getUrlKanda({
        variables: { appName: "Kanda" },
      });
    }
  }, []);

  const [btn, setBtn] = useState(true);
  const ValidEmail = (value) => {
    let domain = value.split("@");
    const anexo = domain[0];
    domain = domain[1];
    console.log(domain);

    if (domain !== "" && domain !== "gmail" && domain !== "hotmail") {
      if (domain === "h") {
        console.log("entro");
        SetTypeCorreo({
          display: "block",
          text: `Quieres decir: ${anexo}@hotmail.com`,
          text_correct: `${anexo}@hotmail.com`,
        });
        texterror.email = "";
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === "g") {
        SetTypeCorreo({
          display: "block",
          text: `Quieres decir: ${anexo}@gmail.com`,
          text_correct: `${anexo}@gmail.com`,
        });
        texterror.email = "";
        error.email = true;
        error.emailvalid = 1;
      } else if (domain === "hotmail.com" || domain === "gmail.com") {
        SetTypeCorreo({
          display: "none",
          text: "",
          text_correct: "",
        });
        texterror.email = "";
        error.email = false;
        error.emailvalid = 0;
      }
    } else {
      SetTypeCorreo({
        display: "none",
        text: "",
        text_correct: "",
      });
    }
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      texterror.email = "Correo electrónico incorrecto";
      error.email = true;
      error.emailvalid = 1;
    } else {
      texterror.email = "";
      error.email = false;
      error.emailvalid = 0;
      SetTypeCorreo({
        display: "none",
        text: "",
        text_correct: "",
      });
    }
    if (value === "") {
      texterror.email = "Correo electrónico incorrecto";
      error.email = true;
      error.emailvalid = 1;
    }
  };

  const ValidaNumero = (nombre, valor) => {
    if (nombre && !/^[0-9]+$/i.test(valor) && valor.length !== 1) {
      texterror[nombre] = "Error";
      error[nombre] = true;
    } else {
      texterror[nombre] = "";
      error[nombre] = false;
    }
  };
  const handleChange = (e) => {
    e.persist();
    setForm({
      ...form,
      [e.target.name]: e.target.value === "N" ? "" : e.target.value,
    });
    if (e.target.name === "email") {
      ValidEmail(e.target.value);
    } else {
      ValidaNumero(e.target.name, e.target.value);
    }
    const { name, value } = e.target;
    switch (name) {
      case "pass1":
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass2']").focus();
        } else {
          return false;
        }
        break;
      case "pass2":
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass3']").focus();
        } else {
          return false;
        }
        break;
      case "pass3":
        if (String(value).length === 1 && value != null) {
          document.querySelector("input[name='pass4']").focus();
        } else {
          return false;
        }
        break;
      case "pass4":
        if (
          form.pass1.length !== 0 &&
          form.pass2.length !== 0 &&
          form.pass3.length !== 0
        ) {
          error.passwordvalid = 0;
          document.getElementById("btnIniciar").focus();
        }
        break;
      default:
        break;
    }
  };

  const ebtn = useCallback(() => {
    if (
      form.pass1.length === 1 &&
      !error.pass1 &&
      form.pass2.length === 1 &&
      !error.pass2 &&
      form.pass3.length === 1 &&
      !error.pass3
    ) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  });
  useEffect(() => {
    ebtn();
  }, [ebtn]);

  const guardar = (e) => {
    e.preventDefault();
    if (
      form.pass1.length === 1 &&
      !error.pass1 &&
      form.pass2.length === 1 &&
      !error.pass2 &&
      form.pass3.length === 1 &&
      !error.pass3 &&
      form.pass4.length === 1 &&
      !error.pass4
    ) {
      let profile = "cliente";
      if (String(form.email).indexOf("@test") !== -1) {
        sessionStorage.setItem("typeUser", "test");
        localStorage.setItem("typeUser", "test");
        profile = "test";
      }
      if (form.email === "fertdemo@coru.com") {
        sessionStorage.setItem("Email1", "fernando@coru.com");
      } else {
        sessionStorage.setItem("Email1", form.email);
        localStorage.setItem("Email1", form.email);
      }

      const source = getQueryVariable("utm_source");
      const medium = getQueryVariable("utm_medium");
      const campaign = getQueryVariable("utm_campaign");
      const inputLogin = {
        input: {
          email: form.email,
          password: form.pass1 + form.pass2 + form.pass3 + form.pass4,
          typeUser: profile,
          IDGA: sessionStorage.getItem("ID_GA"),
          source,
          medium,
          campaign,
          hostName: window.location.hostname,
          pageName: "Login",
        },
      };
      console.log(JSON.stringify(inputLogin));
      try {
        setMostrarLoad(true);
        login({
          variables: inputLogin,
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleClickCorreo = () => {
    setForm({
      ...form,
      email: TypeCorreo.text_correct,
    });
    SetTypeCorreo({
      text: "",
      display: "",
      text_correct: "",
    });
    texterror.email = "";
    error.email = false;
    error.emailvalid = 0;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFocus = () => {
    setFocusContrasena(true);
  };

  const handleKeyDown = (e) => {
    console.log(e.target.id);
    if (e.keyCode === 8) {
      switch (e.target.name) {
        case "pass1":
          document.querySelector("input[name='pass1']").focus();
          break;
        case "pass2":
          document.querySelector("input[name='pass1']").focus();

          break;
        case "pass3":
          document.querySelector("input[name='pass2']").focus();
          break;
        case "pass4":
          setForm({
            ...form,
            pass4: "",
          });
          document.querySelector("input[name='pass3']").focus();
          break;
        default:
          break;
      }
      error.passwordvalid = -1;
    }
  };

  const handleBlur = (e) => {
    console.log(e.target.value);
    if (
      e.target.value !== "" ||
      form.pass1 !== "" ||
      form.pass2 !== "" ||
      form.pass3 !== "" ||
      form.pass4 !== ""
    ) {
      setFocusContrasena(true);
    } else {
      setFocusContrasena(false);
    }
  };

  const ModalMessage = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        >
          X
        </ParagrapTwo>
        <SubtitleOne
          color="red"
          mt="3%"
          className="font-weight-bold"
          sizeMobile="20px"
          size="20px"
          textalingn="center"
          textalingnMobile="center"
        >
          <ErrorOutlineIcon style={{ fontSize: 70 }} />
        </SubtitleOne>
        <ParagrapTwo className="mx-auto roboto" mb="0px">
          {texterror.usuario}
        </ParagrapTwo>
        <Button
          className="d-block mt-5 mt-md-4 mb-5 roboto"
          onClick={handleClose}
        >
          Aceptar
        </Button>
      </div>
    </Modal>
  );

  return (
    <Fragment>
      <Header pathname="/login" />
      <Main
        className="container"
        background=" "
        pb="0%"
        overflow=""
        padding="15px"
        margintop="2%"
      >
        <Container
          width="40%"
          mt="1rem"
          widthMobile="100%"
          mb="15%"
          mtMobile="2rem"
        >
          <Col11>
            {nickname && nickname !== "" ? (
              <ParagrapTwo>
                <b>{nickname},</b> detectamos que ya tienes cuenta con nosotros
              </ParagrapTwo>
            ) : null}
            <SubtitleOne mt="0%" id="idTitle" size="18px" className="roboto">
              {redirectToKanda
                ? "Inicia sesión para continuar"
                : "Inicia sesión"}
            </SubtitleOne>
          </Col11>
          <Col11>
            <form autoComplete="off" onSubmit={guardar}>
              <Container widthMobile="100%" width="100%">
                <Col12 pt="2px">
                  <TextField
                    id="email"
                    name="email"
                    autoFocus
                    error={error.email}
                    label="Ingresa tu correo electrónico"
                    required
                    value={form.email}
                    fullWidth
                    type="email"
                    onChange={handleChange}
                    helperText={texterror.email}
                    inputProps={{
                      className:
                        error.emailvalid === 1
                          ? "invalidInput"
                          : error.emailvalid === 0
                          ? "validInput"
                          : " ",
                    }}
                    className="registro"
                  />
                  <CorrecionCorreo
                    display={TypeCorreo.display}
                    onClick={handleClickCorreo}
                  >
                    {TypeCorreo.text}
                  </CorrecionCorreo>
                </Col12>
              </Container>
              <ParagrapTwo
                mtWeb="1.5rem"
                textalignMobile="left"
                texttalign="left"
                fontSize={focusContrasena ? "12px" : "15px"}
                className="mx-auto roboto"
                fontSizeWeb={focusContrasena ? "13px" : "17px"}
                mb="0%"
                mt="7%"
                paddingleft="2%"
                spacing={focusContrasena ? "-.00020em" : "-0.3px"}
              >
                Ingresa tu contraseña
              </ParagrapTwo>
              <Container
                width="98%"
                mt={!focusContrasena ? "-3rem" : "auto"}
                mtMobile={!focusContrasena ? "-3rem" : "auto"}
              >
                <Col3 padding="0px 5px">
                  <TextField
                    id="pass1"
                    name="pass1"
                    label=""
                    type="tel"
                    value={form.pass1}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 1,
                      className:
                        error.passwordvalid === 1
                          ? "invalidInput text-center"
                          : error.passwordvalid === 0
                          ? "validInput text-center"
                          : "text-center",
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 1);
                    }}
                    className="registro"
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                  />
                </Col3>
                <Col3 padding="0px 5px">
                  <TextField
                    id="pass2"
                    name="pass2"
                    label=""
                    type="tel"
                    value={form.pass2}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 1);
                    }}
                    inputProps={{
                      className: "text-center",
                      maxLength: 1,
                      className:
                        error.passwordvalid === 1
                          ? "invalidInput text-center"
                          : error.passwordvalid === 0
                          ? "validInput text-center"
                          : "text-center",
                    }}
                    className="registro"
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                  />
                </Col3>
                <Col3 padding="0px 5px">
                  <TextField
                    id="pass3"
                    name="pass3"
                    label=""
                    type="tel"
                    value={form.pass3}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 1);
                    }}
                    inputProps={{
                      maxLength: 1,
                      className:
                        error.passwordvalid === 1
                          ? "invalidInput text-center"
                          : error.passwordvalid === 0
                          ? "validInput text-center"
                          : "text-center",
                    }}
                    className="registro"
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                  />
                </Col3>
                <Col3 padding="0px 5px">
                  <TextField
                    id="pass4"
                    name="pass4"
                    label=""
                    type="tel"
                    value={form.pass4}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 1);
                    }}
                    inputProps={{
                      maxLength: 1,
                      className:
                        error.passwordvalid === 1
                          ? "invalidInput text-center"
                          : error.passwordvalid === 0
                          ? "validInput text-center"
                          : "text-center",
                    }}
                    className="registro"
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                  />
                </Col3>
              </Container>
              <Container>
                <ParagraphOne
                  fontSize="13px"
                  mt="2%"
                  mb="2%"
                  className="roboto"
                >
                  {texterrorLogin}
                </ParagraphOne>
              </Container>
              <ContentButton mt="6%" mtMobile="2%">
                <Button
                  id="btnIniciar"
                  mt="0px"
                  type="submit"
                  disabled={btn}
                  className="d-block roboto"
                >
                  Iniciar sesión
                </Button>
                <LinksFooter
                  className="link roboto"
                  fontSize="14px"
                  href="/forgot-password"
                >
                  ¿Olvidaste tu contraseña?{" "}
                </LinksFooter>
              </ContentButton>
            </form>
          </Col11>
        </Container>
      </Main>
      <ModalMessage />

      {/* Ejemplo nuevo Modal Login V2 */}
      {/* <ModalLogin /> */}
      {/* <ModalRegisteredUser /> */}
      {/* ............................... */}

      {mostrarLoad ? <GeneralLoader></GeneralLoader> : null}
      <Footer />
    </Fragment>
  );
}

export default Login;
