/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import MessPort from '../lang/pt.json';
import MessUS from '../lang/en-US.json';
import MessMX from '../lang/es-MX.json';

export const langContext = React.createContext();
const LangProvider = ({ children }) => {
  const [mensaje, setmensaje] = useState(MessPort);
  const [locale, setlocale] = useState('pt');

  const LenguajeApp = (lenguaje) => {
    switch (lenguaje) {
      case 'pt':
        setmensaje(MessPort);
        setlocale('pt');
        window.sessionStorage.setItem('placeholder', 'pt');
        break;
      case 'en-US':
        setmensaje(MessUS);
        setlocale('en-US');
        window.sessionStorage.setItem('placeholder', 'en-US');
        break;
      case 'es-MX':
        setmensaje(MessMX);
        setlocale('es-MX');
        window.sessionStorage.setItem('placeholder', 'es-MX');
        break;
      default:
        setmensaje(MessPort);
        setlocale('pt');
        window.sessionStorage.setItem('placeholder', 'pt');
        break;
    }
  };

  return (
    <langContext.Provider value={{ LenguajeApp }}>
        <IntlProvider locale={locale} messages={mensaje}>
          {children}
        </IntlProvider>
    </langContext.Provider>
  );
};

export default LangProvider;
