/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import Lottie from "react-lottie";
import Empecemos from "../Assets/Lotties/Empecemos.json";
import Start from "../Assets/Lotties/Coru-gifmensajes-eng.json";
import Port from "../Assets/Lotties/Coru-gifmensajes-port.json";
import {
  Wrapper,
  LetsContainer,
  ImageCointainer,
  Description,
  Title,
  InfoContainer,
  ButtonsContainer,
  ButtonPrimary,
  SubContentColor
} from './styles.jsx';
import ClickButton from "../../../Helpers/HookHelpers";

const LetsStarted = ({
  i1, i2, i3, i5, language, url
}) => {
  const { actions } = ClickButton();
  const handleClickCoru = (name) => {
    switch (name) {
      case 'Starttheconversation':
        actions({
          action: 'ClickButtonLPCaaS',
          variables: `Click_Button_iniciarconversacion`,
          collection: 'ClickButtonLPCaaS',
        });
        window.open(url, '_blank');
        break;
      case 'BottonSeeallProducts':
        actions({
          action: 'ClickButtonLPCaaS',
          variables: `Click_BottonSeeallProducts`,
          collection: 'ClickButtonLPCaaS',
        });
        window.open(url, '_blank');
        break;
      default:
        break;
    }
  };
  return (
    <Wrapper>
        <LetsContainer>
            <InfoContainer>
                <Title>{i1}</Title>
                <Description>
                  {i2}
                  <SubContentColor color="rgb(89, 89, 89)">
                    {i5}
                  </SubContentColor>
                </Description>
            </InfoContainer>
            <ButtonsContainer>
                <ButtonPrimary
                name='iniciarconversacion'
                type="button"
                onClick={() => handleClickCoru('Starttheconversation')} >
                {i3}
                </ButtonPrimary>
            </ButtonsContainer>
        </LetsContainer>
        <ImageCointainer>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: language === 1 ? Empecemos : language === 2 ? Start : Port,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
        </ImageCointainer>
    </Wrapper>
  );
};

export default LetsStarted;
