/* eslint-disable camelcase */
// Librerías
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import ReactGA from 'react-ga';
import client from './config/apollo';
import Routes from './routes';
import {
  getTransactionID, getLocalStorage, setTraining, validActivity
} from './Helpers/HelperUTM';
import reducer from './store/reducer';
import Whatsapp from './components/Training/Whatsapp';
// ScrollToTop
import ScrollToTop from './components/ScrollToTop';

// URL de Router-Index
import ChatIcono from './components/Home/ChatIcono';
import { LangProvider } from './context/LangContex';

const onActive = () => {
  console.log("### onActive");
};

const onIdle = () => {
  console.log("### onIdle");
};

const onAction = () => {
  // console.log("### onAction")
};

const store = createStore(reducer);
function App() {
  validActivity();
  setInterval(validActivity, 1000);
  getLocalStorage();
  if (!sessionStorage.getItem('ID_GA')) {
    ReactGA.initialize('UA-53999647-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.ga((tracker) => {
      const id_ga = tracker.get('clientId');
      sessionStorage.setItem('ID_GA', id_ga);
    });
  }
  if (!sessionStorage.getItem('validTransaction')) {
    getTransactionID();
  }
  const path = window.location.pathname;
  setTraining(path);

  window.location.href = 'https://www.coru.com';

  return null
  // return (
  //     <ApolloProvider client={client()}>
  //         <Provider store={store}>
  //           <Router>
  //               <ScrollToTop />

  //               <Switch>

  //                 <Routes />

  //               </Switch>

  //               <ChatIcono />
  //               <IdleTimer
  //               element={document}
  //               onActive={onActive}
  //               onIdle={onIdle}
  //               onAction={onAction}
  //               debounce={250}
  //               timeout={1000 * 5 * 1} />
  //               <Whatsapp url={""} id="Whatsapp"/>
  //           </Router>
  //         </Provider>
  //     </ApolloProvider>
  // );
}

export default App;
