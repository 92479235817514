import React, { Fragment, useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { TextPerfil } from '../../assets/styles/pantInicio.styled';
import {
  Main,
  Container,
  Col2,
  Col12,
  Col10,
  Button,
  ParagrapTwo,
  SubtitleOne,
} from '../../components/common/common.styled';
import '../../assets/styles/perfil/perfiles.css';
import { UPSERT_NOTIFICATION_PERMISION } from '../../mutations';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '380px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

// END MODAL

function Notifications({ Novisible, paddingInicio }) {
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  });
  const [formRepetir, SetFormRepetir] = useState({
    notificacionCorreo: sessionStorage.getItem('NotEmail') === '1',
    notificacionSMS: sessionStorage.getItem('NotSMS') === '1',
    notificacionWhat: sessionStorage.getItem('NotWhat') === '1',
    notificacionGeneral: !!(
      sessionStorage.getItem('NotEmail') === '1'
      || sessionStorage.getItem('NotSMS') === '1'
      || sessionStorage.getItem('NotWhat') === '1'
    ),
  });

  const [open, setOpen] = useState(false);
  const [notigeneral, setnotigeneral] = useState(
    !!(
      sessionStorage.getItem('NotEmail') === '1'
      || sessionStorage.getItem('NotSMS') === '1'
      || sessionStorage.getItem('NotWhat') === '1'
    ),
  );

  const handleClickRepetir = (e) => {
    SetFormRepetir({
      ...formRepetir,
      [e.target.name]: e.target.checked,
    });
    switch (e.target.name) {
      case 'notificacionCorreo':
        if (
          !e.target.checked
          && !formRepetir.notificacionSMS
          && !formRepetir.notificacionWhat
        ) {
          setnotigeneral(false);
        } else {
          setnotigeneral(true);
        }
        break;
      case 'notificacionSMS':
        if (
          !formRepetir.notificacionCorreo
          && !e.target.checked
          && !formRepetir.notificacionWhat
        ) {
          setnotigeneral(false);
        } else {
          setnotigeneral(true);
        }
        break;
      case 'notificacionWhat':
        if (
          !formRepetir.notificacionCorreo
          && !formRepetir.notificacionSMS
          && !e.target.checked
        ) {
          setnotigeneral(false);
        } else {
          setnotigeneral(true);
        }
        break;
      default:
        break;
    }
  };

  const [upsertNotificationPermision] = useMutation(
    UPSERT_NOTIFICATION_PERMISION,
    {
      onCompleted({ upsertNotificationPermision }) {
        console.log(upsertNotificationPermision);
        if (upsertNotificationPermision.statusCode === 200) {
          console.log('******Se completo***********');
          handleOpen();
        } else {
          console.log('******Errror***********');
        }
      },
    },
  );

  const id = sessionStorage.getItem('idUser');
  const handleClickbtn = () => {
    const input = {
      idUser: id,
      Whatsapp: formRepetir.notificacionWhat ? 1 : 0,
      Email: formRepetir.notificacionCorreo ? 1 : 0,
      Sms: formRepetir.notificacionSMS ? 1 : 0,
    };
    upsertNotificationPermision({ variables: { input } });
  };

  const handleClickGeneral = (e) => {
    console.log(e.target);
    if (e.target.checked === true) {
      SetFormRepetir({
        notificacionCorreo: true,
        notificacionSMS: true,
        notificacionWhat: true,
      });
      setnotigeneral(true);
    } else {
      SetFormRepetir({
        notificacionCorreo: false,
        notificacionSMS: false,
        notificacionWhat: false,
      });
      setnotigeneral(false);
    }
  };

  // MODAL
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = () => {
    console.log('Open modal');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ModalMessage = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        >
          X
        </ParagrapTwo>
        <SubtitleOne
          mt="3%"
          className="font-weight-bold roboto"
          sizeMobile="20px"
          size="20px"
        ></SubtitleOne>
        <ParagrapTwo className="mx-auto roboto" mb="0px">
          Tus cambios se han guardado con éxito.
        </ParagrapTwo>
        <Button
          className="d-block mt-5 mt-md-4 mb-5 roboto"
          onClick={handleClose}
        >
          Aceptar
        </Button>
      </div>
    </Modal>
  );

  return (
    <Fragment>
      <Main
        overflow=" "
        ptuno={!paddingInicio ? '70px' : ' '}
        pt={!paddingInicio ? '80px' : ' '}
        background="#fafafa"
        pb={!paddingInicio ? '35%' : ' '}
        mt="0%"
        mtWeb="0%"
      >
        <Container
          width="94%"
          background="#ffff"
          filter=" drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
          mt="5rem"
          widthMobile="100%"
          mtMobile="1rem"
          padding="2%"
          mb="0px"
        >
          <Col10 pt="0px" pMobile="0px">
            <TextPerfil
              fw="400"
              fs="20px"
              mt="0%"
              width="100%"
              color="rgb(17, 14, 32)"
              fsMobile="17px"
              mlMobile="0%"
              lh="20px"
            >
              Notificaciones Coru
            </TextPerfil>
          </Col10>
          <Col2 mt="0px" padding="0px" margin="0px" pMobile="0px" ta="center">
            <Checkbox
              inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
              name="notigeneral"
              id="notigeneral"
              checked={notigeneral}
              onClick={handleClickGeneral}
            />
          </Col2>
        </Container>
        <Container
          width="94%"
          mt="35px"
          widthMobile="100%"
          mtMobile="1rem"
          padding="0%"
          mb="0px"
          className="topOne1"
        >
          <Col12 pt="0px" pMobile="0px">
            <TextPerfil
              fw="400"
              fs="32px"
              fsMobile="30px"
              mt="0%"
              width="100%"
              color="rgb(17, 14, 32)"
              mlMobile="0%"
              lh="20px"
            >
              Entrenamientos
            </TextPerfil>
          </Col12>
        </Container>
        <Container
          width="94%"
          filter=" drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
          background="#ffff"
          mt="35px"
          widthMobile="100%"
          mtMobile="1rem"
          padding="2%"
          paddingBottom="3%"
          paddingTop="3%"
          mb="0px"
          className="topOne1"
        >
          <Col10 pt="0px" pMobile="0px">
            <TextPerfil
              fw="600"
              fs="20px"
              mt="0%"
              width="100%"
              color="rgb(17, 14, 32)"
              fsMobile="17px"
              mlMobile="0%"
              lh="20px"
            >
              Correo
            </TextPerfil>
          </Col10>
          <Col2
            mt="0px"
            padding="0px"
            margin="0px"
            pMobile="0px"
            ta="center"
          ></Col2>
          <Col10 pt="0px" pMobile="0px">
            <TextPerfil
              fw="400"
              fs="20px"
              mt="0%"
              width="100%"
              color="rgb(17, 14, 32)"
              fsMobile="17px"
              mlMobile="0%"
              lh="20px"
            >
              Permitir Notificaciones
            </TextPerfil>
          </Col10>
          <Col2 mt="0px" padding="0px" margin="0px" pMobile="0px" ta="center">
            <Checkbox
              inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
              value={formRepetir.notificacionCorreo}
              name="notificacionCorreo"
              id="notificacionCorreo"
              checked={formRepetir.notificacionCorreo}
              onClick={handleClickRepetir}
            />
          </Col2>
        </Container>
        <Container
          width="94%"
          filter=" drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
          background="#ffff"
          mt="35px"
          widthMobile="100%"
          mtMobile="1rem"
          padding="2%"
          paddingBottom="3%"
          paddingTop="3%"
          mb="0px"
          className="topOne1"
        >
          <Col10 pt="0px" pMobile="0px">
            <TextPerfil
              fw="600"
              fs="20px"
              mt="0%"
              width="100%"
              color="rgb(17, 14, 32)"
              fsMobile="17px"
              mlMobile="0%"
              lh="20px"
            >
              SMS
            </TextPerfil>
          </Col10>
          <Col2
            mt="0px"
            padding="0px"
            margin="0px"
            pMobile="0px"
            ta="center"
          ></Col2>
          <Col10 pt="0px" pMobile="0px">
            <TextPerfil
              fw="400"
              fs="20px"
              mt="0%"
              width="100%"
              color="rgb(17, 14, 32)"
              fsMobile="17px"
              mlMobile="0%"
              lh="20px"
            >
              Permitir Notificaciones
            </TextPerfil>
          </Col10>
          <Col2 mt="0px" padding="0px" margin="0px" pMobile="0px" ta="center">
            <Checkbox
              inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
              value={formRepetir.notificacionSMS}
              name="notificacionSMS"
              id="notificacionSMS"
              checked={formRepetir.notificacionSMS}
              onClick={handleClickRepetir}
            />
          </Col2>
        </Container>
        <Container
          width="94%"
          filter=" drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
          background="#ffff"
          mt="35px"
          widthMobile="100%"
          mtMobile="1rem"
          padding="2%"
          paddingBottom="3%"
          paddingTop="3%"
          mb="0px"
          className="topOne1"
        >
          <Col10 pt="0px" pMobile="0px">
            <TextPerfil
              fw="600"
              fs="20px"
              mt="0%"
              width="100%"
              color="rgb(17, 14, 32)"
              fsMobile="17px"
              mlMobile="0%"
              lh="20px"
            >
              WhatsApp
            </TextPerfil>
          </Col10>
          <Col2
            mt="0px"
            padding="0px"
            margin="0px"
            pMobile="0px"
            ta="center"
          ></Col2>
          <Col10 pt="0px" pMobile="0px">
            <TextPerfil
              fw="400"
              fs="20px"
              mt="0%"
              width="100%"
              color="rgb(17, 14, 32)"
              fsMobile="17px"
              mlMobile="0%"
              lh="20px"
            >
              Permitir Notificaciones
            </TextPerfil>
          </Col10>
          <Col2 mt="0px" padding="0px" margin="0px" pMobile="0px" ta="center">
            <Checkbox
              inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
              value={formRepetir.notificacionWhat}
              name="notificacionWhat"
              id="notificacionWhat"
              checked={formRepetir.notificacionWhat}
              onClick={handleClickRepetir}
            />
          </Col2>
        </Container>
        <Container
          width="94%"
          widthMobile="100%"
          mtMobile="0rem"
          padding="2%"
          paddingBottom="3%"
          paddingTop="0%"
          mb="0px"
          className="topOne1"
        >
          <Col12 pt="0px" ptMobile="0px" className="topOne1">
            <Button
              type="submit"
              className="d-block  roboto"
              label="Guardar"
              mt=""
              onClick={handleClickbtn}
            >
              Guardar
            </Button>
          </Col12>
        </Container>
        <ModalMessage />
      </Main>
    </Fragment>
  );
}
export default Notifications;
