import React from 'react';
import { Link } from 'react-router-dom';
import KandaButton from '../utils/KandaButton';
import ShareB from '../Assets/img/ShareBlue.svg';

import {
  ContentGeneric,
  CardWo,
  Container1,
  Text2,
  HeadingTwo,
  Description,
  ImgW,
  ImgGeneric,

} from '../Assets/styles/StyledGeneric.styled';
import Tarjeta from '../Assets/img/TarjetaBlue.svg';
import Pyme from '../Assets/img/Icon_PyME.svg';
import BMoney from '../Assets/img/BolsaBlue.svg';
import ShareBtn from '../utils/ShareBtn';

const DataBase = [
  {
    id: 1,
    Name: 'Obtén tu tarjeta sin tantos trámites: En menos de 5 minutos.',
    UrlTwinImage: Tarjeta,
    className: 'cardWo3',
    TrainingRedirectUrl: '/kanda/wo/cc-ft?utm_source=organic&utm_medium=coru&utm_campaign=home&utm_content=home',
    m375: '28px 0px 0px 20px',
    m425: '20px 0px 0px 20px',
    color: '#AC2E5B',
    idurl: false,
  },
  {
    id: 2,
    Name: '¿Necesitas dinero extra? Obtén el mejor préstamo personal para ti.',
    NameMobile: '¿Necesitas dinero extra? Obtén el mejor préstamo personal.',
    UrlTwinImage: BMoney,
    className: 'cardWo2',
    TrainingRedirectUrl: '/kanda/wo/pl-ft?utm_source=organic&utm_medium=coru&utm_campaign=home&utm_content=home',
    m375: '40px 0px 0px 20px',
    m425: '20px 0px 0px 20px',
    color: '#433E71 ',
    idurl: false,
  },
  {
    id: 3,
    Name: 'Crédito PyME.',
    UrlTwinImage: Pyme,
    className: 'cardWo3',
    TrainingRedirectUrl: '/kanda/wo/pymes?utm_source=organic&utm_medium=coru&utm_campaign=home&utm_content=home',
    m375: '40px 0px 0px 20px',
    color: '#433E71 ',
    idurl: true,
  },
];

const ConoceSolucionesRapidas = ({
  tituloSecundario,
  handleClick,
  shareCoru,
}) => {
  const shortenedM = 'nNjK3w4GZM8qnR9';
  const shortenedW = '5NMSR1B3piy7NZJ';
  const shortenedF = 'dLEHGGrJzrK25Pw';
  const message = 'Oye, descubrí una plataforma que se llama Coru en la que puedes obtener una 💳 tarjeta de crédito. Hasta puedes conseguir préstamos y seguros más fácil.👀:';
  return (
    <>
      {/* Conoce Soluciones Rapidas */}
      <ContentGeneric className="Container2" id="Soluciones">
        <div
          style={{ display: 'flex', flexDirection: 'row' }}
          className="ContainerTitleShare2">
          <HeadingTwo
            ml700="19px"

            hm="22px"
            w375="283px"

            className="bold"
            m="0px 12px 12px 0px"
            color="#3A6980">
            {tituloSecundario}
          </HeadingTwo>
          <ShareBtn
            className="itemShare2"
            Micon="10px 0px 0px 10px"
            bckRedS="#3A6980"
            bckRedShover="#3A6980"
            ShareImgC={ShareB}
            bckicono="#3A6980"
            seccion="ConoceSolucionesRapidas"
            message={message}
            shortenedM={shortenedM}
            shortenedW={shortenedW}
            shortenedF={shortenedF}
            shareCoru={shareCoru}
          />
        </div>

        <Description
          h375="84px"
          w375="320px"
          mD700="12px 5% 0px "
          m="0px 0px 10px"
          fz="18px"
          className="roboto"
          color="#3A6980">
          Encuentra soluciones a problemas relacionados con tus finanzas como
          créditos, préstamos y seguros, dónde obtenerlos y cómo usarlos.
        </Description>

        <Container1 ml="53px" mleft375="0px" mleft700="auto" id="contencard">
          {DataBase.map((data, idx) => (
            <div key={idx}>
              {!data.idurl
                ? <a
                href={data.TrainingRedirectUrl ? data.TrainingRedirectUrl : '#'}>
                <CardWo>
                  <ContentGeneric
                    style={{ borderRadius: '50%' }}
                    background = 'rgba(255, 255, 255, 0.5)'
                    width= '12%'
                    height= '15%'
                    w700= '80px !important'
                    height700= '80px !important'
                    position = 'absolute'
                    right = '120px'
                    right700 = '30px'
                    margin = '15px 0 0'
                    margin700 = '15px 0px 0px'
                    dis = 'flex'
                    zIndex = '0'
                  />
                  <div className={data.className}>
                    <Text2 color={data.color} m375={data.m375} m425={data.m425} className="bold">
                     {data.NameMobile !== undefined && window.screen.width <= 768 ? data.NameMobile : data.Name}
                    </Text2>
                    <ImgGeneric
                      positionMds="absolute"
                      display = 'flex'
                      zIndex= "0"
                      margin='33px 0px 0px 80px'
                      marginTopMds = '28px !important'
                      widthMds="50px"
                      heightMds="50px"
                      rightMds = "45px"
                      src={data.UrlTwinImage}
                      alt="woImg" />
                  </div>
                </CardWo>
              </a>
              : 
              <a href="https://coru.com/kanda/wo/pymes?utm_source=organic&utm_medium=coru&utm_campaign=home&utm_content=home">
                <CardWo>
                  <ContentGeneric
                    style={{ borderRadius: '50%' }}
                    background = 'rgba(255, 255, 255, 0.5)'
                    width= '12%'
                    height= '15%'
                    w700= '80px !important'
                    height700= '80px !important'
                    position = 'absolute'
                    right = '120px'
                    right700 = '30px'
                    margin = '15px 0 0'
                    margin700 = '15px 0px 0px'
                    dis = 'flex'
                    zIndex = '0'
                  />
                  <div className={data.className}>
                    <Text2 color={data.color} m375={data.m375} m425={data.m425} className="bold">
                      {data.Name}
                    </Text2>
                    <ImgGeneric
                      positionMds="absolute"
                      display = 'flex'
                      zIndex= "0"
                      margin='33px 0px 0px 80px'
                      marginTopMds = '28px !important'
                      widthMds="50px"
                      heightMds="50px"
                      rightMds = "45px"
                      src={data.UrlTwinImage}
                      alt="woImg" />
                  </div>
                </CardWo>
              </a>}
            </div>
          ))}
          <KandaButton
            width="84%"
            w375="90%"
            ml375="19px"
            ml425="22px"
            typeBtn="active"
            mt="20px"
            w700="90%"
            action={() => handleClick('ConoceSoluciones', 'CreaCuenta')}
            id="masterclassMobile">
            Conoce Nuestras Soluciones
          </KandaButton>
        </Container1>
      </ContentGeneric>
    </>
  );
};

export default ConoceSolucionesRapidas;
