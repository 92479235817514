/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-var */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import styled from "styled-components";
import {
  SubtitleOne,
  Container,
} from "../../components/common/common.styled";
import "../../assets/styles/TextCustom.css";
import InputAna from "../../components/common/InputAna";
import MX from '../../lang/placeholder-MX.json';
import US from '../../lang/placeholder-US.json';
import PT from '../../lang/placeholder-pt.json';

const FullNameOfBrasil = ({
  form, setForm, setvalidCampos, validCampos, textHolder
}) => {
  const [error] = useState({
    fullname: false,
    fullnamevalid: -1,
  });

  const [texterror] = useState({
    fullname: ""
  });

  const ValidCampos = () => {
    if (
      !error.fullname && form.fullname.length >= 3
    ) {
      // console.log('nombre Validado crack');
      setvalidCampos({
        ...validCampos,
        personales: true
      });
    } else {
      // console.log('nombre NOOO Validado crack');
      setvalidCampos({
        ...validCampos,
        personales: false
      });
    }
    // console.log('Validar campos en name', validCampos);
  };

  const ValidLetras = (e) => {
    var nombre = e.target.name;
    var valor = e.target.value;

    setForm({
      ...form,
      [nombre]: valor.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ ]/g, ""),
    });
    if (valor.length > 2) {
      error[`${nombre}valid`] = 0;
      error[nombre] = false;
      texterror[nombre] = "";
    } else if (nombre === "secondname") {
      if (valor.length === 0) {
        error[nombre] = false;
        texterror[nombre] = "";
        error[`${nombre}valid`] = -1;
      } else {
        error[`${nombre}valid`] = 1;
        error[nombre] = true;
        texterror[nombre] = "Dado incorreto";
      }
    } else {
      error[`${nombre}valid`] = 1;
      error[nombre] = true;
      texterror[nombre] = "Dado incorreto";
    }
    ValidCampos();
  };

  return (
    <WrapperOfInputs>
      <SubtitleOne
        className="font-weight-bold roboto"
        color="#AC2E5B"
        size="16px"
        sizeMobile="16px"
        mb="0px">
         <FormattedMessage
          id="app.nome"
          defaultMessage="Qual seu nome?"
        />
      </SubtitleOne>
      <form autoComplete="off" className="">
        <Container width="100%" widthMobile="100%">
          <InputWrap>
            <InputAna
              borderR="14px"
              placeholder={textHolder}
              value={form.fullname}
              id={"fullname"}
              type="text"
              handleChange={ValidLetras}
              handleBlur={ValidLetras}
              error={error.fullname}
              texterror={texterror.fullname}
              errorinicial={error.fullnamevalid}
            />
          </InputWrap>
        </Container>
      </form>
    </WrapperOfInputs>
  );
};

export default FullNameOfBrasil;

const InputWrap = styled.div`
    padding-top: 12px;
    /* padding-bottom: 24px; */
    width: 100%;
`;

const WrapperOfInputs = styled.div`
    padding-bottom: 12px;
    /* border: 1px solid red; */
`;
