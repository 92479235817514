import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const FullScreenDialogCC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const bull = <span className={classes.bullet}>•</span>;
  return (
        <Fragment>
            <div>
                <Dialog open={open} fullScreen TransitionComponent={Transition}>
                    <AppBar className={classes.appBar} style={{ background: '#1CB158' }}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                            </Typography>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <div className={classes.details} style={{ margin: '10px' }}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardMedia
                                        image=""
                                        title=""
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Tarjeta de Crédito Citibanamex Rewards
                                    </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {bull} 1 ThankYou Point por cada dólar
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className={classes.details} style={{ margin: '10px' }}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardMedia
                                        image=""
                                        title=""
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Tarjeta Vive BBVA Bancomer
                                    </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {bull} Compra seguro en línea con BBVA wallet <br />
                                            {bull} Al comprar con tu tarjeta de crédito
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className={classes.details} style={{ margin: '10px' }}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardMedia
                                        image=""
                                        title=""
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Falabella Soriana Mastercard
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="h2">
                                            {bull} 3% dinero electrónico al pagar en Soriana, Soriana.com y Citi Club
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <Button style={{ margin: '10px', width: '40%' }} onClick={handleClose} variant="contained" color="secondary">
                            Da click aquí para aplicar
                        </Button>
                        <Link to="/">Ver todas las tarjetas</Link>
                    </div>
                </Dialog>
            </div>
        </Fragment>
  );
};

export default FullScreenDialogCC;
