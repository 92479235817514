import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import TagManager from 'react-gtm-module';
import AnchorLink from "react-anchor-link-smooth-scroll";
import { CREATE_CLICK } from '../../../mutations';
import CoruLogo from "../Assets/img/Logo.svg";
import {
  FooterDescriptionHome,
  FooterContainerHome,
  FooterVerticals,
  FooterDiv1,
  FooterDiv2,
  FooterDiv3,
  FooterVerticalsA,
} from '../Assets/styles/Footer.styled.js';
import ClickButton from '../../../Helpers/HookHelpers';
import SectionShareFooter from '../utils/SectionShare.js';
import { Footer_VerticalsA } from '../../common/common.styled';

const Footer = ({isAboutUS,isLpBrasil,section,isLpForBusiness}) => {
  const pathnameP = window.location.pathname;
  const pathname = pathnameP.split('/')[1];
  const { actions } = ClickButton();

  const [createClickFront] = useMutation(
    CREATE_CLICK,

    {
      onCompleted({ createClick }) {
        console.log(createClick);
        localStorage.setItem('activityDate', new Date());
      },
      onError(err) {
        console.log(err);
      },
    },
    [],
  );

  const sendMongoAndGTM = (action) => {
    const tagManagerArgs = {
      dataLayer: {
        event: action,
      },
    };
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: action,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };

    if (sessionStorage.getItem('id_session')) {
      createClickFront({ variables: createClickInput });
    }
    TagManager.dataLayer(tagManagerArgs);
  };
  const history = useHistory();
  const handleClickAviso = () => {
    history.push('/privacy-notice');
    sendMongoAndGTM('ClickFooterPrivacy');
  };

  const handleClickConocenos = () => {
    actions({
      action: 'ClickButtonCoruHome',
      variables: 'FooterConócenos',
      redirection: '/about-us',
    });
    // history.push("/about-us");
    sendMongoAndGTM('ClickFooterUs');
  };
  const handleClickAdvisers = () => {
    actions({
      action: 'ClickButtonCoruHome',
      variables: 'FooterColaboradoresCoru',
      redirection: '/advisers',
    });
    // history.push("/advisers");
    sendMongoAndGTM('ClickFooterAdvisers');
  };
  const handleOpen = (url, click) => {
    history.push(url);
    sendMongoAndGTM(click);
  };

  const handleClickCoru = (name) => {
    switch (name) {
      case 'Soluciones':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `/user-validation/`,
          href: true,
        });
        break;
      case 'Tarjetadecrédito':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://coru.com/wo/card-funnel?utm_source=footer&utm_medium=${pathname}home`,
          href: true,
        });
        break;
      case 'Préstamospersonales':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://coru.com/wo/loans-funnel?utm_source=footer&utm_medium=${pathname}home`,
          href: true,
        });
        break;
      case 'SegurodeAuto':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://coru.com/wo/car-insurance?utm_source=footer&utm_medium=${pathname}home`,
          href: true,
        });
        break;
      case 'Contacto':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
        });
        break;
      case 'Blog':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
          redirection: `https://coru.com/blog/`,
          href: true,
        });
        break;
      case 'Coru':
        actions({
          action: 'ClickButtonCoruHome',
          variables: `Footer${name}`,
        });
        break;
      default:
        break;
    }
    // history.push('/lp/categories-products');
  };
  return (
    <>
 <FooterContainerHome backcolor={isAboutUS === "si" ? "transparent" : "#ffff"}>
    <FooterDescriptionHome height={isLpBrasil === "si" ? '160px' : ''} heightM={isLpBrasil === "si" ? '160px' : ''} color="#061D80" paddingWeb="3%" paddingMobile="0px" backcolor={isAboutUS === "si" ? "transparent" : "#fff"}>
    {isLpBrasil !== 'si' ? (
      <Fragment>
        {/* Descktop */}
        { !isLpForBusiness
          ? <FooterDiv3 pl="10%">
              <FooterVerticalsA className="roboto" onClick={() => handleClickCoru('Soluciones')} >
                  Soluciones
              </FooterVerticalsA>

              <FooterVerticalsA className="roboto" onClick={() => handleClickCoru('Tarjetadecrédito')} >
                  Tarjeta de crédito
              </FooterVerticalsA>

              <FooterVerticalsA className="roboto" onClick={() => handleClickCoru('Préstamospersonales')} >
                  Préstamos personales
              </FooterVerticalsA>

              <FooterVerticals className="roboto" onClick={() => handleClickCoru('SegurodeAuto')} >
                Seguro de auto
              </FooterVerticals>
            </FooterDiv3>
          : ""
        }
        <FooterDiv3 pl="5%">
          <Footer_VerticalsA colorHover="#061D80" color="#061D80" className="roboto" href={`/for-business`} target="_blank">
            For Business
          </Footer_VerticalsA>
          { !isLpForBusiness 
              ?   <FooterVerticals className="roboto" onClick={handleClickConocenos} >
              Conócenos
            </FooterVerticals>
            :""
          }
            <FooterVerticals className="roboto" onClick={() => handleClickCoru('Blog')}>
              Blog
            </FooterVerticals>

          <FooterVerticals className="roboto" onClick={() => handleClickAdvisers()} >
            Colaboradores
          </FooterVerticals>
        </FooterDiv3>
        </Fragment>
    ) : null}
      <FooterDiv3>
        <SectionShareFooter section={section}/>
        {isLpBrasil !== 'si' ? (
          <FooterVerticalsA target="_blanck" href="https://coru.com/privacy-notice" size="14px" line="160%" className="roboto" width="91%" left="auto" cursor="pointer" >
          Aviso de Privacidad Coru 2022
          </FooterVerticalsA>
         ) : null}
      </FooterDiv3>
   

    {isLpBrasil !== 'si' ? (
      <Fragment>
        {/* Mobile */}
        { !isLpForBusiness 
          ?  <FooterDiv1 width="100%">
            <FooterVerticals className="roboto" onClick={() => handleClickCoru('Soluciones')} >
              Soluciones
            </FooterVerticals>

            <FooterVerticals className="roboto" onClick={() => handleClickCoru('Tarjetadecrédito')}>
              Tarjeta de crédito
            </FooterVerticals>

            <FooterVerticals className="roboto" onClick={() => handleClickCoru('Préstamospersonales')} >
              Préstamos personales
            </FooterVerticals>
          </FooterDiv1>
          : ""
        }
        <FooterDiv2 width="100%">
          { !isLpForBusiness 
            ?   <>
              <FooterVerticals className="roboto" onClick={() => handleClickCoru('SegurodeAuto')}>
                Seguro de auto
              </FooterVerticals>
              <hr style={{ border: '  0.5px solid rgba(6, 29, 128, 0.24)', width: "70%", marginRight: "115px" }} />
            </>
            : "" 
          }
            <Footer_VerticalsA colorHover="#061D80" color="#061D80" className="roboto" href={`/for-business`} target="_blank">
            For Business
          </Footer_VerticalsA>
          { !isLpForBusiness 
            ? <FooterVerticals className="roboto" onClick={handleClickConocenos}>
            Conócenos
          </FooterVerticals>
            : ""
          }
          <FooterVerticals className="roboto" onClick={() => handleClickCoru('Blog')}>
            Blog
          </FooterVerticals>
        </FooterDiv2>
      </Fragment>
    ) : null }
    
      <FooterDiv2 mtop=" 20px" width="100%">
        <SectionShareFooter section={section}/>
        {isLpBrasil !== 'si' ? (
          <FooterVerticalsA target="_blanck" href="https://coru.com/privacy-notice" size="14px" line="160%" className="roboto" displayD="none" displayM="block" >
            Aviso de Privacidad Coru 2022
          </FooterVerticalsA>
        ) : null}
      </FooterDiv2>
      </FooterDescriptionHome>
    </FooterContainerHome>
    </>

  );
};

export default Footer;
