/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import {
  ImageSections,
  CardsUModal,
  CardsTxt,
  ModalDivFlex,
  Images,
  CardsAnchorBtn,
} from '../../assets/styles/Training.styled';
import '../../assets/styles/Home.css';
import stars from '../../assets/img/anacoach/5Stars.png';
import SectionShare from '../common/ShareSection';
import { CardsBtn } from '../../assets/styles/Training.styled';
import ModalStori from '../common/ModalStori';

function ModalProductos(props) {
  const [modalStori, setModalStori] = useState(false);
  const pro = JSON.parse(props.products);
  let productsThree = pro[0].Name;
  if (pro.length === 2) {
    productsThree = `${pro[0].Name} ${pro[1].Name}`;
  } else if (pro.length === 3) {
    productsThree = `${pro[0].Name}, ${pro[1].Name}, ${pro[2].Name}`;
  } else if (pro.length > 3) {
    productsThree = `${pro[0].Name}, ${pro[1].Name}, ${pro[2].Name}, ${pro[3].Name}`;
  } else if (pro.length > 4) {
    productsThree = `${pro[0].Name}, ${pro[1].Name}, ${pro[2].Name}, ${pro[3].Name}, ${pro[4].Name}`;
  } else if (pro.length > 5) {
    productsThree = `${pro[0].Name}, ${pro[1].Name}, ${pro[2].Name}, ${pro[3].Name}, ${pro[4].Name}, ${pro[5].Name}`;
  }

  const showButton = props.showButton ? props.showButton : false;
  return (
    <>
      <CardsUModal
        width="60%"
        marginLeft="18%"
        widthxs="100%"
        marginrightxs="auto"
        marginLeftxs="auto"
        className="productoMod"
        id={pro[0].IDProduct}
        height={'auto'}
        mheight={'auto'}
      >
        <ModalDivFlex width="100%" align="left">
          <ImageSections
            width="74px"
            widthweb="74px"
            heightweb="auto"
            src={pro[0].ImageURL}
            className="img-fluid"
          />
        </ModalDivFlex>
        <ModalDivFlex width="100%" align="left">
          <CardsTxt
            className="cabin"
            color="#07210A"
            mt="14px"
            mb="14px"
            weight="600"
            size="20px"
            sizeweb="20px"
          >
            {pro[0].Name}
          </CardsTxt>
          <hr />
          {pro[0].Name === 'StoriCard' &&
              <CardsTxt color="#EC407A" className="roboto" size="14px">
                Garantizada
              </CardsTxt>
            }
          <CardsTxt color="#4F4F4F" size="14px">
            {pro[0].BusinessAbr}
          </CardsTxt>
          {JSON.parse(pro[0].productDetail.response).map((detalle) => {
            if (detalle.Name === 'CAT') {
              return (
                <CardsTxt color="#4F4F4F" size="14px">
                  CAT {(detalle.Description * 100).toFixed(2)}%
                </CardsTxt>
              );
            }
          })}
          {JSON.parse(pro[0].productDetail.response).map((detalle) => {
            if (detalle.Name === 'Interés anual') {
              return (
                <CardsTxt color="#4F4F4F" size="14px">
                  Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                </CardsTxt>
              );
            }
          })}

          {showButton ? (
            <CardsBtn
              back = {props.color}
              type="button"
              min_w="100%"
              min_web="100%"
              mtopw="10px"
              mtop="0px"
              mtopmd="0px"
              mbottom = "5%"
              mbottomM = "26px"
              onClick={() => {
                props.createOpportunities(pro[0].IDProduct, pro[0].ClickOut);
                { pro[0].Name === 'StoriCard' ? setModalStori(true) : setModalStori(false); }
              }}
            >
                Seleccionar
            </CardsBtn>
          ) : (
            ''
          )}

          <Images
            src={stars}
            top="0"
            topweb="0"
            left="0"
            leftw="0"
            position="initial"
            width="116px"
            widthw="116px"
            className="hidden img-fluid"
          ></Images>
        </ModalDivFlex>
      </CardsUModal>
      {pro.length > 1 ? (
        <CardsUModal
          width="60%"
          marginLeft="18%"
          widthxs="100%"
          marginrightxs="auto"
          marginLeftxs="auto"
          className="productoMod"
          id={pro[1].IDProduct}
          height={'auto'}
          mheight={'auto'}
        >
          <ModalDivFlex width="100%" align="left">
            <ImageSections
              width="74px"
              widthweb="74px"
              heightweb="auto"
              src={pro[1].ImageURL}
              className="img-fluid"
            />
          </ModalDivFlex>
          <ModalDivFlex width="100%" align="left">
            <CardsTxt
              color="#07210A"
              className="cabin"
              mt="14px"
              mb="14px"
              weight="600"
              size="20px"
              sizeweb="20px"
            >
              {pro[1].Name}
            </CardsTxt>
            <hr />
            {pro[1].Name === 'StoriCard' &&
              <CardsTxt color="#EC407A" className="roboto" size="14px">
                Garantizada
              </CardsTxt>
            }
            <CardsTxt color="#4F4F4F" className="roboto" size="14px">
              {pro[1].BusinessAbr}
            </CardsTxt>
            {JSON.parse(pro[1].productDetail.response).map((detalle) => {
              if (detalle.Name === 'CAT') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    CAT {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}
            {JSON.parse(pro[1].productDetail.response).map((detalle) => {
              if (detalle.Name === 'Interés anual') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}

            {showButton ? (
              <CardsBtn
                back = {props.color}
                type="button"
                min_w="100%"
                min_web="100%"
                mtopw="10px"
                mtop="0px"
                mtopmd="0px"
                mbottom = "5%"
                mbottomM = "26px"
                onClick={() => {
                  props.createOpportunities(pro[1].IDProduct, pro[1].ClickOut);
                  { pro[1].Name === 'StoriCard' ? setModalStori(true) : setModalStori(false); }
                }}
              >
                  Seleccionar
              </CardsBtn>
            ) : (
              ''
            )}

            <Images
              src={stars}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              position="initial"
              width="116px"
              widthw="116px"
              className="hidden img-fluid"
            ></Images>
          </ModalDivFlex>
        </CardsUModal>
      ) : null}
      {pro.length > 2 ? (
        <CardsUModal
          width="60%"
          marginLeft="18%"
          widthxs="100%"
          marginrightxs="auto"
          marginLeftxs="auto"
          className="productoMod"
          id={pro[2].IDProduct}
          height={'auto'}
          mheight={'auto'}
        >
          <ModalDivFlex width="100%" align="left">
            <ImageSections
              width="74px"
              widthweb="74px"
              heightweb="auto"
              src={pro[2].ImageURL}
              className="img-fluid"
            />
          </ModalDivFlex>
          <ModalDivFlex width="100%" align="left">
            <CardsTxt
              color="#07210A"
              className="cabin"
              mt="14px"
              mb="14px"
              weight="600"
              size="20px"
              sizeweb="20px"
            >
              {pro[2].Name}
            </CardsTxt>
            <hr />
            {pro[2].Name === 'StoriCard' &&
              <CardsTxt color="#EC407A" className="roboto" size="14px">
                Garantizada
              </CardsTxt>
            }
            <CardsTxt color="#4F4F4F" className="roboto" size="14px">
              {pro[2].BusinessAbr}
            </CardsTxt>
            {JSON.parse(pro[2].productDetail.response).map((detalle) => {
              if (detalle.Name === 'CAT') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    CAT {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}
            {JSON.parse(pro[2].productDetail.response).map((detalle) => {
              if (detalle.Name === 'Interés anual') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}

            {showButton ? (
            <CardsBtn
              back = {props.color}
              type="button"
              min_w="100%"
              min_web="100%"
              mtopw="10px"
              mtop="0px"
              mtopmd="0px"
              mbottom = "5%"
              mbottomM = "26px"
              onClick={() => {
                props.createOpportunities(pro[2].IDProduct, pro[2].ClickOut);
                { pro[2].Name === 'StoriCard' ? setModalStori(true) : setModalStori(false); }
              }}
            >
                Seleccionar
            </CardsBtn>
            ) : (
              ''
            )}

            <Images
              src={stars}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              position="initial"
              width="116px"
              widthw="116px"
              className="hidden img-fluid"
            ></Images>
          </ModalDivFlex>
        </CardsUModal>
      ) : null}
      {pro.length > 3 ? (
        <CardsUModal
          width="60%"
          marginLeft="18%"
          widthxs="100%"
          marginrightxs="auto"
          marginLeftxs="auto"
          className="productoMod"
          id={pro[3].IDProduct}
          height={'auto'}
          mheight={'auto'}
        >
          <ModalDivFlex width="100%" align="left">
            <ImageSections
              width="74px"
              widthweb="74px"
              heightweb="auto"
              src={pro[3].ImageURL}
              className="img-fluid"
            />
          </ModalDivFlex>
          <ModalDivFlex width="100%" align="left">
            <CardsTxt
              color="#07210A"
              className="cabin"
              mt="14px"
              mb="14px"
              weight="600"
              size="20px"
              sizeweb="20px"
            >
              {pro[3].Name}
            </CardsTxt>
            <hr />
            {pro[3].Name === 'StoriCard' &&
              <CardsTxt color="#EC407A" className="roboto" size="14px">
                Garantizada
              </CardsTxt>
            }
            <CardsTxt color="#4F4F4F" className="roboto" size="14px">
              {pro[3].BusinessAbr}
            </CardsTxt>
            {JSON.parse(pro[3].productDetail.response).map((detalle) => {
              if (detalle.Name === 'CAT') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    CAT {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}
            {JSON.parse(pro[3].productDetail.response).map((detalle) => {
              if (detalle.Name === 'Interés anual') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}

            {showButton ? (
              <CardsBtn
                back = {props.color}
                type="button"
                min_w="100%"
                min_web="100%"
                mtopw="10px"
                mtop="0px"
                mtopmd="0px"
                mbottom = "5%"
                mbottomM = "26px"
                onClick={() => {
                  props.createOpportunities(pro[3].IDProduct, pro[3].ClickOut);
                  { pro[3].Name === 'StoriCard' ? setModalStori(true) : setModalStori(false); }
                }}
              >
                  Seleccionar
              </CardsBtn>
            ) : (
              ''
            )}

            <Images
              src={stars}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              position="initial"
              width="116px"
              widthw="116px"
              className="hidden img-fluid"
            ></Images>
          </ModalDivFlex>
        </CardsUModal>
      ) : null}
      {pro.length > 4 ? (
        <CardsUModal
          width="60%"
          marginLeft="18%"
          widthxs="100%"
          marginrightxs="auto"
          marginLeftxs="auto"
          className="productoMod"
          id={pro[4].IDProduct}
          height={'auto'}
          mheight={'auto'}
        >
          <ModalDivFlex width="100%" align="left">
            <ImageSections
              width="74px"
              widthweb="74px"
              heightweb="auto"
              src={pro[4].ImageURL}
              className="img-fluid"
            />
          </ModalDivFlex>
          <ModalDivFlex width="100%" align="left">
            <CardsTxt
              color="#07210A"
              className="cabin"
              mt="14px"
              mb="14px"
              weight="600"
              size="20px"
              sizeweb="20px"
            >
              {pro[4].Name}
            </CardsTxt>
            <hr />
            {pro[4].Name === 'StoriCard' &&
              <CardsTxt color="#EC407A" className="roboto" size="14px">
                Garantizada
              </CardsTxt>
            }
            <CardsTxt color="#4F4F4F" className="roboto" size="14px">
              {pro[4].BusinessAbr}
            </CardsTxt>
            {JSON.parse(pro[4].productDetail.response).map((detalle) => {
              if (detalle.Name === 'CAT') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    CAT {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}
            {JSON.parse(pro[4].productDetail.response).map((detalle) => {
              if (detalle.Name === 'Interés anual') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}

            {showButton ? (
              <CardsBtn
                back = {props.color}
                type="button"
                min_w="100%"
                min_web="100%"
                mtopw="10px"
                mtop="0px"
                mtopmd="0px"
                mbottom = "5%"
                mbottomM = "26px"
                onClick={() => {
                  props.createOpportunities(pro[4].IDProduct, pro[4].ClickOut);
                  { pro[4].Name === 'StoriCard' ? setModalStori(true) : setModalStori(false); }
                }}
              >
                  Seleccionar
              </CardsBtn>
            ) : (
              ''
            )}

            <Images
              src={stars}
              top="0"
              topweb="0"
              left="0"
              leftw="0"
              position="initial"
              width="116px"
              widthw="116px"
              className="hidden img-fluid"
            ></Images>
          </ModalDivFlex>
        </CardsUModal>
      ) : null}
      {pro.length > 5 ? (
        <CardsUModal
          width="60%"
          marginLeft="18%"
          widthxs="100%"
          marginrightxs="auto"
          marginLeftxs="auto"
          className="productoMod"
          id={pro[5].IDProduct}
          height={'auto'}
          mheight={'auto'}
        >
          <ModalDivFlex width="100%" align="left">
            <ImageSections
              width="74px"
              widthweb="74px"
              heightweb="auto"
              src={pro[5].ImageURL}
              className="img-fluid"
            />
          </ModalDivFlex>
          <ModalDivFlex width="100%" align="left">
            <CardsTxt
              color="#07210A"
              className="cabin"
              mt="14px"
              mb="14px"
              weight="600"
              size="20px"
              sizeweb="20px"
            >
              {pro[5].Name}
            </CardsTxt>
            <hr />
            {pro[5].Name === 'StoriCard' &&
              <CardsTxt color="#EC407A" className="roboto" size="14px">
                Garantizada
              </CardsTxt>
            }
            <CardsTxt color="#4F4F4F" className="roboto" size="14px">
              {pro[5].BusinessAbr}
            </CardsTxt>
            {JSON.parse(pro[5].productDetail.response).map((detalle) => {
              if (detalle.Name === 'CAT') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    CAT {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}
            {JSON.parse(pro[5].productDetail.response).map((detalle) => {
              if (detalle.Name === 'Interés anual') {
                return (
                  <CardsTxt color="#4F4F4F" className="roboto" size="14px">
                    Tasa de interés {(detalle.Description * 100).toFixed(2)}%
                  </CardsTxt>
                );
              }
            })}

            {showButton ? (
              <CardsBtn
                back = {props.color}
                type="button"
                min_w="100%"
                min_web="100%"
                mtopw="10px"
                mtop="0px"
                mtopmd="0px"
                mbottom = "5%"
                mbottomM = "26px"
                onClick={() => {
                  props.createOpportunities(pro[5].IDProduct, pro[5].ClickOut);
                  { pro[5].Name === 'StoriCard' ? setModalStori(true) : setModalStori(false); }
                }}
              >
                  Seleccionar
              </CardsBtn>
            ) : (
              ''
            )}
        <Images
            src={stars}
            top="0"
            topweb="0"
            left="0"
            leftw="0"
            position="initial"
            width="116px"
            widthw="116px"
            className="hidden img-fluid"
          ></Images>
        </ModalDivFlex>
      </CardsUModal>
      ) : null}
      <div id="productos3" style={{ display: 'none' }}>
        {productsThree}
      </div>
      {/* menos de 7k */}
      { modalStori && <ModalStori />}
      {showButton ? <SectionShare WO=""></SectionShare> : null}
    </>
  );
}

export default ModalProductos;

ModalProductos.defaultProps = {
  createOpportunities: (id, click_out) => window.open(click_out, '_blank'),
};
