import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import Carousel from 'react-elastic-carousel';
// import styled from "styled-components";
import Vexi from '../../Assets/Imgs/partners/vexi.svg';
import GNP from '../../Assets/Imgs/partners/GNP.svg';
import Citi from '../../Assets/Imgs/partners/citi.svg';
import American from '../../Assets/Imgs/partners/american.svg';
import BBVA from '../../Assets/Imgs/partners/BBVA.svg';
import Nu from '../../Assets/Imgs/partners/nu.svg';
import Rappi from '../../Assets/Imgs/partners/rappi.svg';
import Scotia from '../../Assets/Imgs/partners/scotia.svg';
import Kueski from '../../Assets/Imgs/partners/kueski.svg';
import Stori from '../../Assets/Imgs/partners/stori.svg';
import Fintonic from '../../Assets/Imgs/partners/fintonic.svg';

import HSBC from '../../Assets/Imgs/partners/HSBC.svg';
import Mapfre from '../../Assets/Imgs/partners/Mapfre.svg';
import ana from '../../Assets/Imgs/partners/ana.svg';
import palacio from '../../Assets/Imgs/partners/palacio.svg';
import dinn from '../../Assets/Imgs/partners/dinn.svg';
import credilike from '../../Assets/Imgs/partners/credilike.svg';
import credijusto from '../../Assets/Imgs/partners/credijusto.svg';
import coppel from '../../Assets/Imgs/partners/coppel.svg';
import Chubb from '../../Assets/Imgs/partners/Chubb.svg';
import netwoth from '../../Assets/Imgs/partners/netwoth.svg';
import Kavak from '../../Assets/Imgs/partners/kavak.svg';

import Liverpool from '../../Assets/Imgs/partners/Liverpool.svg';
import GBM from '../../Assets/Imgs/partners/GBM.svg';
import azteca from '../../Assets/Imgs/partners/azteca.svg';
import creditas from '../../Assets/Imgs/partners/creditas.svg';
import Qualitas from '../../Assets/Imgs/partners/Qualitas.svg';
import quetarjeta from '../../Assets/Imgs/partners/quetarjeta.svg';
import curadeuda from '../../Assets/Imgs/partners/curadeuda.svg';
import mozper from '../../Assets/Imgs/partners/mozper.svg';
import Banorte from '../../Assets/Imgs/partners/Banorte.svg';
import montePiedad from '../../Assets/Imgs/partners/montePiedad.svg';
import invex from '../../Assets/Imgs/partners/invex.svg';

const list = [
  Vexi,
  GNP,
  Citi,
  American,
  BBVA,
  Nu,
  Rappi,
  Scotia,
  Kueski,
  Stori,
  Fintonic,
];

const ListTwo = [
  HSBC,
  Mapfre,
  ana,
  palacio,
  dinn,
  credilike,
  credijusto,
  coppel,
  Chubb,
  netwoth,
  Kavak,
];

const ListThree = [
  Liverpool,
  GBM,
  azteca,
  creditas,
  Qualitas,
  quetarjeta,
  curadeuda,
  mozper,
  Banorte,
  montePiedad,
  invex,
];
const CarouselProfesion = () => {
  const ref = useRef(null);
  let resetTimeout;
  const [isMobil, setIsMobil] = useState();

  const renderPagination = ({ pages, activePage, onClick }) => (
    <div className="pagination">
      {pages.map((page) => {
        const isActive = activePage === page;
        const backgroundColor = isActive ? "#F64282" : "#FFE4EE";
        const buttonStyle = {
          backgroundColor,
          margin: '0px 12px',
        };

        return (
          <SelectorButton
            key={page}
            // onClick={() => onClick(page, setActualPage(page))}
            style={buttonStyle}
          />
        );
      })}
    </div>
  );

  useEffect(() => {
    const mediaQueryMobil = window.matchMedia("(max-width: 375px)");
    setIsMobil(mediaQueryMobil.matches);

    const isMediaQueryMobilListener = (event) => {
      setIsMobil(event.matches);
    };

    mediaQueryMobil.addEventListener("change", isMediaQueryMobilListener);

    return () => {
      mediaQueryMobil.removeEventListener("change", isMediaQueryMobilListener);
    };
  }, []);

  return (
    <Wrapper>
      <Carousel
        renderPagination={renderPagination}
        ref={ref}
        showArrows={false}
        enableAutoPlay={true}
        autoPlaySpeed={1900}
        onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          if (index + 1 === 3) {
            resetTimeout = setTimeout(() => {
              ref.current.goTo(0);
            }, 1500);
          }
        }}
      >
          <Container>
            <Row>
                {list.slice(0, 6).map((partner, index) => (
                <ImageContainer key={index} >
                  <Image src={partner} />
                </ImageContainer>
                ))}
              </Row>
              <Row>
                {list.slice(6, 11).map((partner, index) => (
                    <ImageContainer key={index} >
                    <Image src={partner} />
                  </ImageContainer>
                ))}
              </Row>
            </Container>
            <Container>
            <Row>
                {ListTwo.slice(0, 6).map((partner, index) => (
                <ImageContainer key={index} >
                  <Image src={partner} />
                </ImageContainer>
                ))}
              </Row>
              <Row>
                {ListTwo.slice(6, 11).map((partner, index) => (
                    <ImageContainer key={index} >
                    <Image src={partner} style={{
                      height: partner.includes('Chubb') && !isMobil ? 20 : 16
                    }} />
                  </ImageContainer>
                ))}
              </Row>
            </Container>
            <Container>
            <Row>
                {ListThree.slice(0, 6).map((partner, index) => (
                <ImageContainer key={index} >
                  <Image src={partner} />
                </ImageContainer>
                ))}
              </Row>
              <Row>
                {ListThree.slice(6, 11).map((partner, index) => (
                    <ImageContainer key={index} >
                    <Image src={partner} />
                  </ImageContainer>
                ))}
              </Row>
            </Container>
      </Carousel>
    </Wrapper>
  );
};

export default CarouselProfesion;

const Wrapper = styled.section`
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    width: 100%;
    .rec-carousel-wrapper {
    height: auto !important;
    
    background-color: transparent !important;
  }
  .rec-carousel {
    height: 200px !important;
  }
  @media (max-width: 834px) {
    .rec-carousel-wrapper {
    height: auto !important;    
    background-color: transparent !important;
  }
  .rec-carousel {
    height: 300px !important;
  }
  }
  @media (max-width: 834px) {
    .rec-carousel {
    height: 300px !important;
  }
  @media (max-width: 600px) {
    .rec-carousel {
    height: 400px !important;
  }
  }
  @media (max-width: 531px) {
    .rec-carousel {
    height: 450px !important;
  }
  }
  @media (max-width: 400px) {
    .rec-carousel {
    height: 580px !important;
  }
  }
  @media (max-width: 375px) {
    .rec-carousel-wrapper {
    height: auto !important;    
    background-color: transparent !important;
  }
  .rec-carousel {
    height: 150px !important;
  }
  }
}
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: auto;
  @media (max-width: 834px) {
    height: auto;
  }
  @media (max-width: 375px) {
    height: auto;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-evenly;
  width: auto;
  height: fit-content;
  margin-bottom: 50px;
  @media (max-width: 834px) {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    justify-content: space-evenly;
    margin-bottom: 48px;
  }
  @media (max-width: 375px) {
    gap: 15px;
    margin-bottom: 10px;
  }
`;

const Image = styled.img`
  height: 40px;
  @media (max-width: 834px) {
    height: 32px;
  }
  @media (max-width: 375px) {
    height: 16px;
  }
`;

const ImageContainer = styled.div`
  align-self: center;
`;

const SelectorButton = styled.div`
  width: 40px;
  height: 6px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 15px;
`;
