import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../assets/styles/Home.css';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Image, Div_Flex } from '../assets/styles/Home.styled';
import History_Clients from '../components/Home/Carousel';
import { GET_TRAINING_HOME_CATEGORIES } from '../queries';
import CookiesNotice from '../components/common/CookiesNotice';
import {
  Container1,
  ContainerSubMenu,
  ContainerMenu,
  Rombo,
  ContainerBottons,
  Rectangle20,
  Seccion2,
  TitleEntrenamientos,
  SubTexto,
  DivTrofeos,
  SocialMedia,
  Triangle,
  VectorNum,
  ContentHomeSection,
  Shapes,
} from '../components/common/Home.styled';
import {
  Button,
  ParagrapTwo,
  Container,
  Col6,
} from '../components/common/common.styled';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import CarrouselP from '../components/Home/CarouselPrincipal';
import ModalTestViralidad from '../components/Home/ModalTestViralidad';
import CarrouselEmpresas from '../components/Home/CarouselEmpresas';
import HomeCategories from '../components/Home/HomeCategories';
import { CREATE_SESSION, CREATE_CLICK } from '../mutations';
import Shape from '../assets/img/home/Shape.png';
import What from '../assets/img/home/What.png';
import Messenger from '../assets/img/home/messenger.png';
import Compartir from '../assets/img/home/compartir.png';
import CaretUp from '../assets/img/home/caretup.png';
import trophies from '../assets/img/home/FITpress-01.svg';
import expansion from '../assets/img/home/expansion.png';
import financiero from '../assets/img/home/financiero.png';
import forbes from '../assets/img/home/forbes.png';
import universal from '../assets/img/home/universal.svg';
import ChatIcono from '../components/Home/ChatIcono';
import { GET_DAY_OF_WEEK } from '../queries';
import { Top_Text } from '../assets/styles/Feed.styled';
import Kanda from '../components/Kanda/Kanda';
import '../assets/styles/kanda/KandaDesktop.css';
// Modal
import ClickButton from '../Helpers/HookHelpers';
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '380px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

const tagManagerArgs = {
  gtmId: 'GTM-NT59K8W',
  dataLayer: {
    pageName: 'home',
  },
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-53999647-2');
ReactGA.pageview(window.location.pathname + window.location.search);
export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
function analyzeCookie() {
  let galletita = document.cookie;
  let source = '';
  let medium = '';
  let campaign = '';
  let variableEstado = '';
  let date = '';
  let content = '';
  let term = '';
  if (galletita.includes('ReturningSession')) {
    variableEstado = 'ReturningSession';
  } else if (galletita.includes('FirstSession')) {
    variableEstado = 'FirstSession';
  } else {
    variableEstado = 'noencontrada';
  }
  let elementos = galletita.split(';');
  let posicion = 0;
  for (const elem in elementos) {
    if (elementos[elem].includes(variableEstado)) {
      break;
    } else {
      posicion += 1;
    }
  }
  galletita = decodeURIComponent(elementos[posicion]).replace(
    `${variableEstado}=`,
    '',
  );
  elementos = galletita.split('&');
  for (const elem in elementos) {
    if (elementos[elem].includes('source=')) {
      source = elementos[elem].replace('source=', '');
    } else if (elementos[elem].includes('medium=')) {
      medium = elementos[elem].replace('medium=', '');
    } else if (elementos[elem].includes('campaign=')) {
      campaign = elementos[elem].replace('campaign=', '');
    } else if (elementos[elem].includes('term=')) {
      term = elementos[elem].replace('term=', '');
    } else if (elementos[elem].includes('content=')) {
      content = elementos[elem].replace('content=', '');
    } else if (elementos[elem].includes('date=')) {
      date = elementos[elem].replace('date=', '');
    }
  }

  source = source != null && source !== '' ? source : 'direct';
  medium = medium != null && medium !== '' ? medium : 'none';
  campaign = campaign != null && campaign !== '' ? campaign : 'direct';
  sessionStorage.setItem('source', source);
  sessionStorage.setItem('medium', medium);
  sessionStorage.setItem('campaign', campaign);
  sessionStorage.setItem('content', content);
  ReactGA.ga((tracker) => {
    const idGa = tracker.get('clientId');
    sessionStorage.setItem('idGa', idGa);
  });
}

function Home({ location }) {
  // se mandan a traer las categorias para las tarjetas de entrenamientos
  const [categories, setCategories] = useState([]);
  const { data } = useQuery(GET_TRAINING_HOME_CATEGORIES);
  useEffect(() => {
    if (data) {
      const trainings = JSON.parse(data.getTrainingCategories.response);
      setCategories(extractCategories(trainings));
    }
  }, [data]);
  // se extraen las categorias de los entrenamientos
  const extractCategories = (trainingArray) => {
    let counter = 1;
    const catgrys = trainingArray.filter((training) => {
      if (training.idCatCategory === counter) {
        counter++;
        return training;
      }
    });
    return catgrys;
  };

  const [selectedCatego, setSelectedCatego] = useState(0);

  useEffect(() => {
    const variable = parseInt(sessionStorage.getItem('categorieActive'));
    if (variable) {
      setSelectedCatego(variable);
    }
  });

  const { actions } = ClickButton();
  console.log('fijate aqui');
  console.log(process.env.REACT_APP_SERVERTYPE);
  const [MorePrensa, setMorePrensa] = useState(false);
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
    analyzeCookie();
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
  }
  sessionStorage.setItem('showModal', false);
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleClickMorePrensa = (estado) => {
    setMorePrensa(estado);
  };
  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted() {
      localStorage.setItem('activityDate', new Date());
    },
    onError(err) {
      console.log(err);
    },
  });
  const history = useHistory();

  const [scrollY, setScrollY] = useState(window.scrollY);
  const _useTagManager = (socialNetwork) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const tagManagerArgs = {
      dataLayer: {
        event: `clickShare${socialNetwork}`,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    const createSocialMediaShare = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: `clickShare${socialNetwork}`,
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };
    createClickFront({ variables: createSocialMediaShare });
  };
  function shareCoru(socialNetwork) {
    _useTagManager(socialNetwork);
    let coruRedirectURL;
    switch (socialNetwork) {
      case 'Messenger':
        // It seems that custom message is no longer suported by the facebook API
        // if you find a way to implement it, please do it.
        coruRedirectURL = 'https://coru.com/shorter?id=qUHiX6VXq7TZP0n';
        const url = `https://www.facebook.com/dialog/send?app_id=248019326476226&link=${coruRedirectURL}&redirect_uri=${encodeURIComponent(
          'https://www.facebook.com/',
        )}`;
        actions({ action: 'ClickButtonCoruHome', variables: 'Messenger'});
        window.open(url);
        break;
      case 'MessengerMob':
        coruRedirectURL = 'https://coru.com/shorter?id=qUHiX6VXq7TZP0n';
        actions({ action: 'ClickButtonCoruHome', variables: 'MessengerMob'});
        window.open(
          `fb-messenger://share/?link=${coruRedirectURL}&app_id=248019326476226`,
        );
        break;
      case 'Facebook':
        coruRedirectURL = 'https://coru.com/shorter?id=P6Ca2ipVL5Vq87H';
        actions({ action: 'ClickButtonCoruHome', variables: 'Facebook'});
        window.open(
          `https://www.facebook.com/dialog/share?app_id=248019326476226&href=${encodeURIComponent(
            coruRedirectURL,
          )}&redirect_uri=${encodeURIComponent(
            'https://www.facebook.com/',
          )}&display=popup`,
        );
        break;
      case 'Whatsapp':
        coruRedirectURL = 'https://coru.com/shorter?id=yVn6F9Odtw0LnBH';
        actions({ action: 'ClickButtonCoruHome', variables: 'Whatsapp'});
        const whatsappMessage = `¡Obtén el poder de tu dinero! Coru es la forma más inteligente de tomar tus decisiones financieras. Nuestro coach financiero te llevará de la mano para entender tus necesidades. Regístrate y alcanza tus metas financieras. ${coruRedirectURL}`;
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURI(whatsappMessage)}`,
          'popup',
          'width=600',
          'height=600',
        );
        break;
      case 'Copy':
        actions({ action: 'ClickButtonCoruHome', variables: 'Copy'});
        coruRedirectURL = 'http://coru.com/shorter?id=f26eaeGcF3Zzvi7';
        const el = document.createElement('input');
        el.value = coruRedirectURL;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        handleOpen();
        break;
      default:
        break;
    }
  }
  // Modal Test de Viralidad
  const [openTestViralidad, setOpenTestViralidad] = useState(
    sessionStorage.getItem('dayOfWeek') === 'Hola',
  );

  const [getDayOfWeek] = useLazyQuery(GET_DAY_OF_WEEK, {
    onCompleted({ getDayOfWeek }) {
      const { today } = getDayOfWeek;
      sessionStorage.setItem('dayOfWeek', today);
      setOpenTestViralidad(today === 'Hola');
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    getDayOfWeek();
  }, []);

  useEffect(() => {
    ReactGA.ga((tracker) => {
      const idga = tracker.get('clientId');
      console.log(idga);
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const dataJson = {
        idga,
        campaign: sessionStorage.getItem('campaign'),
        medium: sessionStorage.getItem('medium'),
        source: sessionStorage.getItem('source'),
        timestamp: today,
        content: sessionStorage.getItem('content'),
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        createSession({ variables: mongoInput });
      }
    });
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', debounce(handleScroll));
    return () => window.removeEventListener('scroll', debounce(handleScroll));
  }, [campaign, createSession, medio, source]);
  sessionStorage.setItem('clickEmpezarAhoraHome', false);
  const initializeNow = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.setItem('clickEmpezarAhoraHome', true);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickEmpezarAhoraHome',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: 'clickEmpezarAhoraHome',
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };
    createClickFront({ variables: createClickInput });
    actions({ action: 'ClickButtonCollection', variables: name });
    sessionStorage.setItem('focusheader', '');
    actions({ action: 'ClickButtonCoruHome', variables: `Home${name}`, redirection: '/user-validation/'});
    // history.push('/user-validation/');
  };

  const initializeLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.setItem('clickIniciarSesion', true);
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    createClickFront({
      variables: {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickLogin',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      },
    });
    actions({ action: 'ClickButtonCollection', variables: 'Login' });
    history.push('/login');
  };

  // Visual effects
  const scrollToTop = () => {
    document.querySelector('#root').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ModalMessage = () => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <ParagrapTwo
          fontWeight="700"
          cursor="pointer"
          texttalign="right"
          textalignMobile="right"
          className="mx-auto"
          onClick={handleClose}
        ></ParagrapTwo>
        <ParagrapTwo
          className="mx-auto"
          mb="0px"
          textalignMobile="center"
          texttalign="center"
        >
          {' '}
          Se ha copiado el enlace <br /> correctamente
        </ParagrapTwo>
        <Button
          className="d-block mt-5 mt-md-4 mb-5 roboto"
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </div>
    </Modal>
  );

  const handleCategoryClicked = (categoryId) => {
    // setCategoryClicked(categoryId);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Experiências digitais sob medida</title>
        <link rel="canonical" href="https://coru.com/" />
        <meta
          name="description"
          content="Tenha experiências customizadas que geram valor para o seu usuário e para você. Implemente os módulos da plataforma Coru e aprimore continuamente seus resultados com Inteligência Artifical e Machine Learning"
        />
      </Helmet>
      <CookiesNotice />
      <Container1>
        <ContainerMenu>
          <ContainerSubMenu style={{ position: 'fixed', zIndex: 10000000 }}>
            <Header pathname={'/'} />
          </ContainerSubMenu>
        </ContainerMenu>
        <div
          data-bs-spy="scroll"
          data-bs-target="#navhome"
          data-bs-offset="0"
          className="scrollspy-example"
          tabIndex="0"
        >
          <CarrouselP
            className="CarruselContainer"
            initializeNow={initializeNow}
            initializeLogin={initializeLogin}
          />
          <ChatIcono />
          <div className="row justify-content-center">
            <div className="col-12 col-xs-8" style={{ marginTop: '6%' }}>
              <ContainerBottons
                position=" "
                displayD="none"
                displayM="block"
                marginW="auto 1%"
              >
                <Button className="d-block roboto" onClick={(e)=>initializeNow(e,'EntrenaAhora')}>
                  ¡Entrena ahora!
                </Button>
              </ContainerBottons>
            </div>

          </div>
          <div className='KandaHome'>
            <Kanda goToDiagnostico="Diagnostico2" />
          </div>
          <Rombo displayD="none" displayM="block" />
          <Rectangle20 displayD="block" displayM="none" />
          <Shapes
            className="container-fluid"
            top=" "
            left=" "
            heightD="auto"
            height=" "
            width="100%"
            position=" "
          >
            <Container
              width="80%"
              mt="0rem"
              widthMobile="100%"
              mb="0%"
              mtMobile="0rem"
            >
              <ContentHomeSection
                leftD="10%"
                floatD="left"
                width="40%;"
                displayD="none"
                displayM="block"
              >
                <div id="Entrenamientos"></div>
                <Seccion2>
                  <ContainerBottons position=" ">
                    <TitleEntrenamientos className="roboto">
                      Los entrenamientos más usados
                    </TitleEntrenamientos>
                  </ContainerBottons>
                  <ContainerBottons position=" ">
                    <SubTexto className="roboto" mb="7%">
                      Regístrate y elige el entrenamiento que te ayude a
                      resolver tus dudas, tomar la mejor decisión y alcanzar tus
                      metas financieras.
                    </SubTexto>
                  </ContainerBottons>
                </Seccion2>
              </ContentHomeSection>
              <Col6 pt="0px" flex="0 0 100%" maxwidth="100%" margin="auto">
                {categories.map((post) => (
                  <HomeCategories
                    color={post.color}
                    name={post.NameCategory}
                    imagen={post.ImageURL}
                    show={selectedCatego === post.idCatCategory}
                    id={post.idCatCategory}
                    description={post.description}
                    setCategory={handleCategoryClicked}
                    isHome={true}
                  />
                ))}
              </Col6>
              <Col6
                pt="0px"
                flex="0 0 100%"
                maxwidth="100%"
                display="flex"
                displayM="none"
              >
                
                <div style={{ margin: 'auto' }}>
                  <TitleEntrenamientos className="roboto" top=" ">
                    Educación financiera para las y los mexicanos{' '}
                  </TitleEntrenamientos>
                  <SubTexto className="roboto" height=" " heightD=" ">
                    En Coru sabemos que las finanzas personales pueden ser un
                    dolor de cabeza,{' '}
                    <b>
                      por eso diseñamos los entrenamientos para que aprendas a
                      tomar mejores decisiones financieras
                    </b>{' '}
                    con base en lo que tú necesitas:
                  </SubTexto>
                  <ContainerBottons
                    top="0px"
                    position=" "
                    leftD=""
                    bottomM="0"
                    displayM="none"
                    displayD="block"
                  >
                    <Button
                      className="d-block mt-5 mt-md-4 roboto"
                      onClick={(e)=>initializeNow(e,'EntrenaAhora')}
                    >
                      ¡Entrena ahora!
                    </Button>
                  </ContainerBottons>
                </div>
              </Col6>
            </Container>
          </Shapes>
          <Shapes
            top="5%"
            left=" "
            heightD="auto"
            height=" "
            width="100%"
            position=" "
          >
            <ContainerBottons
              top="20px"
              position=" "
              displayD="none"
              displayM=" "
            >
              <Button
                className="d-block mt-5 mt-md-4 roboto"
                onClick={(e)=>initializeNow(e,'EntrenaAhora')}
              >
                ¡Entrena ahora!
              </Button>
            </ContainerBottons>
          </Shapes>
          <Shapes
            id="Aliados"
            className="container-fluid"
            top=" "
            left=" "
            heightD="auto"
            height=" "
            width="100%"
            position=" "
          >
            <div className="d-flex flex-column ">
              <div className="p-2">
                <ContentHomeSection
                  leftD="10% "
                  rigthD="10%"
                  floatD=" "
                  width="80%;"
                  displayD=" "
                  displayM=" "
                >
                  <TitleEntrenamientos top="5%" topD=" " className="roboto">
                    Las mejores empresas de México <br />
                    nos respaldan
                  </TitleEntrenamientos>
                  <SubTexto
                    bottom="0px"
                    className="roboto"
                    heightD="auto"
                    heightmb1="auto"
                  >
                    Realizamos alianzas con las mejores instituciones del país
                    para ofrecerte los mejores productos y beneficios.
                  </SubTexto>
                </ContentHomeSection>
              </div>
              <div className="p-2">
                <ContentHomeSection
                  leftD="10% "
                  rigthD="10%"
                  floatD=" "
                  width="80%;"
                  displayD=" "
                  displayM=" "
                  id="CarruselEmpresasContentHome"
                  style={{ marginBottom: '5%' }}
                >
                  <CarrouselEmpresas />
                  <div id="Comparte"></div>
                </ContentHomeSection>
              </div>
            </div>
          </Shapes>

          <Shapes
            top=" "
            left=" "
            heightD="auto"
            height=" "
            width="100%"
            position="relative"
          >
            <DivTrofeos id="trofeos">
              <Image src={trophies} />
            </DivTrofeos>
          </Shapes>
          <Shapes
            className="container-fluid"
            top=" "
            zindex="20"
            left=" "
            heightD="auto"
            height=" "
            width="100%"
            position=" "
            mtopD="0%"
            mtopM="0px"
          >
            <ContentHomeSection
              leftD="10%"
              floatD="left"
              width="40%;"
              displayD="none"
              displayM="block"
            >
              <TitleEntrenamientos top="89.66" className="roboto" topD="31%">
                Alivio financiero para una mejor calidad de vida
              </TitleEntrenamientos>
              <SubTexto height=" " bottom="28px" className="roboto">
                <b>
                  Nuestro sueño es que las mexicanas y mexicanos tengan finanzas
                  saludables
                </b>
                , que descubran que una vida libre de estrés financiero es
                posible. Ayúdanos a llegar a esas personas compartiendo con tu
                familia y amigos en tu red social favorita con los botones de
                lado izquierdo en la imagen.
              </SubTexto>
            </ContentHomeSection>
            <ContentHomeSection
              leftD="10%"
              width="40%;"
              floatD="left"
              displayD=" "
              displayM=" "
              rigthD=" "
            >
              <SocialMedia>
                <Rectangle20
                  width="32.71px"
                  height="32.71px"
                  marginleft="114px"
                  background="#E3E660"
                  transform="rotate(-30deg)"
                  margintop=""
                />
                <Triangle
                  widthborder="20px solid transparent;"
                  widthborderColor="30px solid #91D770"
                  marginleft="180px"
                />
                <Triangle
                  widthborder="20px solid transparent;"
                  widthborderColor="30px solid #91D770"
                />

                <VectorNum
                  className="faceHome"
                  width="89px"
                  cursor="pointer"
                  height="104px"
                  back="#3B5998"
                  marginleft="34px"
                  margintop="-19%"
                  transform="rotate(-15deg)"
                  onClick={() => shareCoru('Facebook')}
                >
                  <Image
                    src={Shape}
                    width="30%"
                    hei=" "
                    margin="auto"
                    transform="rotate(10deg)"
                  />
                </VectorNum>
                <VectorNum
                  className="whatHome"
                  width="137px"
                  cursor="pointer"
                  height="160px"
                  back="#4AD07C"
                  marginleft="242px"
                  margintop="-19%"
                  transform="rotate(9.02deg)"
                  onClick={() => shareCoru('Whatsapp')}
                >
                  <Image src={What} width="50%" hei=" " margin="auto" />
                </VectorNum>
                <VectorNum
                  className="messangerHome"
                  cursor="pointer"
                  shadow="0px 4px 24px rgba(0, 0, 0, 0.07)"
                  displayM="none"
                  width="71.23px"
                  height="83.3px"
                  back="#fff"
                  marginleft="142px"
                  margintop="-3%"
                  transform="rotate(15deg)"
                  onClick={() => shareCoru('Messenger')}
                >
                  <Image src={Messenger} width="50%" hei=" " margin="auto" />
                </VectorNum>
                <VectorNum
                  className="messangerHome"
                  cursor="pointer"
                  shadow="0px 4px 24px rgba(0, 0, 0, 0.07)"
                  displayM="flex"
                  display="none"
                  width="71.23px"
                  height="83.3px"
                  back="#fff"
                  marginleft="142px"
                  margintop="-3%"
                  transform="rotate(15deg)"
                  onClick={() => shareCoru('MessengerMob')}
                >
                  <Image src={Messenger} width="50%" hei=" " margin="auto" />
                </VectorNum>
                <Rombo
                  width="32.71px"
                  height="32.71px"
                  background="#E3E660"
                  marginleft="-18px"
                  margintop="40%"
                />
                <VectorNum
                  className="compartirHome"
                  cursor="pointer"
                  width="84.58px"
                  height="98.83px"
                  back="#9D8CE4"
                  marginleft="30.46px"
                  margintop="0%"
                  transform="rotate(-27.05deg)"
                  onClick={() => shareCoru('Copy')}
                >
                  <Image
                    src={Compartir}
                    width="50%"
                    hei=" "
                    margin="auto"
                    transform="rotate(27deg)"
                  />
                </VectorNum>
                <Rombo
                  width="32.71px"
                  height="32.71px"
                  background="#91D770"
                  marginleft="353px"
                  margintop="-20%"
                />
                <Rombo
                  width="26.93px"
                  height="26.93px"
                  background="#E3E660"
                  marginleft="283.38px"
                  margintop="30%"
                  transform="rotate(15.91deg);"
                />
                <Triangle
                  widthborder="20px solid transparent;"
                  widthborderColor="30px solid #91D770"
                  marginleft="60px"
                />
              </SocialMedia>
            </ContentHomeSection>
            <ContentHomeSection
              heightD="auto"
              leftD=" "
              floatD="right"
              width="40%;"
              displayD=" "
              displayM="none"
              rigthD="10%"
            >
              <TitleEntrenamientos className="roboto" topD="28%">
                Alivio financiero para una mejor calidad de vida
              </TitleEntrenamientos>
              <SubTexto height=" " bottom="28px" className="roboto">
                <b>
                  Nuestro sueño es que las mexicanas y mexicanos tengan finanzas
                  saludables
                </b>
                , que descubran que una vida libre de estrés financiero es
                posible. Ayúdanos a llegar a esas personas compartiendo con tu
                familia y amigos en tu red social favorita con los botones de
                lado izquierdo en la imagen.
              </SubTexto>
            </ContentHomeSection>
          </Shapes>
          <Shapes
            id="Prensa"
            className="container-fluid"
            top=" "
            left=" "
            heightD="auto"
            height=" "
            width="100%"
            position=" "
            mtopD="0%"
            mtopM="0px"
          >
            <Container
              width="80%"
              shadow=" "
              mt="0rem"
              radius="5px"
              widthMobile="90%"
              mb="0%"
              padding="1%"
              paddingMobile="5%"
            >
              <Div_Flex>
                <TitleEntrenamientos
                  className="roboto"
                  top=" "
                  alingM="center"
                  aling="center"
                  minheight=" "
                >
                  Prensa
                </TitleEntrenamientos>
              </Div_Flex>
            </Container>
            <Container
              width="80%"
              shadow="0px 4px 24px rgba(0,0,0,0.07)"
              mt="0rem"
              radius="5px"
              widthMobile="90%"
              mb="0%"
              padding="1%"
              paddingMobile="5%"
              background="#fff"
            >
              <Container
                width="100%"
                displaymobile="none"
                mt="0rem"
                widthMobile="100%"
              >
                <Div_Flex>
                  <Image
                    src={universal}
                    width=" "
                    hei="auto"
                    mwidth="45px"
                    heightMobile="28.469px"
                    margin="auto"
                  ></Image>
                  <Image
                    src={financiero}
                    width="270px"
                    hei="31px"
                    mwidth="85px"
                    heightMobile="28.469px"
                    margin="auto"
                  ></Image>
                  <Image
                    src={forbes}
                    width=" "
                    hei="40px"
                    mwidth="45px"
                    heightMobile="28.469px"
                    margin="auto"
                  ></Image>
                  <Image
                    src={expansion}
                    width="200px"
                    mwidth="45px"
                    heightMobile="28.469px"
                    margin="auto"
                  ></Image>
                </Div_Flex>
              </Container>
              <Container
                width="100%"
                displaymobile="block"
                displayweb="none"
                mt="0rem"
                widthMobile="100%"
              >
                <Div_Flex>
                  <Image
                    src={forbes}
                    widthmob="139.313px!important"
                    heiM="40px!important"
                    margin="auto"
                  ></Image>
                </Div_Flex>
                <Div_Flex>
                  <Image
                    src={financiero}
                    widthmob="270px"
                    heiM="31px"
                    margin="10% auto 0  auto"
                  ></Image>
                </Div_Flex>
                <Div_Flex>
                  <Image
                    src={expansion}
                    widthmob="200px"
                    heiM="100px"
                    margin="auto"
                  ></Image>
                </Div_Flex>
                {MorePrensa ? (
                  <Div_Flex>
                    <Image
                      src={universal}
                      widthmob="200px"
                      heiM="100px"
                      margin="auto"
                    ></Image>
                  </Div_Flex>
                ) : null}
                <Div_Flex>
                  {MorePrensa ? (
                    <ExpandLessIcon
                      style={{ color: '#F64282', fontSize: 40 }}
                      onClick={() => {
                        handleClickMorePrensa(false);
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      style={{ color: '#F64282', fontSize: 40 }}
                      onClick={() => {
                        handleClickMorePrensa(true);
                      }}
                    />
                  )}
                </Div_Flex>
              </Container>
            </Container>
          </Shapes>
          <Shapes
            top=" "
            left=" "
            heightD="auto"
            height=" "
            width="100%"
            position=" "
          >
            <div className="d-flex flex-column ">
              <div className="p-2">
                <ContentHomeSection
                  leftD="10% "
                  rigthD="10%"
                  floatD=" "
                  width="80%;"
                  displayD=" "
                  displayM=" "
                >
                  <TitleEntrenamientos
                    top="92.5px"
                    className="roboto"
                    displayD="none"
                    displayM="block"
                  >
                    Únete a más de 2.5 millones de usuarios en Coru
                  </TitleEntrenamientos>
                  <TitleEntrenamientos
                    top=""
                    topD="5%"
                    widthD="52%"
                    className="roboto"
                    aling="center"
                    displayD="flex"
                    displayM="none"
                  >
                    Juntos somos el cambio financiero{' '}
                  </TitleEntrenamientos>
                  <SubTexto
                    alingD="center"
                    height="130px"
                    bottom="0px"
                    className="roboto"
                    top="0px"
                  >
                    <b>
                      Más de 2.5 millones de mexicanas y mexicanos forman parte
                      de la comunidad de Coru.
                    </b>{' '}
                    <br />
                    Estas son las historias de cómo superaron sus obstáculos y
                    le dijeron adiós al estrés financiero:
                  </SubTexto>
                </ContentHomeSection>
              </div>
              <div className="p-2">
                <ContentHomeSection
                  leftD="10% "
                  rigthD="10%"
                  floatD=" "
                  width="80%;"
                  displayD=" "
                  displayM=" "
                >
                  <History_Clients></History_Clients>
                </ContentHomeSection>
              </div>
            </div>
          </Shapes>
          <ContainerBottons top="20px" position=" ">
            <Button
              className="d-block mt-5 mt-md-4 roboto"
              onClick={(e)=>initializeNow(e,'RegistrarmeAhora')}
            >
              Registrarme ahora
            </Button>
          </ContainerBottons>
          <ContainerBottons
            top="30px"
            position=" "
            onClick={scrollToTop}
            displayD="none"
          >
            <Image
              src={CaretUp}
              width="20px"
              margin="auto"
              widthmob="20px"
              onClick={scrollToTop}
            />
          </ContainerBottons>
          <ContainerBottons top="18.59px" position=" " displayD="none">
            <Top_Text
              onClick={scrollToTop}
              style={{ cursor: 'pointer' }}
              className="roboto"
            >
              Volver arriba
            </Top_Text>
          </ContainerBottons>
        </div>
      </Container1>
      <ModalTestViralidad
        open={openTestViralidad}
        setOpen={setOpenTestViralidad}
      />
      <Footer pathname={window.location.pathname} />
      <ModalMessage />
    </Fragment>
  );
}

export default Home;
