/* eslint-disable no-shadow */
/* eslint-disable import/named */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_MAGIC_LINK } from '../queries/index';
import { CREATE_MAGIC_LINK } from '../mutations';
import {
  ContentGeneric,
  Image,
  SubtitleOne,
  Button,
  ButtonAlt,
} from "./common/common.styled.js";
import { TextMaxLine } from '../anaCoach/Routines/KnowScore.jsx';
import ImageLogo from '../assets/img/anacoach/CoruLogoB.png';
import warning from '../assets/img/anacoach/warning.svg';
import ModalLogin, { ModalMagicLink } from "../anaCoach/pantallasInicio/ModalLogin";
import Modallogin from '../anaCoach/pantallasInicio/Login/partials/Modallogin';

const MagicLink = () => {
  const [message, setMessage] = useState(false);
  const [recoveryId, setRecoveryId] = useState(false);
  const params = window.location.search;
  const id = params.split('&')[0].replace('?id=', '');
  
  const [getMagicLink] = useLazyQuery(GET_MAGIC_LINK, {
    onCompleted({ getMagicLink }) {
      const { error, message, response } = getMagicLink;
      if (message === "Success") {
        window.location.href = response;
      } else if (message === "Error") {
        const { recoveryId } = JSON.parse(error);
        recoveryId.length > 0 && setRecoveryId(recoveryId);
        setMessage(true);
      }
    }
  });

  const [openSuccessMagicL, setopenSuccessMagicL] = useState(false);
  const [openErrorMagicL, setopenErrorMagicL] = useState(false);
  const [open, setOpen] = useState(false);
  const [typeText, settypeText] = useState("");
  const [childrend, setChildrend] = useState("");
  const [textBtnPrincipal, settextBtnPrincipal] = useState("");
  const [textBtnSeccon, settextBtnSeccon] = useState("");
  const [openModal, setopenModal] = useState(false);

  const [createMagicLink] = useMutation(CREATE_MAGIC_LINK, {
    onCompleted({ createMagicLink }) {
      const { response, message, error } = createMagicLink;
      if (message === "success") {
        // En caso de success
        setopenModal(true);
        openSuccessMagicL(true);
      } else { 
        // En caso de error
        openErrorMagicL(true);
        setChildrend(
          "Los datos ingresados no corresponden a un usuario registrado. Por favor verifique los datos."
        );
        settextBtnPrincipal("Entendido");
        settextBtnSeccon("");
        setOpen(true);
      }
    }
  });

  useEffect(() => {
    getMagicLink({
      variables: {
        id
      }
    });
  }, []);

  const reSendMagicLink = () => {
    createMagicLink({variables: { type: "", origin: 'login', text: "", recoveryId }})
  };

  const FcloseModal = () => {
    setopenModal(!openModal);
  };

  return (
    <>
      {message ? (
        <ContentGeneric textAlign="center" margin="50px auto 0">
          <Image
            cursor="pointer"
            src={ImageLogo}
            width="111px"
            alt="logo-coru"
          />
          <ContentGeneric textAlign="center" width="80%" margin="90px auto" >
            <Image
              cursor="pointer"
              src={warning}
              width="49px"
              height="48px"
              alt="logo-coru"
              bottom="34px"
            />
            <TextMaxLine
              fontSize="20px"
              fontColor="#252525"
              weight="400px"
              width="80%"
            >
              Tu enlace ha caducado, por favor solicita otro para ingresar a tu cuenta
            </TextMaxLine>
            <Button className="d-block mt-5 mt-md-4 mb-5 roboto" onClick={reSendMagicLink}>
              Solicitar nuevo enlace
            </Button>
            <ButtonAlt
              className="d-block mt-5 mt-md-4 mb-5 roboto"
            >
              En otro momento
            </ButtonAlt>
          </ContentGeneric>
          {openSuccessMagicL 
            ? <ModalMagicLink typeInfo="correo electrónico" dato="" closeModal={FcloseModal} />
            : null
          }
          {openErrorMagicL ?
            <Modallogin
              open={open}
              setopen={setOpen}
              childrent={childrend}
              textBtnPrincipal={textBtnPrincipal}
              textBtnSeccon={textBtnSeccon}
            />
          : null}
        </ContentGeneric>
      ) : null}
    </>
  );
};

export default MagicLink;
