/* eslint-disable no-tabs */
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import '../../assets/styles/TextCustom.css';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
  VALIDATE_OTP,
  CREATE_CLICK,
  DYNAMIC_GET,
  SEND_OTP,
} from '../../mutations';
import { FormValidateOtp } from '../../components/common/FormValidateOtp';
import { GET_CONFIG_OTP } from '../../queries';
// Modal

sessionStorage.removeItem('codval1');
sessionStorage.removeItem('codval2');
sessionStorage.removeItem('codval3');
sessionStorage.removeItem('codval4');
sessionStorage.setItem('otpcode', '');

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '300px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    borderRadius: '5px',
  },
}));

const initialState = {
  codval1: '',
  codval2: '',
  codval3: '',
  codval4: '',
};

const ValidateOtp = React.memo(() => {
  const [mostrarLoad, setMostrarLoad] = useState(false);
  const [form, setForm] = useState(initialState);
  const [clickLink, setclickLink] = useState(false);
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleChangeForm = useCallback(() => {
    setForm(initialState);
  }, [setForm]);
  const history = useHistory();
  const [error, setError] = useState({
    codigo: '',
  });
  const handleChangeError = useCallback(() => {
    setError({ codigo: 'Código incorrecto' });
  }, [setError]);

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      console.log(createClick);
    },
    onError(err) {
      console.log(err);
    },
  });
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: window.location.pathname,
        pageName: window.location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  });

  const Celular = useState(
    sessionStorage.getItem('cellPhone').substring(6, 10),
  );
  const [validateOtp] = useMutation(VALIDATE_OTP, {
    onCompleted({ validateOtp }) {
      console.log(validateOtp.message);
      sessionStorage.removeItem('codval1');
      sessionStorage.removeItem('codval2');
      sessionStorage.removeItem('codval3');
      sessionStorage.removeItem('codval4');
      const responseValidate = validateOtp.message;
      if (responseValidate === 'Validacion Exitosa') {
        // si otp es valido descomentar la siguiente linea
        const tagManagerArgs = {
          dataLayer: {
            event: 'otpCorrecta',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const createClickInput = {
          input: {
            id: sessionStorage.getItem('id_session'),
            column: 'clickOTPSuccess',
            collection: 'sessions',
            timeStamp: timestamp,
          },
        };
        if (sessionStorage.getItem('id_session')) { createClickFront({ variables: createClickInput }); }

        history.push('/personal-Information');
        setMostrarLoad(false);
      } else {
        // si otp es no valido descomentar la siguiente linea
        handleChangeForm();
        const tagManagerArgs = {
          dataLayer: {
            event: 'otpIcorrecta',
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
        const timestamp = today;
        const createClickInput = {
          input: {
            id: sessionStorage.getItem('id_session'),
            column: 'clickOTPInvalid',
            collection: 'sessions',
            timeStamp: timestamp,
          },
        };
        if (sessionStorage.getItem('id_session')) { createClickFront({ variables: createClickInput }); }
        handleChangeError();
        setMostrarLoad(false);
      }
    },
  });
  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setForm((form) => ({ ...form, [name]: value }));

      switch (name) {
        case 'codval1':
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='codval2']").focus();
            sessionStorage.setItem('codval1', value);
          } else {
            return false;
          }
          break;
        case 'codval2':
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='codval3']").focus();
            sessionStorage.setItem('codval2', value);
          } else {
            return false;
          }
          break;
        case 'codval3':
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='codval4']").focus();
            sessionStorage.setItem('codval3', value);
          } else {
            return false;
          }
          break;
        case 'codval4':
          if (String(value).length === 1 && value != null) {
            document.querySelector("input[name='codval1']").focus();
            sessionStorage.setItem('codval4', value);
          } else {
            return false;
          }
          break;
        default:
          break;
      }

      const otp1 = sessionStorage.getItem('codval1');
      const otp2 = sessionStorage.getItem('codval2');
      const otp3 = sessionStorage.getItem('codval3');
      const otp4 = sessionStorage.getItem('codval4');
      const otpcode = otp1 + otp2 + otp3 + otp4;
      console.log(otpcode);
      console.log(form);
      if (otp1 != null && otp2 != null && otp3 != null && otp4 != null) {
        setMostrarLoad(true);
        if (
          otp1.length === 1
          && otp2.length === 1
          && otp3.length === 1
          && otp4.length === 1
        ) {
          const typeUser = sessionStorage.getItem('typeUser');
          sessionStorage.setItem('otpcode', otpcode);
          if (typeUser === 'cliente') {
            const medioOtp = sessionStorage.getItem('medioOtp');
            const cel = sessionStorage.getItem('cellPhone');
            const inputValidateOtp = {
              input: {
                telefono: cel,
                medio: medioOtp,
                token: otpcode, // WA
              },
            };

            validateOtp({ variables: inputValidateOtp });
          } else {
            sessionStorage.removeItem('codval1');
            sessionStorage.removeItem('codval2');
            sessionStorage.removeItem('codval3');
            sessionStorage.removeItem('codval4');
            history.push('/personal-Information');
          }
        } else {
          // SetvalueError(true);
          // error.codigo = "LLenar todos los recuadros"
        }
      }
    },
    [form, validateOtp, history],
  );
  const { data: dataOtp } = useQuery(GET_CONFIG_OTP);
  const [sendOtp] = useMutation(SEND_OTP, {
    onCompleted({ sendOtp }) {
      console.log(sendOtp);
      sessionStorage.setItem(
        'lada',
        String(sessionStorage.getItem('cellPhone')).substring(0, 3),
      );
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const timestamp = today;
      const createClickInput = {
        input: {
          id: sessionStorage.getItem('id_session'),
          column: 'clickReenviarCodigoStep3',
          collection: 'sessions',
          timeStamp: timestamp,
        },
      };
      createClickFront({ variables: createClickInput });
      setOpen(true);
    },
  });
  const [validExitPhone] = useMutation(DYNAMIC_GET, {
    onCompleted({ dynamicGet }) {
      console.log(dynamicGet);
      const { response } = dynamicGet;
      if (JSON.parse(response).length > 0) {
        // alert("El número ya se encuentra registrado, ve a iniciar sesión")
        // handleOpen();
      } else {
        const caracteres = '0123456789';
        let cadenaResponse = '';
        const charactersLength = caracteres.length;
        for (let i = 0; i < 4; i++) {
          cadenaResponse += caracteres.charAt(
            Math.floor(Math.random() * charactersLength),
          );
        }
        const inputOtp = {
          input: {
            telefono: sessionStorage.getItem('phoneOTP'),
            medio: sessionStorage.getItem('medioOtp'),
            tipo: 'validacion',
            lastOtp: cadenaResponse,
          },
        };
        if (dataOtp) {
          // config otp admin
          const arrayConfig = dataOtp.getConfigOTP.response;
          for (let i = 0; i < arrayConfig.length; i++) {
            if (arrayConfig[i].step === 'user validation') {
              if (arrayConfig[i].sms_on_off === 'true') {
                inputOtp.input.medio = 'SMS';
                sendOtp({ variables: inputOtp });
              }
              if (arrayConfig[i].whatsapp_on_off === 'true') {
                inputOtp.input.medio = 'WA';
                sendOtp({ variables: inputOtp });
              }
            }
          }
        }
      }
      setMostrarLoad(false);
    },
  });

  const ClickReenvioOTP = () => {
    const cel = sessionStorage.getItem('cellPhone');
    // const validateUser = cel * 1;
    const medioOtp = 'WA'; // "SMS";
    sessionStorage.setItem('medioOtp', medioOtp);
    const caracteres = '0123456789';
    let cadenaResponse = '';
    const charactersLength = caracteres.length;
    for (let i = 0; i < 4; i++) {
      cadenaResponse += caracteres.charAt(
        Math.floor(Math.random() * charactersLength),
      );
    }
    sessionStorage.setItem('typeUser', 'cliente');
    localStorage.setItem('typeUser', 'cliente');
    sessionStorage.setItem('phoneOTP', cel);
    const phoneInput = {
      input: {
        table: 'CatContactMedium',
        columns: ['CellPhone'],
        conditions: [
          {
            valone: 'CellPhone',
            condition: '=',
            valtwo: String(cel),
          },
        ],
      },
    };
    validExitPhone({ variables: phoneInput });
    setMostrarLoad(true);
  };
  sessionStorage.setItem('clickReenviarcodigostep3', false);
  const forwardCode = () => {
    setclickLink(true);
    sessionStorage.setItem('clickReenviarcodigostep3', true);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickReenviarcodigostep3',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    ClickReenvioOTP();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyDown = (e) => {
    console.log(e.target.id);
    if (e.keyCode === 8) {
      switch (e.target.id) {
        case 'codval1':
          document.querySelector("input[name='codval1']").focus();
          break;
        case 'codval2':
          document.querySelector("input[name='codval1']").focus();

          break;
        case 'codval3':
          document.querySelector("input[name='codval2']").focus();
          break;
        case 'codval4':
          document.querySelector("input[name='codval3']").focus();
          break;
        default:
          break;
      }
    }
  };
  return (
    <FormValidateOtp
      stateForm={form}
      handleChange={handleChange}
      error={error}
      forwardCode={forwardCode}
      celular={Celular}
      handleClose={handleClose}
      open={open}
      classes={classes}
      modalStyle={modalStyle}
      handleKeyDown={handleKeyDown}
      clickLink={clickLink}
      mostrarLoad={mostrarLoad}
    ></FormValidateOtp>
  );
});

export default ValidateOtp;
