/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable import/no-useless-path-segments */
import React, { useState, useContext, useEffect } from "react";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { FormattedMessage } from 'react-intl';
import styled from "styled-components";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/styles/Home.css";
import './lpBrasil_style.css';
import language from "../../src/assets/img/Language.svg";
import SplitDown from "../../src/assets/img/SplitDown.svg";
// import WOlayout from '../components/layouts/WOlayout';
import "../assets/styles/kanda/KandaDesktop.css";
// import TribuCoru from "../assets/img/TribuCoru.svg";
import ImgBanner from "../assets/img/LPBrasil/GifPrincipal.gif";
import Business from '../assets/img/LPBrasil/stock-photo-business.svg';
import Businessman from '../assets/img/LPBrasil/stock-photo-businessman-plan.svg';
import Optimizacion from '../assets/img/LPBrasil/stock-photo-Optimizacion.svg';
import AsianWoman from '../assets/img/LPBrasil/stock-photo-young-asian-woman.svg';
import SmartFinancial from '../assets/img/LPBrasil/stock-photo-smart-financial.svg';
import { Image } from "../assets/styles/Home.styled";
import ImageLogo from "../assets/img/LPBrasil/CoruLogoW.svg";
import ImageLogoBlack from "../assets/img/LPBrasil/CoruLogoBlack.svg";
import backgroundI from "../assets/img/LPBrasil/background.svg";
import backgroundIMob from "../assets/img/LPBrasil/HERO1.svg";
// import { imageComponent } from "../components/common/common";
import { CardsBtn } from "../assets/styles/Training.styled";
// import Colaboradores from "../components/rediseno_about_us/Colaboradores";
import Footer from "../components/LpHomeCoru/Components/Footer";
import KandaButton from "../components/LpHomeCoru/utils/KandaButton";
import { ContentGeneric, ImgGeneric } from "../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";
import RegisterWithBusiness from "./pantallasInicio/ registerWithBusiness";
import ModalSuccessRegister from "../components/common/ModalSuccessRegister";
import ClickButton from "../Helpers/HookHelpers";
import RegisterWithBusinessOfBrasil from "./pantallasInicio/registerWithBusinessOfBrasil";
import ManchasBrasil from "../assets/img/LPBrasil/ManchasRegistro.svg";
import Carrousel1 from "../components/LpBrasil/Carrusel1";
import Carrousel2 from "../components/LpBrasil/Carrusel2";
import { Text } from "../assets/styles/kanda/KandaContent.style";
import { TextMaxLine } from "./Routines/KnowScore.jsx";
import { CarruselSolution } from "../components/LpBrasil/CarruselSolution";
import ModalContainerRegisterComponent from "../components/common/ModalContainerRegister";
import CollapsableNav from "../components/common/collapsableNav/CollapsableNav";
import MessPort from '../lang/pt.json';
import MessUS from '../lang/en-US.json';
import { langContext } from '../context/LangContex';

const Container1 = styled.div`
  width: 100%;
  left: 0px;
  top: 0px;
  background: linear-gradient(180deg, #FFFFFF 2.78%, #E7E7FE 26.58%, #E9ECFF 53.41%, #F2E6F7 68.04%, #FEDDF3 100%);
  min-height: 100vh;
  align-items: center;
  @media (min-width: 770px) {
    display: grid;
  }
`;

export const TitleBlue = styled.div`
  width: ${(props) => props.width || ""};
  font-weight: 700;
  font-size: ${(props) => props.fontS || "38px"};
  line-height: 120%;
  align-items: center;
  text-align: ${(props) => props.textA || "center"};
  letter-spacing: -0.3px;
  color: ${(props) => props.color || "#393a80"};
  margin: ${(props) => props.marginM || ""};
  @media (min-width: 770px) {
    text-align: ${(props) => props.textAM || "center"};
    font-size: ${(props) => props.fontSD || "52px"};
    margin: ${(props) => props.marginD || ""};
    display: ${(props) => props.displayMD || ""};
  }
  @media (max-width: 770px) {
    display: ${(props) => props.display || ""};
  }
`;

export const TextGray = styled.div`
  width: ${(props) => props.width || ""};
  font-weight: ${(props) => props.fontW || "400"};
  font-size: ${(props) => props.fontS || "16px"};
  padding: ${(props) => props.padding || ""};
  line-height: 120%;
  align-items: center;
  letter-spacing: -0.3px;
  color: ${(props) => props.color || "#595959"};
  text-align: ${(props) => props.textA || ""};
  margin-top: ${(props) => props.marginT || ""};
  margin: ${(props) => props.margin || ""};
  cursor: ${(props) => props.cursor || ""};
  @media (min-width: 770px) {
    display: ${(props) => props.displayMQ || "block"};
    font-size: ${(props) => props.fontSD || "24px"};
    text-align: ${(props) => props.textAD || ""};
  }
`;

const ContainerFlex = styled.div`
  display: ${(props) => props.displayD || "flex"};
  gap: ${(props) => props.gap || "24px"};
  justify-content: ${(props) => props.justifyC || ""};
  margin: ${(props) => props.margin || ""};
  margin-top: ${(props) => props.marginT || ""};
  text-align: ${(props) => props.textA || ""};
  min-height: ${(props) => props.minH || ""};
  @media (max-width: 769px) {
    display: ${(props) => props.displayM || ""};
    direction: ${(props) => props.directionM || ""};
    margin: ${(props) => props.marginM || ""};
  }
`;

export const ContainerW = styled.div`
  width: 100%;
  @media (min-width: 769px) {
    width: 50%;
    margin: ${(props) => props.margin || ""};
  }
`;

const ImageInfo = styled.img`
  max-height: 100%;
  width: ${(props) => props.width || ""};
  height: ${(props) => props.heightM || ""};
  object-fit: ${(props) => props.objectFit || ""};
  @media (min-width: 770px) {
    max-height: 392px;
    height: ${(props) => props.height || ""};
    margin: ${(props) => props.margin || ""};
    margin-top: ${(props) => props.mtop || ""};
  }
  @media (max-width: 770px) {
    display: ${(props) => props.displayM || ""};
  }
`;

const ContLogo = styled.div`
  width: 20%;
  margin-left: 0;
  margin: auto;
  @media (max-width: 769px) {
    margin-left: 0;
    width: 50%;
    margin-top: 12px;
  }
`;
const ContLogoTres = styled.div`
  /* width: 20%; */
  /* margin-left: 0; */
  /* margin: auto; */
  @media (max-width: 769px) {
    margin-left: 0;
    width: 50%;
    margin-top: 12px;
  }
`;

const ContLogo2 = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 769px) {
    width: 50%;
  }
`;

const ContainerFD = styled.div`
  /* display: block;
  @media (min-width: 770px) {
    display: flex;
    margin-top: 30px;
  } */
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`;

const ContainerOfForm = styled.div`
  max-width: 606px;
  width: 100%;
  /* max-height: 646px; */
  height: 100%;
  
  background-color: ${(p) => p.bgColor || 'rgba(255, 255, 255, 0.5)'};
  border-radius: 16px;
  display: flex;
  padding: 24px 24px;
  @media (max-width: 770px) {
    display: ${(props) => props.displayM || "flex"};
  }
`;

const TitleOfForm = styled.div`
  font-size: 32px;
  color: #AC2E5B;
  font-weight: 700;
  font-family: Cabin;
`;

const SubTitleForm = styled.div`
color: #AC2E5B;
  font-size: 16px;
  font-weight: 400;
  padding-top: 12px;
  /* font-family: Roboto; */
  line-height: 20.8px;
  padding-bottom: 24px;
`;

const Cases = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  max-width: 488px;
  height: ${(props) => props.height || "90px"};
  background: ${(props) => props.bg || "#FFFFFF"};
  box-shadow: ${(props) => props.bs || "0px 4px 20px #C9CEEE"};
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  backdrop-filter: ${(props) => props.bf || ""};
  color: ${(props) => props.color || ""}
`;

const Case1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  max-width: 488px;
  height: ${(props) => props.height || "90px"};
  background: #FFFFFF;
  box-shadow: 0px 4px 20px #C9CEEE;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  backdrop-filter: ${(props) => props.bf || ""};
  &:hover {
    background: #E0E9FE;
    box-shadow: 0px 4px 20px #C9CEEE;
  }
`;

const Case2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  max-width: 488px;
  height: ${(props) => props.height || "90px"};
  background: #FFFFFF;
  box-shadow: 0px 4px 20px #C9CEEE;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  backdrop-filter: ${(props) => props.bf || ""};
  &:hover {
    background: #FFE9D6;
    box-shadow: 0px 4px 20px #FFC898;
  }
`;

const Case3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  max-width: 488px;
  height: ${(props) => props.height || "90px"};
  background: #FFFFFF;
  box-shadow: 0px 4px 20px #C9CEEE;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  backdrop-filter: ${(props) => props.bf || ""};
  &:hover {
    background: #E0FEF7;
    box-shadow: 0px 4px 20px #8FECD6;
  }
`;

const Case4 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  max-width: 488px;
  height: ${(props) => props.height || "90px"};
  background: #FFFFFF;
  box-shadow: 0px 4px 20px #C9CEEE;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  backdrop-filter: ${(props) => props.bf || ""};
  &:hover {
    background: #E8E0FE;
    box-shadow: 0px 4px 20px #BFA8FF;
  }
`;

const Desafios = styled.div`
  width: 20%;
  background: ${(props) => props.bg || "#FFFFFF"};
  padding: 16px 24px;
  color: ${(props) => props.color || "#A6A6A6"};
  font-size: ${(props) => props.fs || "16px"};
  font-weight: ${(props) => props.fw || ""};
  box-shadow: 0px 8px 16px rgba(197, 214, 243, 0.6);
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 770px) {
    width: 100%;
    margin: 12px 0;
  }
`;

const ContainerBanner = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 770px) {
    display: block;
  }
`;

const ContainAnim = styled.div`
  background-image: url(${backgroundI});
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -5px;
  @media (max-width: 770px) {
    background-image: url(${backgroundIMob});
    margin-top: -50px;
  }
`;

const InfoDesafio = styled.div`
padding: 24px;
max-width: 25%;
min-height: auto;
background: rgba(255, 255, 255, 0.6);
backdrop-filter: blur(12px);
border-radius: 16px;
margin: 24px;
height: fit-content;
float: right;
margin-top: -360px;
@media (max-width: 770px) {
  width: 100%;
  position: relative;
  max-width: 100%;
  margin: 0;
  margin-top: 25px;
}
`;

const FotoDesafio = styled.div`
  min-width: 100%;
  margin: 0;
  @media (max-width: 770px) {
    min-width: 10%;
    height: 200px;
  }
`;

const ImgMancha = styled.img`
  height: 100%;
  width: 100%;
  max-width: 606px;
  max-height: 616px;
`;
const WrapperOFElementsForm = styled.div`
  /* border: 1px solid  red; */
`;

const ContainerBcg = styled.div`
  max-width: 85%;
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin: 140px auto 60px;
  @media (max-width: 770px) {
    margin: 0 auto 60px;
  }
`;

function LPBrasil() {
  const idioma = useContext(langContext);
  console.log(idioma.lenguajeApp);

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const { actions } = ClickButton();
  const [changeAdquisicion, setchangeAdquisicion] = useState(false);
  const [changeOnboarding, setchangeOnboarding] = useState(false);
  const [changeCrosselling, setchangeCrosselling] = useState(false);
  const [changeExperiencia, setchangeExperiencia] = useState(false);
  const [modalregister, setModalRegister] = useState(false);
  const [openModalSuccess, setopenModalSuccess] = useState(false);
  const [openModalSuccessRegister, setopenModalSuccessRegister] = useState(false);
  const [otherContainer, setotherContainer] = useState(true);
  const [changeConversacion, setchangeConversacion] = useState(true);
  const [changeOptimizacion, setchangeOptimizacion] = useState(false);
  const [changePerfil, setchangePerfil] = useState(false);
  const [changeEstrategia, setchangeEstrategia] = useState(false);
  const [changeSoluciones, setchangeSoluciones] = useState(false);
  console.log('sessionStorage', sessionStorage);
  const urlh = window.location.href;
  console.log('AAAAAAAAAAAAAAAAA', urlh);

  const [form, setForm] = useState({
    fullname: "",
    email: "",
    numPhone: "",
    lada: "México lada +(52)",
    textlada: "México lada +(52)",
    Empresa: "",
    Posicion: "",
    Sitio: "",
  });
  const [validCampos, setvalidCampos] = useState({
    personales: false,
    contacto: false,
    password: false,
  });

  useEffect(() => {
    window.location.href = 'https://coru.com/caas/pt/';
  }, []);

  const handleClickCoru = (name) => {
    actions({
      action: 'ClickButtonLPBrasil',
      variables: 'ClickDemo_LpCoruBrasil',
      collection: 'LogClickButtonLpCoruBrasil',
    });
    switch (name) {
      case 'Ingresar':
        sessionStorage.setItem('Training', 'Business');
        window.location.href = '/login-v2';
        break;
      case 'CrearCuenta':
        gsap.to(window, { duration: 1, scrollTo: '#CrearCuenta' });
        // window.location.href = '/personal-Information-v2';
        break;
      default:
        break;
    }
  };

  const Header = () => (
    <div style={{
      padding: "24px", height: "120px", display: "flex", background: "#1B1C46"
    }}>
      <ContLogo>
        <Image
          cursor="pointer"
          src={ImageLogo}
          width="230px"
          mwidth="97.92px"
          heightMobile="20.8px"
          alt="Experiências digitais sob medida"
        />
      </ContLogo>
      <ContLogo2>
      <ContentGenericNew>
      <ContLogoTres>
         <img src={language} alt=""/>
      </ContLogoTres>
      <div style={{ color: "#fff" }}>
        <FormattedMessage
          id="header.idioma"
          defaultMessage="PORT-BRA"
        />
      </div>
      <CollapsableNav />
      {/* <ButtonSplit>
         <img src={SplitDown} alt=""/>
      </ButtonSplit> */}
      </ContentGenericNew>
      <ContentGeneric width="100%" widthMd="20%" margin="auto 0" >
          <KandaButton
            action={() => handleClickCoru('CrearCuenta')}
            mr="96px"
            p="10px 5px"
            height="40px"
            width="100%"
            fontS="12px"
            typeBtn="active">
            Agendar uma Demo
          </KandaButton>
        </ContentGeneric>
      </ContLogo2>
    </div>
  );

  const handleClickInteraccion = (name) => {
    console.log(name);
    switch (name) {
      case 'Adquisicion':
        if (!changeAdquisicion) {
          setchangeAdquisicion(true);
          setchangeOnboarding(false);
          setchangeCrosselling(false);
          setchangeExperiencia(false);
        }
        break;
      case 'Onboarding':
        if (!changeOnboarding) {
          setchangeAdquisicion(false);
          setchangeOnboarding(true);
          setchangeCrosselling(false);
          setchangeExperiencia(false);
        }
        break;
      case 'Crosselling':
        if (!changeCrosselling) {
          setchangeAdquisicion(false);
          setchangeOnboarding(false);
          setchangeCrosselling(true);
          setchangeExperiencia(false);
        }
        break;
      case 'Experiencia':
        if (!changeExperiencia) {
          setchangeAdquisicion(false);
          setchangeOnboarding(false);
          setchangeCrosselling(false);
          setchangeExperiencia(true);
        }
        break;
      case 'Conversacion':
        if (!changeConversacion) {
          setchangeConversacion(true);
          setchangeOptimizacion(false);
          setchangePerfil(false);
          setchangeEstrategia(false);
          setchangeSoluciones(false);
        }
        break;
      case 'Optimizacion':
        if (!changeOptimizacion) {
          setchangeConversacion(false);
          setchangeOptimizacion(true);
          setchangePerfil(false);
          setchangeEstrategia(false);
          setchangeSoluciones(false);
        }
        break;
      case 'Perfil':
        if (!changePerfil) {
          setchangeConversacion(false);
          setchangeOptimizacion(false);
          setchangePerfil(true);
          setchangeEstrategia(false);
          setchangeSoluciones(false);
        }
        break;
      case 'Estrategia':
        if (!changeEstrategia) {
          setchangeConversacion(false);
          setchangeOptimizacion(false);
          setchangePerfil(false);
          setchangeEstrategia(true);
          setchangeSoluciones(false);
        }
        break;
      case 'Soluciones':
        if (!changeSoluciones) {
          setchangeConversacion(false);
          setchangeOptimizacion(false);
          setchangePerfil(false);
          setchangeEstrategia(false);
          setchangeSoluciones(true);
        }
        break;
      default:
        break;
    }
  };

  const handleClickModal = (name) => {
    actions({
      action: 'ClickButtonLPBrasil',
      variables: `${name}`,
      collection: 'LogClickButtonLpCoruBrasil',
    });
    setModalRegister(!modalregister);
  };
  const var2 = {
    '--cui-accordion-btn-active-icon': 'none',
    '--cui-accordion-btn-icon': 'none',
  };
  const vars = {
    '--cui-accordion-border-color': 'transparent',
    '--cui-accordion-body-padding-x': '0px',
    '--cui-accordion-body-padding-y': '0px',
    '--cui-accordion-btn-active-icon': 'none',
  };

  return (
      <Container1 id="padr">
        <Header />
        <ContainAnim>
          <ContainerBcg>
            <ContainerBanner>
              <TitleBlue displayMD="none" className="cabin" color="#FFFFFF" style={{ margin: "22% 0" }}>
                <FormattedMessage
                  id="app.aliadoMobile"
                  defaultMessage="O seu aliado para"
                />
              </TitleBlue>
              <TitleBlue
                width="18%"
                display="none"
                className="cabin"
                color="#FFFFFF"
                fontSD="5.5em"
                style={{ margin: "-6% 0" }}
              >
                <FormattedMessage
                  id="app.aliado"
                  defaultMessage="O seu aliado
                  para"
                />
              </TitleBlue>
              <ImageInfo height="fit-content" width="180px" mtop="2%" displayM="none" src={ImgBanner} />
              <TitleBlue width="23%" display="none" className="cabin" color="#FFFFFF" fontSD="5.5em" style={{ margin: "-5% 0" }}>
                <FormattedMessage
                    id="app.escalar"
                    defaultMessage="escalar canais digitais sadfsdfsdfsdfsdfdsf"
                  />
              </TitleBlue>
            </ContainerBanner>
            <TextGray
              width = "100%"
              padding = "0 13%"
              className="roboto"
              textA="center"
              fontSD="24px"
              style={{ margin: "200px 0 30px 0" }}
              fontW="700"
            >
              <FormattedMessage
                  id="app.infotitle"
                  defaultMessage="Com"
              />
              {" "}
              <span style={{ color: '#A5C3FF' }}>
                <FormattedMessage
                    id="app.infotitleSpan"
                    defaultMessage="Inteligência Artificial e Machine Learning"
                />
              </span>,{" "}
              <FormattedMessage
                    id="app.infotitle2"
                    defaultMessage="usamos a plataforma Coru para potencializar seus resultados de forma ágil."
              />
            </TextGray>
            <CardsBtn
              styledMaxWidth="300px"
              type="button"
              minmd="155px"
              min_w="300px"
              min_web="300px"
              max_w="300px"
              windthmd="60%"
              style={{ borderRadius: "16px" }}
              onClick={() => handleClickModal('ClickEspecialista_LpCoruBrasil')}
            >
               <FormattedMessage
                    id="app.ButtonEspecialista"
                    defaultMessage="Fale com um especialista"
                />
            </CardsBtn>
          </ContainerBcg>
        </ContainAnim>
        <div
          style={{
            maxWidth: "1056px",
            display: "flex",
            flexDirection: "column",
            justifySelf: "center",
            margin: "24px",
          }}
        >
          <TitleBlue marginM="40px" className="cabin" fontS="32px" fontSD="32px" textAM="center" marginD="80px 0 24px 0">
            <FormattedMessage
              id="app.YouDesafio"
              defaultMessage="Qual é o seu desafio?"
            />
          </TitleBlue>
          <ContentGeneric id="containerCollapsible" displayxS="block" dis="none">
            <CAccordion flush>
              <CAccordionItem itemKey={1} id='item1' style={vars}>
                <CAccordionHeader style={var2}>
                  <TextMaxLine
                    fontSize="16px"
                    className="roboto"
                    fontColor="#393A80"
                    >
                       <FormattedMessage
                        id="accordion.titulo1"
                        defaultMessage="AQUISIÇÃO"
                      />
                    </TextMaxLine>
                  </CAccordionHeader>
                <CAccordionBody>
                  <ContentGeneric background="#E0E9FE" padding="0px 16px 16px">
                    <TextMaxLine
                      fontSize="14px"
                      className="roboto"
                      fontColor="#070B11"
                    >
                       <FormattedMessage
                        id="accordion.quadro1"
                        defaultMessage="Baixa conversão ou alto custo de aquisição via canais digitais"
                      />
                    </TextMaxLine>
                  </ContentGeneric>
                  <ImgGeneric width="100%" height="auto" margin="0px" borderBottomL="18px" borderBottomR="18px" src={Businessman} />
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={2} id='item2' style={vars}>
                <CAccordionHeader style={var2}>
                  <TextMaxLine
                      fontSize="16px"
                      className="roboto"
                      fontColor="#80460C"
                  >
                     <FormattedMessage
                      id="accordion.titulo2"
                      defaultMessage="ATIVAÇÃO"
                     />
                  </TextMaxLine>
                </CAccordionHeader>
                <CAccordionBody>
                  <ContentGeneric background="#ffecda" padding="0px 16px 16px">
                    <TextMaxLine
                      fontSize="14px"
                      className="roboto"
                      fontColor="#070B11"
                    >
                      <FormattedMessage
                        id="accordion.quadro2"
                        defaultMessage="Baixa ou lenta ativação da base de usuários ou clientes"
                      />
                    </TextMaxLine>
                  </ContentGeneric>
                  <ImgGeneric width="100%" height="auto" margin="0px" borderBottomL="18px" borderBottomR="18px" src={AsianWoman} />
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={3} id='item3' style={vars}>
                <CAccordionHeader style={var2}>
                  <TextMaxLine
                      fontSize="16px"
                      className="roboto"
                      fontColor="#3A6980"
                  >
                     <FormattedMessage
                     id="accordion.titulo3"
                     defaultMessage="MONETIZAÇÃO"
                    />
                  </TextMaxLine>
                </CAccordionHeader>
                <CAccordionBody>
                  <ContentGeneric background="#e7fff9" padding="0px 16px 16px">
                    <TextMaxLine
                      fontSize="14px"
                      className="roboto"
                      fontColor="#070B11"
                    >
                       <FormattedMessage
                        id="accordion.quadro3"
                        defaultMessage="Falta de conhecimento do usuário ou cliente sobre o portfolio de produtos e baixa rentabilização desses com produtos e serviços complementares"
                      />
                    </TextMaxLine>
                  </ContentGeneric>
                  <ImgGeneric width="100%" height="auto" margin="0px" borderBottomL="18px" borderBottomR="18px" src={Business} />
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={4} id='item4' style={vars}>
                <CAccordionHeader style={var2}>
                  <TextMaxLine
                      fontSize="16px"
                      className="roboto"
                      fontColor="#393A80"
                  >
                     <FormattedMessage
                      id="accordion.titulo4"
                      defaultMessage="RETENÇÃO"
                    />
                  </TextMaxLine>
                </CAccordionHeader>
                <CAccordionBody>
                  <ContentGeneric background="#ece4ff" padding="0px 16px 16px">
                    <TextMaxLine
                      fontSize="14px"
                      className="roboto"
                      fontColor="#070B11"
                    >
                       <FormattedMessage
                        id="accordion.quadro4"
                        defaultMessage="Baixa recorrência e engajamento com os seus serviços e produtos"
                      />
                    </TextMaxLine>
                  </ContentGeneric>
                  <ImgGeneric width="100%" height="auto" margin="0px" borderBottomL="18px" borderBottomR="18px" src={SmartFinancial} />
                </CAccordionBody>
              </CAccordionItem>
              <CAccordionItem itemKey={5} id='item5' style={vars}>
                <CAccordionHeader style={var2}>
                  <TextMaxLine
                      fontSize="16px"
                      className="roboto"
                      fontColor="#AC2E5B"
                  >
                    <FormattedMessage
                        id="accordion.titulo5"
                        defaultMessage="DIGITALIZAÇÃO"
                    />
                  </TextMaxLine>
                </CAccordionHeader>
                <CAccordionBody>
                  <ContentGeneric background="#ffe8ec" padding="0px 16px 16px">
                    <TextMaxLine
                      fontSize="14px"
                      className="roboto"
                      fontColor="#070B11"
                    >
                       <FormattedMessage
                        id="accordion.quadro5"
                        defaultMessage="Baixa conversão ou alto custo de aquisição via canais digitais"
                      />
                    </TextMaxLine>
                  </ContentGeneric>
                  <ImgGeneric width="100%" height="auto" margin="0px" borderBottomL="18px" borderBottomR="18px" src={Optimizacion} />
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </ContentGeneric>
          <ContentGeneric id="containerCollapsible" displayxS="none" dis="block">
            <div
              style={{
                background: 'transparent', borderRadius: '16px 16px 0px 0px', minHeight: 'auto'
              }}
              className="col-12"
            >
              <ContainerFlex gap="0" displayM="block" marginM={ changeConversacion ? '150px 0 0 0' : '60px 0 0 0'}>
                <Desafios
                  onClick={() => handleClickInteraccion('Conversacion')}
                  bg={changeConversacion ? '#E0E9FE' : ''}
                  color={changeConversacion ? '#393A80' : ''}
                  fw={changeConversacion ? '700' : ''}
                >
                  <FormattedMessage
                    id="accordion.titulo1"
                    defaultMessage="AQUISIÇÃO"
                  />
                </Desafios>
                <Desafios
                  onClick={() => handleClickInteraccion('Optimizacion')}
                  bg={changeOptimizacion ? '#FFE9D6' : ''}
                  color={changeOptimizacion ? '#80460C' : ''}
                  fw={changeOptimizacion ? '700' : ''}
                >
                  <FormattedMessage
                    id="accordion.titulo2"
                    defaultMessage="ATIVAÇÃO"
                  />
                </Desafios>
                <Desafios
                  onClick={() => handleClickInteraccion('Perfil')}
                  bg={changePerfil ? '#E0FEF7' : ''}
                  color={changePerfil ? '#3A6980' : ''}
                  fw={changePerfil ? '700' : ''}
                >
                  <FormattedMessage
                    id="accordion.titulo3"
                    defaultMessage="MONETIZAÇÃO"
                  />
                </Desafios>
                <Desafios
                  onClick={() => handleClickInteraccion('Estrategia')}
                  bg={changeEstrategia ? '#E8E0FE' : ''}
                  color={changeEstrategia ? '#393A80' : ''}
                  fw={changeEstrategia ? '700' : ''}
                >
                  <FormattedMessage
                    id="accordion.titulo4"
                    defaultMessage="RETENÇÃO"
                  />
                </Desafios>
                <Desafios
                  onClick={() => handleClickInteraccion('Soluciones')}
                  bg={changeSoluciones ? '#FFE4EB' : ''}
                  color={changeSoluciones ? '#AC2E5B' : ''}
                  fw={changeSoluciones ? '700' : ''}
                >
                  <FormattedMessage
                    id="accordion.titulo5"
                    defaultMessage="DIGITALIZAÇÃO"
                  />
                </Desafios>
              </ContainerFlex>
              <ContainerFlex minH={changeConversacion ? "auto" : "292px"} displayD="block" displayM="block">
                <FotoDesafio>
                  {changeConversacion ? <ImageInfo objectFit="cover" width="100%" heightM="200px" height="392px" MwidthD="100%" margin="auto" src={Businessman} /> : null}
                  {changeOptimizacion ? <ImageInfo objectFit="cover" width="100%" heightM="200px" height="392px" MwidthD="100%" margin="auto" src={AsianWoman} /> : null}
                  {changePerfil ? <ImageInfo objectFit="cover" width="100%" heightM="200px" height="392px" MwidthD="100%" margin="auto" src={Business} /> : null}
                  {changeEstrategia ? <ImageInfo objectFit="cover" width="100%" heightM="200px" height="392px" MwidthD="100%" margin="auto" src={SmartFinancial} /> : null}
                  {changeSoluciones ? <ImageInfo objectFit="cover" width="100%" heightM="200px" height="392px" MwidthD="100%" margin="auto" src={Optimizacion} /> : null}
                </FotoDesafio>
                <InfoDesafio>
                  {changeConversacion
                    ? <FormattedMessage
                    id="accordion.quadro1"
                    defaultMessage="Baixa conversão ou alto custo de aquisição via canais digitais"
                  />
                    : ''}
                  {changeOptimizacion
                    ? <FormattedMessage
                    id="accordion.quadro2"
                    defaultMessage="Baixa ou lenta ativação da base de usuários ou clientes"
                  />
                    : ''}
                  {changePerfil
                    ? <FormattedMessage
                    id="accordion.quadro3"
                    defaultMessage="Falta de conhecimento do usuário ou cliente sobre o portfolio de produtos e baixa rentabilização desses com produtos e serviços complementares"
                  />
                    : ''}
                  {changeEstrategia
                    ? <FormattedMessage
                    id="accordion.quadro4"
                    defaultMessage="Baixa recorrência e engajamento com os seus serviços e produtos"
                  />
                    : ''}
                  {changeSoluciones
                    ? <FormattedMessage
                    id="accordion.quadro5"
                    defaultMessage="Estratégias ineficientes de digitalizar a experiência do usuário ou cliente"
                  />
                    : ''}
                </InfoDesafio>
              </ContainerFlex>
            </div>
          </ContentGeneric>
          <ContainerFD>
            <ContainerFlex marginT="105px" justifyC="space-between" displayM="block" directionM="rtl">
              <ContainerW margin="auto">
                <TitleBlue marginM="20px" className="cabin" fontS="32px" fontSD="32px" textAM="left" marginD="24px 0">
                  <FormattedMessage
                    id="soluções.titulo1"
                    defaultMessage="De soluções conversacionais e inteligentes até gamification,"
                  />
                </TitleBlue>
                <TextGray className="roboto" fontSD="16px" textAD="left">
                  <FormattedMessage
                    id="soluções.titulo2"
                    defaultMessage="criamos experiências digitais sob medida para seus usuários ou clientes."
                  />
                </TextGray>
              </ContainerW>
              <ContainerW>
                {/* <TitleBlue marginM="40px" className="cabin" fontS="32px" fontSD="32px" textAM="center" marginD="24px 0">
                  Cases
                </TitleBlue> */}
                <Case1
                  id="prueba"
                  onMouseOver={() => handleClickInteraccion('Adquisicion')}
                  height="auto"
                  bf={!changeAdquisicion ? 'blur(16px)' : ''}
                >
                  <TextGray fontW="700" fontSD="16px" color="#393A80">
                    <FormattedMessage
                      id="case.titulo1"
                      defaultMessage=" Plataforma conversacional"
                    />
                  </TextGray>
                  {changeAdquisicion
                    ? <div style={{ color: "#393A80" }}>
                    <FormattedMessage
                      id="case.texto1"
                      defaultMessage="Aumento da conversão de novos usuários ou clientes via canais digitais com o uso de
                      plataforma conversacional para personalizar processo com base em Inteligência Artificial
                      e Machine Learning"
                    />
                  </div> : null
                  }
                </Case1>
                <Case2
                  onMouseOver={() => handleClickInteraccion('Onboarding')}
                  /* onClick={() => handleClickInteraccion('Onboarding')} */
                  height="auto"
                  bf={!changeOnboarding ? 'blur(16px)' : ''}
                >
                  <TextGray fontW="700" fontSD="16px" color="#80460C">
                    <FormattedMessage
                      id="case.titulo2"
                      defaultMessage="Onboarding digital"
                    />
                  </TextGray>
                  {changeOnboarding
                    ? <div style={{ color: "#80460C" }}>
                    <FormattedMessage
                      id="case.texto2"
                      defaultMessage=" Agilidade e aumento da ativação de usuários ou clientes com implementação da
                      educação conversacional pós conversão em usuário ou cliente"
                    />
                   </div> : null
                  }
                </Case2>
                <Case3
                  onMouseOver={() => handleClickInteraccion('Crosselling')}
                  height="auto"
                  bf={!changeCrosselling ? 'blur(16px)' : ''}
                >
                  <TextGray fontW="700" fontSD="16px" color="#507E62">
                    <FormattedMessage
                        id="case.titulo3"
                        defaultMessage="Cross Selling inteligente"
                      />
                  </TextGray>
                  {changeCrosselling
                    ? <div style={{ color: "#507E62" }}>
                      <FormattedMessage
                        id="case.texto3"
                        defaultMessage="Perfilamento dos usuários ou clientes com base em inteligência artificial para
                        direcionamento e potencialização de aquisição de produtos e serviços
                        complementares"
                      />
                    </div> : null
                  }
                </Case3>
                <Case4
                  onMouseOver={() => handleClickInteraccion('Experiencia')}
                  height="auto"
                  bf={!changeExperiencia ? 'blur(16px)' : ''}
                >
                  <TextGray fontW="700" fontSD="16px" color="#393A80">
                    <FormattedMessage
                      id="case.titulo4"
                      defaultMessage="Engajamento por gamificação"
                    />
                  </TextGray>
                  {changeExperiencia
                    ? <div style={{ color: "#393A80" }}>
                        <FormattedMessage
                            id="case.texto4"
                            defaultMessage="Aumento da retenção de usuários ou clientes com o uso de gamificação
                            personalizada "
                        />
                    </div> : null
                  }
                </Case4>
              </ContainerW>
            </ContainerFlex>
          </ContainerFD>
          <CarruselSolution />
          {/* <TitleBlue marginM="40px" className="cabin" fontS="32px" fontSD="32px" textAM="center" marginD="80px 0 24px 0">
            Cases de sucesso
          </TitleBlue>
          <TextGray
            className="roboto"
            textA={"center"}
            textAD="center"
            fontSD="16px"
            style={{ margin: "15px 0" }}
          >
            +300 empresas/partners y 3M de usuarios en <span style={{ color: '#F64282' }}>coru.com</span>
          </TextGray>
          <ImageInfo widthD="auto" MwidthD="614px" margin="auto" mtop="30px" src={Ballons} /> */}
          <TitleBlue color="#3A6980" marginM="40px" className="cabin" fontS="32px" fontSD="32px" textAM="center" marginD="80px 0 24px 0">
            <FormattedMessage
              id="startups.title"
              defaultMessage="São mais de 300 empresas e startups escalando seus canais digitais"
            />
          </TitleBlue>
          <Carrousel1 />
          <TitleBlue color="#3A6980" marginM="40px" className="cabin" fontS="32px" fontSD="32px" textAM="center" marginD="80px 0 24px 0">
            <FormattedMessage
              id="startups.title2"
              defaultMessage="Contamos com os melhores parceiros"
            />
          </TitleBlue>
          <Carrousel2 />
          {/* <ImageInfo widthD="auto" MwidthD="200px" margin="auto" mtop="30px" src={GifLogos1} /> */}
          {/* <ImageInfo widthD="auto" MwidthD="200px" margin="auto" mtop="30px" src={GifLogos} /> */}
          <div id="CrearCuenta" style={{
            display: 'flex', margin: "15px 0", justifyContent: 'space-between', width: '100%', gap: '12px', alignItems: "center"
          }}>
            <ContainerOfFormImg bgColor="none" displayM="none" >
              <ImgMancha src={ManchasBrasil} alt=""/>
            </ContainerOfFormImg>
          {otherContainer ? <ContainerOfForm>
              <WrapperOFElementsForm>
                <TitleOfForm>
                  <FormattedMessage
                    id="app.contato.title"
                    defaultMessage="Qual é o seu desafio?"
                  />
                </TitleOfForm>
                <SubTitleForm>
                  <FormattedMessage
                    id="app.contato.subtitle"
                    defaultMessage="Para falar com um de nossos especialistas, use o formulário abaixo e entraremos em contato em breve"
                  />
                </SubTitleForm>
                <RegisterWithBusinessOfBrasil
                setotherContainer={setotherContainer}
                setopenModalSuccessRegister={setopenModalSuccessRegister}
                />
              </WrapperOFElementsForm>
            </ContainerOfForm>
            : <ContainerOFSuccesRegistro>
            <TitleOFBx>
              <FormattedMessage
                id="registro.success"
                defaultMessage="Seu registro foi enviado com sucesso"
              />
            </TitleOFBx>
            <SubtitleOFBx>
            <FormattedMessage
                id="registro.successMsg"
                defaultMessage="Um especialista entrará em contato em breve para lhe dar mais informações sobre os serviços"
              />
            </SubtitleOFBx>
            </ContainerOFSuccesRegistro>
          }
          </div>
          <br /><br />
        </div>
        <RegisterWithBusiness
          key={129}
          open={modalregister}
          setOpen={setModalRegister}
          setSuccess={setopenModalSuccess}
          success={openModalSuccess}
          isBrasilLp={true}
          setopenModalSuccessRegister={setopenModalSuccessRegister}
        />
        <ModalSuccessRegister
        setopenModalSuccessRegister={setopenModalSuccessRegister}
        key={130}
        open={openModalSuccess}
        setOpen={setopenModalSuccess}
        />
        <ModalContainerRegisterComponent
          key={131}
          open={openModalSuccessRegister}
          setOpen={setopenModalSuccessRegister}
        />
        <Footer isAboutUS="si" isLpBrasil="si" section="LPBrasil"/>
      </Container1>
  );
}

export default LPBrasil;

const ContainerOFSuccesRegistro = styled.div`
  height: 161px;
  width: 606px;
  position: relative;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  padding: 25px 20px;
`;

const TitleOFBx = styled.h3`
  font-weight: 700;
  font-size: 32px;
  font-family:Cabin;
  color: #ac2e5b;
`;
const SubtitleOFBx = styled.h3`
  font-weight: 400;
  font-size: 16px;
  font-family: Cabin;
  color: #595959;
`;

const ContainerOfFormImg = styled.div`
width: 45%;
  /* max-height: 646px; */
  height: 100%;
  
  background-color: ${(p) => p.bgColor || 'rgba(255, 255, 255, 0.5)'};
  border-radius: 16px;
  display: flex;
  padding: 24px 24px;
  @media (max-width: 770px) {
    display: ${(props) => props.displayM || "flex"};
  }
`;

const ContentGenericNew = styled.div`
  height: auto;
  background-color: transparent;
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  align-items: center;
  align-content: center;
  margin-right: 5%;

  @media (max-width: 770px) {
    display: none;
  }
`;

const ButtonSplit = styled.div`
  height: auto;
  background-color: transparent;
  border: none;
`;
