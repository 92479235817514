/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CoruLogo from '../../assets/img/LPBrasil/CoruLogoBlack.svg';
import { Image } from '../LpHomeCoru/Assets/styles/Footer.styled';
import {
  ModalFull,

} from './common.styled';
import { langContext } from '../../context/LangContex';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '500px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    textAlign: 'center',
    borderRadius: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '55%',
    '@media (max-width: 768px)': {
      height: '38%',
    },
  },
}));

const ModalContainerRegisterComponent = ({ setOpen, open }) => {
  const handleClose = () => {
    setOpen(false);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  };
  const heightW = window.screen.height;
  return (
    <Modal open={open}>
        <ModalFull
              heightxsss="40%"
              width="44%"
              widthXs="90%"
              height={`${heightW - 698}px`}
              heightXs={`40%`}
              bottom="auto"
              borderR="16px"
              margin="5% 25%"
              marginXs="5%"
              className='d-flex flex-column'
              justifyC="flex-start"
              textAlign="center"
            >
  <ModalContainerRegister>
      <ButtonCloseModal onClick={handleClose}><ButtonTxt>X</ButtonTxt></ButtonCloseModal>
      <ImageModalContainer>
      <Image
          cursor="pointer"
          src={CoruLogo}
          width="230px"
          mwidth="97.92px"
          heightMobile="20.8px"
          alt="logo-coru"
        />
      </ImageModalContainer>
      <TitleOfModalContainer>
        <FormattedMessage
          id="registro.sucess"
          defaultMessage="Seu registro foi enviado com sucesso"
        />
      </TitleOfModalContainer>
        <SubTitleOfModalContainer>
          <FormattedMessage
            id="registro.successMsg"
            defaultMessage="Um especialista entrará em contato em breve para lhe dar mais informações sobre os serviços"
          />
        </SubTitleOfModalContainer>
      </ModalContainerRegister>
      </ModalFull>
      </Modal>
  );
};

export default ModalContainerRegisterComponent;

const ModalContainerRegister = styled.div`
  position: relative;
  height: 480px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  
  @media (max-width: 770px) {
    height: 100%;
  }
  
  @media (max-width: 770px) {
    height: 100%;
  }
`;

const TitleOfModalContainer = styled.h1`
  color: #393a80;
  font-family: Cabin;
  font-weight: 700;
  font-size: 62px;
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: 770px) {
    font-size: 31px;
  }
  
  @media (max-width: 460px) {
    font-size: 26px;
  }
`;

const SubTitleOfModalContainer = styled.p`
  color: #595959;
  font-weight: 400;
  font-family: Cabin;
  font-size: 24px;
  padding: 24px 10px;
  @media (max-width: 770px) {
    font-size: 12px;
  }
  
  @media (max-width: 460px) {
    font-size: 12px;
  }
`;

const ImageModalContainer = styled.div`
  padding: 40px 0px 30px 0px;
  /* border: 1px solid black; */
`;

const ButtonCloseModal = styled.button`
  border: none;
  background-color: transparent;
  position: relative;
  top: 5%;
  left: 45%;
`;

const ButtonTxt = styled.p`
  font-weight: 700;
  font-size: 15px;
`;
