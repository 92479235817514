/* eslint-disable max-len */
/* eslint-disable import/no-useless-path-segments */
import React, { useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/styles/Home.css";
// import WOlayout from '../components/layouts/WOlayout';
import "../assets/styles/kanda/KandaDesktop.css";
import Porcentaje from "../assets/gifs/porcentaje.gif";
import PorcentajeMex from "../assets/gifs/porcentajemex.gif";
// import TribuCoru from "../assets/img/TribuCoru.svg";
import Adquisición from "../assets/img/forBusiness/Adquisición.svg";
import Experiencia from "../assets/img/forBusiness/Experiencia.svg";
import Monetización from "../assets/img/forBusiness/Monetización.svg";
import EduFinanciera from "../assets/img/forBusiness/EduFinanciera.svg";
import Validación from "../assets/img/forBusiness/Validación.svg";
import Onboarding from "../assets/img/forBusiness/Onboarding.svg";
import CaaSTrue from "../assets/img//forBusiness/CaaSTrue.svg";
import OriginateTrue from "../assets/img//forBusiness/OriginateTrue.svg";
import BindTrue from "../assets/img//forBusiness/BindTrue.svg";
import CMCTrue from "../assets/img//forBusiness/CMCTrue.svg";
import CaaSFalse from "../assets/img//forBusiness/CaaSFalse.svg";
import OriginateFalse from "../assets/img//forBusiness/OriginateFalse.svg";
import BindFalse from "../assets/img//forBusiness/BindFalse.svg";
import CMCFalse from "../assets/img//forBusiness/CMCFalse.svg";
import InfoCaaS from "../assets/img//forBusiness/InfoCaaS.svg";
import InfoOriginate from "../assets/img//forBusiness/InfoOriginate.svg";
import InfoBind from "../assets/img//forBusiness/InfoBind.svg";
import InfoCMC from "../assets/img//forBusiness/InfoCMC.svg";
import CMCOn from "../assets/img//forBusiness/CaaSOn.svg";
import CMCOff from "../assets/img//forBusiness/CaaSOff.svg";
import OriginateOn from "../assets/img//forBusiness/OriginateOn.svg";
import OriginateOff from "../assets/img//forBusiness/OriginateOff.svg";
import BindOn from "../assets/img//forBusiness/BindOn.svg";
import BindOff from "../assets/img//forBusiness/BindOff.svg";
import CaaSOn from "../assets/img//forBusiness/CMCOn.svg";
import CaaSOff from "../assets/img//forBusiness/CMCOff.svg";
import InversionistasD from "../assets/img/equipoCoru.jpg";
import { Image } from "../assets/styles/Home.styled";
import ImageLogo from "../assets/img/forBusiness/CoruLogoFB.svg";
import ColaboradoresI from "../assets/img/Coru_colaboradores.gif";
// import { imageComponent } from "../components/common/common";
import { CardsBtn } from "../assets/styles/Training.styled";
// import Colaboradores from "../components/rediseno_about_us/Colaboradores";
import Footer from "../components/LpHomeCoru/Components/Footer";
import KandaButton from "../components/LpHomeCoru/utils/KandaButton";
import { ContentGeneric } from "../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";
import RegisterWithBusiness from "./pantallasInicio/ registerWithBusiness";
import ModalSuccessRegister from "../components/common/ModalSuccessRegister";
import ClickButton from "../Helpers/HookHelpers";

const Container1 = styled.div`
  width: 100%;
  left: 0px;
  top: 0px;
  background: linear-gradient(360deg, rgba(239, 209, 247, 0.32) 0.52%, rgba(209, 209, 247, 0.32) 39.06%, rgba(255, 255, 255, 0.32) 100%);
  min-height: 100vh;
  padding: 24px;
  gap: 24px;
  align-items: center;
  @media (min-width: 770px) {
    display: grid;
  }
`;

const TitleBlue = styled.div`
  font-weight: 700;
  font-size: ${(props) => props.fontS || "38px"};
  line-height: 120%;
  align-items: center;
  text-align: ${(props) => props.textA || "center"};
  letter-spacing: -0.3px;
  color: #393a80;
  margin: ${(props) => props.marginD || ""};
  @media (min-width: 770px) {
    text-align: ${(props) => props.textAM || "center"};
    font-size: ${(props) => props.fontSD || "52px"};
  }
`;

const TextGray = styled.div`
  font-weight: ${(props) => props.fontW || "400"};
  font-size: ${(props) => props.fontS || "16px"};
  line-height: 120%;
  align-items: center;
  letter-spacing: -0.3px;
  color: #595959;
  text-align: ${(props) => props.textA || ""};
  margin-top: ${(props) => props.marginT || ""};
  margin: ${(props) => props.margin || ""};
  cursor: ${(props) => props.cursor || ""};
  @media (min-width: 770px) {
    display: ${(props) => props.displayMQ || "block"};
    font-size: ${(props) => props.fontSD || "24px"};
    text-align: ${(props) => props.textAD || ""};
  }
`;

const ContainerFlex = styled.div`
  display: flex;
  gap: 24px;
  justify-content: ${(props) => props.justifyC || ""};
  margin: ${(props) => props.margin || ""};
  margin-top: ${(props) => props.marginT || ""};
  text-align: ${(props) => props.textA || ""};
  @media (max-width: 769px) {
    display: ${(props) => props.displayM || ""};
    direction: ${(props) => props.directionM || ""};
  }
`;

const ContainerW = styled.div`
  width: 100%;
  @media (min-width: 769px) {
    width: 50%;
  }
`;
const ImageCoach = styled.img`
  width: ${(props) => props.width || "100%"};
  max-height: 50%;
  cursor: pointer;

  @media (min-width: 770px) {
    max-height: 100%;
    width: 336px;
  }
`;

const ImageForMob = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border: ${(props) => props.border || ""};
  box-shadow:  ${(props) => props.boxS || ""};
  border-radius: 16px;
  text-align: left;
  padding: 8px 16px;
  min-height: 152px;
  margin-bottom: 16px;
`;

const ImageInfo = styled.img`
  width: 100%;
  max-height: 50%;

  @media (min-width: 770px) {
    max-height: 100%;
    width: ${(props) => props.widthD || "605px"};
  }
`;

const ImageInfoMob = styled.img`
  width: 140%;
  max-height: 50%;
  position: relative;
  margin-left: -20%;
`;

const ContLogo = styled.div`
  width: 20%;
  margin-left: 0;
  @media (max-width: 769px) {
    margin-left: 0;
    width: 38%;
    margin-top: 15px;
  }
`;

const ContLogo2 = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 769px) {
    width: 62%;
  }
`;

const ContainerFM = styled.div`
  display: none;
  @media (max-width: 770px) {
    display: block;
    text-align: ${(props) => props.textA || ""};
  }
`;
const ContainerFD = styled.div`
  display: none;
  @media (min-width: 770px) {
    display: flex;
    margin-top: 30px;
  }
`;

const TitleMInfo = styled.span`
  color: ${(props) => props.color || "#595959"};
  font-weight: 600;
  text-align: left;
  font-size: 18px;
`;

const handleClickCoru = (name) => {
  switch (name) {
    case 'Ingresar':
      sessionStorage.setItem('Training', 'Business');
      window.location.href = '/login-v2';
      break;
    case 'CrearCuenta':
      window.location.href = '/personal-Information-v2';
      break;
    default:
      break;
  }
};

const Header = () => (
  <div style={{ height: "85px", display: "flex" }}>
    <ContLogo>
      <Image
        cursor="pointer"
        src={ImageLogo}
        width="200px"
        mwidth="97.92px"
        heightMobile="20.8px"
        alt="logo-coru"
      />
    </ContLogo>
    <ContLogo2>
      <ContentGeneric width="40%" widthMd="10%">
        <KandaButton
          action={() => handleClickCoru('Ingresar')}
          mr="54px"
          width="100%"
          height="19px"
          typeBtn="text"
          fontS="13px"
        >
          Ingresar
        </KandaButton>
      </ContentGeneric>
      <ContentGeneric width="60%" widthMd="15%">
        <KandaButton
          action={() => handleClickCoru('CrearCuenta')}
          mr="96px"
          width="100%"
          fontS="13px"
          typeBtn="active">
          Crear Cuenta
        </KandaButton>
      </ContentGeneric>
    </ContLogo2>
  </div>
);

function ForBusinessLP() {
  const { actions } = ClickButton();
  const [changeCaaS, setchangeCaaS] = useState(true);
  const [changeOriginate, setchangeOriginate] = useState(false);
  const [changeBind, setchangeBind] = useState(false);
  const [changeCMC, setchangeCMC] = useState(false);

  const [changeCaaSM, setchangeCaaSM] = useState(true);
  const [changeOriginateM, setchangeOriginateM] = useState(false);
  const [changeBindM, setchangeBindM] = useState(false);
  const [changeCMCM, setchangeCMCM] = useState(false);

  const [changeAdquisicion, setchangeAdquisicion] = useState(true);
  const [changeOnboarding, setchangeOnboarding] = useState(false);
  const [changeValidacion, setchangeValidacion] = useState(false);
  const [changeEduFinanciera, setchangeEduFinanciera] = useState(false);
  const [changeMonetizacion, setchangeMonetizacion] = useState(false);
  const [changeExperiencia, setchangeExperiencia] = useState(false);
  const [modalregister, setModalRegister] = useState(false);
  const [openModalSuccess, setopenModalSuccess] = useState(false);
  console.log('sessionStorage', sessionStorage);

  const handleClickInfo = (name) => {
    console.log(name);
    switch (name) {
      case 'CaaS':
        if (!changeCaaS) {
          setchangeCaaS(true);
          setchangeOriginate(false);
          setchangeBind(false);
          setchangeCMC(false);
        }
        break;
      case 'Originate':
        if (!changeOriginate) {
          setchangeCaaS(false);
          setchangeOriginate(true);
          setchangeBind(false);
          setchangeCMC(false);
        }
        break;
      case 'Bind':
        if (!changeBind) {
          setchangeCaaS(false);
          setchangeOriginate(false);
          setchangeBind(true);
          setchangeCMC(false);
        }
        break;
      case 'CMC':
        if (!changeCMC) {
          setchangeCaaS(false);
          setchangeOriginate(false);
          setchangeBind(false);
          setchangeCMC(true);
        }
        break;
      default:
        break;
    }
  };

  const handleClickInfoM = (name) => {
    console.log(name);
    switch (name) {
      case 'CaaS':
        if (!changeCaaSM) {
          setchangeCaaSM(true);
          setchangeOriginateM(false);
          setchangeBindM(false);
          setchangeCMCM(false);
        }
        break;
      case 'Originate':
        if (!changeOriginateM) {
          setchangeCaaSM(false);
          setchangeOriginateM(true);
          setchangeBindM(false);
          setchangeCMCM(false);
        }
        break;
      case 'Bind':
        if (!changeBindM) {
          setchangeCaaSM(false);
          setchangeOriginateM(false);
          setchangeBindM(true);
          setchangeCMCM(false);
        }
        break;
      case 'CMC':
        if (!changeCMCM) {
          setchangeCaaSM(false);
          setchangeOriginateM(false);
          setchangeBindM(false);
          setchangeCMCM(true);
        }
        break;
      default:
        break;
    }
  };

  const handleClickInteraccion = (name) => {
    console.log(name);
    switch (name) {
      case 'Adquisicion':
        if (!changeAdquisicion) {
          setchangeAdquisicion(true);
          setchangeOnboarding(false);
          setchangeValidacion(false);
          setchangeEduFinanciera(false);
          setchangeMonetizacion(false);
          setchangeExperiencia(false);
        }
        break;
      case 'Onboarding':
        if (!changeOnboarding) {
          setchangeAdquisicion(false);
          setchangeOnboarding(true);
          setchangeValidacion(false);
          setchangeEduFinanciera(false);
          setchangeMonetizacion(false);
          setchangeExperiencia(false);
        }
        break;
      case 'Validacion':
        if (!changeValidacion) {
          setchangeAdquisicion(false);
          setchangeOnboarding(false);
          setchangeValidacion(true);
          setchangeEduFinanciera(false);
          setchangeMonetizacion(false);
          setchangeExperiencia(false);
        }
        break;
      case 'EduFinanciera':
        if (!changeEduFinanciera) {
          setchangeAdquisicion(false);
          setchangeOnboarding(false);
          setchangeValidacion(false);
          setchangeEduFinanciera(true);
          setchangeMonetizacion(false);
          setchangeExperiencia(false);
        }
        break;
      case 'Monetizacion':
        if (!changeMonetizacion) {
          setchangeAdquisicion(false);
          setchangeOnboarding(false);
          setchangeValidacion(false);
          setchangeEduFinanciera(false);
          setchangeMonetizacion(true);
          setchangeExperiencia(false);
        }
        break;
      case 'Experiencia':
        if (!changeExperiencia) {
          setchangeAdquisicion(false);
          setchangeOnboarding(false);
          setchangeValidacion(false);
          setchangeEduFinanciera(false);
          setchangeMonetizacion(false);
          setchangeExperiencia(true);
        }
        break;
      default:
        break;
    }
  };

  const handleClickModal = (name) => {
    actions({
      action: 'ClickButtonForBusiness',
      variables: `${name}`,
      collection: 'ClickButtonHeaderFor_Business',
    });
    setModalRegister(!modalregister);
  };

  return (
    <Container1>
      <Header />
      <div
        style={{
          maxWidth: "1056px",
          display: "flex",
          flexDirection: "column",
          justifySelf: "center",
        }}
      >
        <TitleBlue className="cabin" style={{ margin: "15px 0" }}>
          Nuestros productos
        </TitleBlue>
        <TextGray
          className="roboto"
          textA={"center"}
          textAD="center"
          fontSD="16px"
          style={{ margin: "15px 0" }}
        >
          Somos <span style={{ color: '#F64282' }}>la plataforma líder en Coaching Digital en México</span> que conecta a millones de usuarios
          con cientos de productos vía experiencias inteligentes y empáticas. Contamos con la red de
          socios más grande del país.
        </TextGray>
        <ContainerFD>
          <div style={{
            display: 'flex', margin: "15px 0", justifyContent: 'space-between', width: '100%'
          }}>
            <div style={{ display: 'grid' }}>
              <ImageCoach src={changeCaaS ? CaaSTrue : CaaSFalse} onClick={() => handleClickInfo('CaaS')} />
              <ImageCoach src={changeOriginate ? OriginateTrue : OriginateFalse} onClick={() => handleClickInfo('Originate')} />
              <ImageCoach src={changeBind ? BindTrue : BindFalse} onClick={() => handleClickInfo('Bind')} />
              <ImageCoach src={changeCMC ? CMCTrue : CMCFalse} onClick={() => handleClickInfo('CMC')} />
            </div>
            <div style={{ minHeight: '449px' }}>
              {changeCaaS ? <ImageInfo src={InfoCaaS} /> : null}
              {changeOriginate ? <ImageInfo src={InfoOriginate} /> : null}
              {changeBind ? <ImageInfo src={InfoBind} /> : null}
              {changeCMC ? <ImageInfo src={InfoCMC} /> : null}
              <div  className="text-center mt-3">
                <CardsBtn
                  styledMaxWidth="300px"
                  type="button"
                  min_w="300px"
                  minmd="155px"
                  min_web="300px"
                  max_w="300px"
                  windthmd="60%"
                  style={{ borderRadius: "16px" }}
                  onClick={() => handleClickModal('contactanosLP')}
                >
                  Contáctanos
                </CardsBtn>
              </div>
            </div>
          </div>
       
        </ContainerFD>
        <ContainerFM textA="center">
          <div style={{ columnCount: '2' }}>
            <ImageForMob border={changeCaaSM ? '1px solid #A5C3FF' : ''} boxS={changeCaaSM ? '0px 4px 20px #96B6F6' : ''} onClick={() => handleClickInfoM('CaaS')}>
              <ImageCoach width="65px" src={changeCaaSM ? CaaSOn : CaaSOff} />
              <br />
              <TitleMInfo className="roboto" color={changeCaaSM ? '#393A80' : ''}>CaaS</TitleMInfo>
              <br />
              <span className="roboto" style={{ color: '#595959' }}>Coaching as a Service</span>
            </ImageForMob>
            <ImageForMob border={changeBindM ? '1px solid #77C2B0' : ''} boxS={changeBindM ? '0px 4px 20px rgba(119, 194, 176, 0.5)' : ''} onClick={() => handleClickInfoM('Bind')}>
              <ImageCoach width="65px" src={changeBindM ? BindOn : BindOff} />
              <br />
              <TitleMInfo className="roboto" color={changeBindM ? '#3A6980' : ''}>Bind</TitleMInfo>
              <br />
              <span className="roboto" style={{ color: '#595959' }}>Financial Education for Employees</span>
            </ImageForMob>
            <ImageForMob border={changeOriginateM ? '1px solid #FF7932' : ''} boxS={changeOriginateM ? '0px 4px 20px rgba(255, 121, 50, 0.35)' : ''} onClick={() => handleClickInfoM('Originate')}>
              <ImageCoach width="65px" src={changeOriginateM ? OriginateOn : OriginateOff} />
              <br />
              <TitleMInfo className="roboto" color={changeOriginateM ? '#80460C' : ''}>Originate</TitleMInfo>
              <br />
              <span className="roboto" style={{ color: '#595959' }}>Customer Origination</span>
            </ImageForMob>
            <ImageForMob border={changeCMCM ? '1px solid #F87F7F' : ''} boxS={changeCMCM ? '0px 4px 20px rgba(248, 127, 127, 0.35)' : ''} onClick={() => handleClickInfoM('CMC')}>
              <ImageCoach width="65px" src={changeCMCM ? CMCOn : CMCOff} />
              <br />
              <TitleMInfo className="roboto" color={changeCMCM ? '#AC2E5B' : ''}>CMC</TitleMInfo>
              <br />
              <span className="roboto" style={{ color: '#595959' }}>Coru Master Class</span>
            </ImageForMob>
            {/* <ImageCoach src={changeCaaS ? CaaSTrue : CaaSFalse} onClick={() => handleClickInfo('CaaS')} />
            <ImageCoach src={changeOriginate ? OriginateTrue : OriginateFalse} onClick={() => handleClickInfo('Originate')} />
            <ImageCoach src={changeBind ? BindTrue : BindFalse} onClick={() => handleClickInfo('Bind')} />
            <ImageCoach src={changeCMC ? CMCTrue : CMCFalse} onClick={() => handleClickInfo('CMC')} /> */}
          </div>
          <br />
          <div style={{ minHeight: 'auto' }}>
            {changeCaaSM ? <ImageInfoMob src={InfoCaaS} /> : null}
            {changeOriginateM ? <ImageInfoMob src={InfoOriginate} /> : null}
            {changeBindM ? <ImageInfoMob src={InfoBind} /> : null}
            {changeCMCM ? <ImageInfoMob src={InfoCMC} /> : null}
          </div>
          <CardsBtn
            styledMaxWidth="300px"
            type="button"
            min_w="300px"
            minmd="155px"
            min_web="300px"
            max_w="300px"
            windthmd="60%"
            style={{ borderRadius: "16px" }}
            onClick={() => handleClickModal('contactanosLP')}
          >
            Contáctanos
          </CardsBtn>
        </ContainerFM>
        <ContainerFD>
          <ContainerFlex marginT="105px" justifyC="space-between" displayM="block" directionM="rtl">
            <ContainerW>
              <TitleBlue className="cabin" fontS="32px" fontSD="32px" textAM="left" marginD="24px 0">
                Mayor valor en la interacción con tus usuarios
              </TitleBlue>
              <TextGray className="roboto" fontSD="16px" textAD="left">
                <span style={{ color: '#F64282' }}>Coru For Business es ideal</span> para las empresas que buscan:
              </TextGray>
              {/* <ul>
                <li>
                  <TextGray fontSD={changeAdquisicion ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Adquisicion')}>
                    <b>Adquisición y perfilamiento</b> de usuario online.
                  </TextGray>
                </li>
                <li>
                  <TextGray fontSD={changeOnboarding ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Onboarding')}>
                    <b>Onboarding</b> digital de usuarios
                  </TextGray>
                </li>
                <li>
                  <TextGray fontSD={changeValidacion ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Validacion')}>
                    <b>Validación</b> del perfil crediticio
                  </TextGray>
                </li>
                <li>
                  <TextGray fontSD={changeEduFinanciera ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('EduFinanciera')}>
                    <b>Educación financiera</b> acerca de productos o servicios
                  </TextGray>
                </li>
                <li>
                  <TextGray fontSD={changeMonetizacion ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Monetizacion')}>
                    <b>Monetización</b> de usuarios con productos financieros complementarios
                  </TextGray>
                </li>
                <li>
                  <TextGray fontSD={changeExperiencia ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Experiencia')}>
                    Mejora en <b>experiencia y satisfacción</b> de usuarios
                  </TextGray>
                </li>
              </ul> */}
            </ContainerW>
            <ContainerW>
              {changeAdquisicion ? <ImageInfo onClick={() => handleClickInteraccion('Onboarding')} widthD="425px" src={Adquisición} /> : null}
              {changeOnboarding ? <ImageInfo onClick={() => handleClickInteraccion('Validacion')} widthD="425px" src={Onboarding} /> : null}
              {changeValidacion ? <ImageInfo onClick={() => handleClickInteraccion('EduFinanciera')} widthD="425px" src={Validación} /> : null}
              {changeEduFinanciera ? <ImageInfo onClick={() => handleClickInteraccion('Monetizacion')} widthD="425px" src={EduFinanciera} /> : null}
              {changeMonetizacion ? <ImageInfo onClick={() => handleClickInteraccion('Experiencia')} widthD="425px" src={Monetización} /> : null}
              {changeExperiencia ? <ImageInfo onClick={() => handleClickInteraccion('Adquisicion')} widthD="425px" src={Experiencia} /> : null}
            </ContainerW>
          </ContainerFlex>
        </ContainerFD>
        <ContainerFM>
          <TitleBlue className="cabin" fontS="24px" textAM="left" textA="left" marginD="24px 0">
            Mayor valor en la interacción con tus usuarios
          </TitleBlue>
          <TextGray className="roboto" fontSD="16px" textAD="left">
            <span style={{ color: '#F64282' }}>Coru For Business es ideal</span> para las empresas que buscan:
          </TextGray>
          <ContainerW>
            {changeAdquisicion ? <ImageInfo onClick={() => handleClickInteraccion('Onboarding')} widthD="425px" src={Adquisición} /> : null}
            {changeOnboarding ? <ImageInfo onClick={() => handleClickInteraccion('Validacion')} widthD="425px" src={Onboarding} /> : null}
            {changeValidacion ? <ImageInfo onClick={() => handleClickInteraccion('EduFinanciera')} widthD="425px" src={Validación} /> : null}
            {changeEduFinanciera ? <ImageInfo onClick={() => handleClickInteraccion('Monetizacion')} widthD="425px" src={EduFinanciera} /> : null}
            {changeMonetizacion ? <ImageInfo onClick={() => handleClickInteraccion('Experiencia')} widthD="425px" src={Monetización} /> : null}
            {changeExperiencia ? <ImageInfo onClick={() => handleClickInteraccion('Adquisicion')} widthD="425px" src={Experiencia} /> : null}
          </ContainerW>
          {/* <ul style={{ paddingInlineStart: '20px' }}>
            <li>
              <TextGray fontS={changeAdquisicion ? "20px" : "16px"} fontSD={changeAdquisicion ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Adquisicion')}>
                <b>Adquisición y perfilamiento</b> de usuario online.
              </TextGray>
            </li>
            <li>
              <TextGray fontS={changeOnboarding ? "20px" : "16px"} fontSD={changeOnboarding ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Onboarding')}>
                <b>Onboarding</b> digital de usuarios
              </TextGray>
            </li>
            <li>
              <TextGray fontS={changeValidacion ? "20px" : "16px"} fontSD={changeValidacion ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Validacion')}>
                <b>Validación</b> del perfil crediticio
              </TextGray>
            </li>
            <li>
              <TextGray fontS={changeEduFinanciera ? "20px" : "16px"} fontSD={changeEduFinanciera ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('EduFinanciera')}>
                <b>Educación financiera</b> acerca de productos o servicios
              </TextGray>
            </li>
            <li>
              <TextGray fontS={changeMonetizacion ? "20px" : "16px"} fontSD={changeMonetizacion ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Monetizacion')}>
                <b>Monetización</b> de usuarios con productos financieros complementarios
              </TextGray>
            </li>
            <li>
              <TextGray fontS={changeExperiencia ? "20px" : "16px"} fontSD={changeExperiencia ? "24px" : "16px"} cursor="pointer" margin="12px 0" className="roboto" onClick={() => handleClickInteraccion('Experiencia')}>
                Mejora en <b>experiencia y satisfacción</b> de usuarios
              </TextGray>
            </li>
          </ul> */}
        </ContainerFM>
        <br /><br />
        <TitleBlue className="cabin" fontS="24px" fontSD="32px" marginD="24px 0">
          ¿Cómo te ayudamos?
        </TitleBlue>
        <TextGray className="roboto" fontSD="16px" textA="center">
          Compártenos tus datos o agenda una llamada a través de nuestro Calendly para que uno de
          nuestros expertos se ponga en contacto contigo:
        </TextGray>
        <CardsBtn
          styledMaxWidth="300px"
          type="button"
          minmd="155px"
          min_w="300px"
          min_web="300px"
          max_w="300px"
          windthmd="60%"
          style={{ borderRadius: "16px" }}
          onClick={() => handleClickModal('CFB_contactanosLP')}
        >
          Contáctanos
        </CardsBtn>
        <br /><br />
      </div>
      <RegisterWithBusiness
        key={129}
        open={modalregister}
        setOpen={setModalRegister}
        setSuccess={setopenModalSuccess}
        success={openModalSuccess}
      />
      <ModalSuccessRegister
        key={130}
        open={openModalSuccess}
        setOpen={setopenModalSuccess}
      />
      <Footer isAboutUS="si" isLpForBusiness />
    </Container1>
  );
}

export default ForBusinessLP;
