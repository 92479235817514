import styled from 'styled-components';

export const NavComponent = styled.nav`
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: end;
  .nav-content {
    display: flex;
    align-items: stretch;
    gap: 10px;
    padding: 0.5rem 0;
  }
  p {
    display: flex;
    align-items: center;
  }
  .inicio {
    font-weight: bold;
    color: var(--darkAcqua);
  }
  .registrate {
    font-weight: bold;
    color: var(--violet);
    margin-right: 1.81rem;
  }
  .icon-style {
    height: fit-content;
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin-left: 10px;
    background-color: var(--acqua);
    img {
      height: fit-content;
      display: flex;
      align-items: center;
      margin: 0.5rem 0.7rem 0.1rem 0.7rem;
    }
  }
  .new-width-btn {
    .button-3d-effect,
    button {
      border-radius: 2rem;
      width: 8.125rem;
      height: 2.06rem;
      margin-right: 1rem;
    }
    
  }
  .navbar {
  overflow: hidden;
  background-color: #333;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
    float: left;
}

.dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: Source Sans Pro;
    margin: 0;
    font-weight: 400;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
    background-color: transparent;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 3;
}

.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}
@media(max-width: 1024px) and (min-width: 768px){
  .dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 10px;
    background-color: inherit;
    font-family: Source Sans Pro;
    margin: 0;
    font-weight: 400;
  }
}
@media(max-width: 830px){
  .down{
    display: none;
  }
}
`;
export const DivisorVH = styled.div`
width: 2px;
height: 50px;
margin-right: 20px;
background-color: #0091DA;
`;
