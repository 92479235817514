import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'node-fetch';

let urlService = 'https://api.coru.com/graphql';
if (process.env.REACT_APP_SERVERTYPE === 'Development') {
  urlService = 'https://api.coru.com/graphql';
} else if (process.env.REACT_APP_SERVERTYPE === 'Production') {
  urlService = 'https://api.coru.com/graphql';
} else if (process.env.REACT_APP_SERVERTYPE === 'Stg') {
  urlService = 'https://api-staging.coru.mx/graphql';
} else if (process.env.REACT_APP_SERVERTYPE === 'StgAb') {
  urlService = 'https://api-staging.coru.mx/graphql';
} else if (process.env.REACT_APP_SERVERTYPE === 'ProdAb') {
  urlService = 'https://api.coru.com/graphql';
}

const httpLink = new HttpLink({
  uri: urlService,
  // uri: 'http://localhost:5000/graphql',
  credentials: 'include',
  fetch,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    securityWord: 'test',
  },
}));

const client = () => new ApolloClient({
  // ssrMode: typeof window === 'undefined',
  connectToDevTools: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  }),
  onError: ({ networkError, graphQLErrors }) => {
    console.log('ApolloClient graphQLErrors', graphQLErrors);
    console.log('ApolloClient networkError', networkError);
  },
});

export default client;
