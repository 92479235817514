/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import '@coreui/coreui/dist/css/coreui.min.css';
import { FormattedMessage } from 'react-intl';
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { CREATE_SESSION, SEND_HUBSPOT_INFO } from "../../mutations";
import "../../assets/styles/TextCustom.css";
import ClickButton from "../../Helpers/HookHelpers";
import FullNameOfBrasil from "./FullNameOfBrasil";
import ContactoOfBrasil from "./ContactoOfBrasil";
import DataBusinessOfBrasil from "./DataBusinessOfBrasil";
import { Text2 } from "../../components/LpHomeCoru/Assets/styles/StyledGeneric.styled";
import MX from '../../lang/placeholder-MX.json';
import US from '../../lang/placeholder-US.json';
import PT from '../../lang/placeholder-pt.json';

const RegisterWithBusinessOfBrasil = ({ setopenModalSuccessRegister, setotherContainer }) => {
  const [form, setForm] = useState({
    fullname: "",
    email: "",
    numPhone: "",
    lada: "México lada +(52)",
    textlada: "México lada +(52)",
    Empresa: "",
    Posicion: "",
    Sitio: ""
  });

  const { actions } = ClickButton();

  const [tipoIdioma, settipoIdioma] = useState(window.sessionStorage.getItem("placeholder"));
  const [textHolder, settextHolder] = useState([PT]);

  useEffect(() => {
    switch (tipoIdioma) {
      case 'pt':
        settextHolder(PT);
        break;
      case 'en-US':
        settextHolder(US);
        break;
      case 'es-MX':
        settextHolder(MX);
        break;
      default:
        settextHolder(PT);
        break;
    }
  }, []);

  const [validCampos, setvalidCampos] = useState({
    personales: false,
    contacto: false,
    empresa: false,
  });
  const [disabledbtn, setdisabledbtn] = useState(true);

  const [sendInfoHubForm] = useMutation(SEND_HUBSPOT_INFO, {
    onCompleted({ sendInfoHubForm }) {
      setotherContainer(false);
    },
    onError(err) {
      console.log(err);
      setotherContainer(false);
    },
  });

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        // Consumo API de HubSpot
        sendInfoHubSpot();
      }
    },
    onError(err) {
      console.log(err);
      setotherContainer(false);
    },
  });

  const sendInfoHubSpot = () => {
    let hubSpotForm = {
      pId: "8315759",
      fId: "ef58c755-11a9-43e8-9a97-68fc382c1584",
      campos: [
        {
          name: "firstname",
          value: capitalizar(form.fullname),
        },
        {
          name: "apellido_paterno",
          value: '',
        },
        {
          name: "apellido_materno",
          value: '',
        },
        {
          name: 'fecha_de_nacimiento',
          value: '',
        },
        {
          name: "email",
          value: form.email,
        },
        {
          name: "phone",
          value: form.numPhone,
        },
        {
          name: "company",
          value: form.Empresa,
        },
        {
          name: 'jobtitle',
          value: form.Posicion,
        },
        {
          name: 'website',
          value: form.Sitio,
        },
      ],
    };
    sendInfoHubForm({
      variables: {
        dataJson: btoa(JSON.stringify(hubSpotForm)),
      },
    });
  };

  const capitalizar = (str) => {
    if (str && str !== "") {
      str = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return str || "";
  };

  const handleClickForm = (e) => {
    actions({
      action: 'ClickButtonLPBrasil',
      variables: 'ClickDatosUsuario_LpCoruBrasil',
      collection: 'LogClickButtonLpCoruBrasil',
    });

    e.preventDefault();
    e.stopPropagation();

    const infoUser = {
      'User Data': [
        {
          Name: form.fullname,
        }
      ],
      'Contact User': [
        {
          Email: form.email,
          'Phone Number': form.numPhone,
        }
      ],
      'Bussines Data': [
        {
          Name: form.Empresa,
          'Work Position': form.Posicion,
          Web: form.Sitio,
        }
      ]
    };

    const mongoInput = {
      input: {
        collection: 'LogLpCoruBrasil',
        data: JSON.stringify(infoUser),
      },
    };

    createSession({ variables: mongoInput });
  };

  useEffect(() => {
    if (
      validCampos.personales
      && validCampos.contacto
    ) {
      setdisabledbtn(false);
    } else {
      setdisabledbtn(true);
    }
  }, [validCampos]);

  return (
    <div>
      <FullNameOfBrasil form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} textHolder={textHolder.nomeContact}/>
      <ContactoOfBrasil form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} textHolder={textHolder.email}/>
      <DataBusinessOfBrasil form={form} setForm={setForm} validCampos={validCampos} setvalidCampos={setvalidCampos} textHolder={textHolder}/>
      <BoxInput>
        <ButtonNew
          mb="24px"
          width="59px"
          mt="1.2rem"
          onClick={(e) => handleClickForm(e)}
          disabled={disabledbtn}
          >
            <FormattedMessage
              id="app.buttonContacto"
              defaultMessage="Fale com um especialista"
            />
        </ButtonNew>
      </BoxInput>
    </div>
  );
};

export default RegisterWithBusinessOfBrasil;

const ButtonNew = styled.button`
  display: flex;
  border-radius: 16px;
  position: relative;
  width: 80%;
  cursor: pointer;
  height: 48px;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #F64282;
  border: none;
  color: #fff;
  margin-top: 4%;
`;

const BoxInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
