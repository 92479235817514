/* eslint-disable no-useless-escape */
/* eslint-disable no-var */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-debugger */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import True from '../../assets/img/True.svg';
import {
  SubtitleOne,
  Image,
} from "../../components/common/common.styled";
import "../../assets/styles/TextCustom.css";
import InputAnaIcon from "../../components/common/InputAnaIcon";
import { Input } from "../../assets/styles/Training.styled";
import styled from "styled-components";

const ContactoOfBrasil = ({
  form, setForm, validCampos, setvalidCampos, textHolder
}) => {
  const [tipoIdioma, settipoIdioma] = useState('pt');
  useEffect(() => {
    settipoIdioma(document.getElementById('padr').parentNode.id);
    console.log('tipoIidioma', tipoIdioma);
  }, []);

  const [error] = useState({
    email: false,
    emailvalid: -1,
    numPhone: false,
    numPhonevalid: -1,
  });

  const [texterror] = useState({
    email: "",
    numPhone: "",
  });

  const [emailError, setEmailError] = useState("");
  const [message, setmessage] = useState("");
  const [valphone, setvalphone] = useState(null);
  const [valEmail, setvalEmail] = useState('');
  const [visibleIcon, setvisibleIcon] = useState(false);
  const [visibleIconPhone, setvisibleIconPhone] = useState(true);

  const validarEmail = (valor) => {
    console.log('aquí ando 442', valor);
    if (
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        valor
      )
    ) {
      console.log('Ya entré y fue validado');
      setEmailError("valid Email");
      setmessage("");
      setvalEmail(valor);
      setvisibleIcon(true);
    } else {
      console.log('No fue validado');
      setEmailError("Enter valid Email!");
      setmessage("Enter valid Email!");
      setvalEmail(valor);
      setvisibleIcon(false);
    }
  };

  const validateEmail = (e) => {
    var email = e.target.value;
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    validarEmail(email);
  };

  const validatePhone = (e) => {
    console.log("Event", e);
    setForm({
      ...form,
      numPhone: e,
    });
    setvalphone(e);
  };

  useEffect(() => {
    if (valphone !== null && valphone.length === 13 && message === "") {
      setvalidCampos({
        ...validCampos,
        contacto: true,
      });
      if (valphone.length === 13) {
        setvisibleIconPhone(true);
      }
    } else {
      setvalidCampos({
        ...validCampos,
        contacto: false,
      });
      setvisibleIconPhone(false);
    }
  }, [valphone, valEmail]);

  const ValidNumero = (e) => {
    e.persist();
    if (e.target.name === "numPhone") {
      console.log(e.target.value.replace(/[^0-9]/g, ""));
      setForm({
        ...form,
        numPhone: e.target.value.replace(/[^0-9]/g, ""),
      });
      if (
        (e.target.value.length < 11 && e.target.value.length !== 10)
        || (e.target.name && !/^[0-9]+$/i.test(e.target.value))
      ) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
        });
        texterror.numPhone = "dado incorreto";
        error.numPhonevalid = 1;
        error.numPhone = false;

        // (".MuiInput-underline").addClass("MuiInput-underline-error");
      } else if (e.target.value.length < 11 && e.target.value.length === 10) {
        setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
        });
        sessionStorage.setItem(
          "cellPhone",
          e.target.value.replace(/[^0-9]/g, "")
        );
        error.numPhone = "";
        error.numPhonevalid = 0;
      }
    } else {
      setForm({
        ...form,
        lada: e.target.value,
        textlada: e.target.value,
      });
      const indexLada = e.target.value.indexOf("+(");
      const ladaselect = e.target.value.substring(indexLada);
      sessionStorage.setItem("ladauser", ladaselect);
    }
    ValidCampos();
  };

  const ValidCampos = () => {
    if (
      error.email == false
      && error.emailvalid === 0
      && error.numPhone == false
      && form.numPhone.length >= 10
    ) {
      setvalidCampos({
        ...validCampos,
        contacto: true,
      });
    } else {
      setvalidCampos({
        ...validCampos,
        contacto: false,
      });
    }
  };

  return (
    <Fragment>
      <SubtitleOne
        className="font-weight-bold roboto"
        size="16px"
        sizeMobile="16px"
        mb="0px"
        mt="24px"
        color="#AC2E5B"
      >
        <FormattedMessage
          id="app.email"
          defaultMessage="Dados de contato"
        />
      </SubtitleOne>
      <form autoComplete="off" className="col-12" style={{ padding: '0px' }}>
        <ContainerOfInputs>
          <InputWrapper>
            <InputAnaIcon
              placeholder={textHolder}
              type="email"
              id="email"
              value={valEmail}
              handleChange={validateEmail}
              handleBlur={validateEmail}
              error={error.email}
              texterror={texterror.email}
              errorinicial={error.emailvalid}
              visibleIcon={visibleIcon}
            />
          </InputWrapper>
          <InputWrapper>
            <Input padding="0px" className="d-flex flex-row">
              <PhoneInput
                country="br"
                countryCodeEditable={false}
                enableSearch
                containerStyle={{ width: "90%", padding: "0px", border: 'none' }}
                inputStyle={{
                  width: "95%",
                  backgroundColor: 'transparent',
                  height: "48px",
                  borderRadius: "16px",
                  border: "none"
                }}
                value={valphone}
                buttonStyle={{
                  borderRadius: "16px 0 0 16px",
                  backgroundColor: "white",
                  border: 'none'
                }}
                onChange={(e) => validatePhone(e)}
              />
              { visibleIconPhone ? <Image width="2.7%" widthMd="6.5%" marginRight="20px" style={{ width: '6.5%', marginRight: '22px' }} src={True} /> : null }
            </Input>
          </InputWrapper>
        </ContainerOfInputs>
      </form>
    </Fragment>
  );
};

export default ContactoOfBrasil;

export const InputWrapper = styled.div`
    width: 98%;
    padding-bottom: 8px;
`;

export const ContainerOfInputs = styled.div`
    width: 100%;
    padding-top: 12px;
    display: flex;
    gap: 12px;
    justify-content: space-between;
`;
