//  Botón de uso general
//  Para usar el botón hay que pasar el parámetro "typeBtn"
//  Los valores que puede recibir son {active}, {inactive}, {secondary}, {text} e {image}.
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Button = styled.div`
  width: ${(props) => props.Width || '100%'};
  border-radius: 16px;
  margin-top: ${(props) => props.mt || ''};
  border: ${(props) => props.borderColor || ''};
  max-width: ${(props) => props.maxWidth || '100%'};
  margin: ${(props) => props.margin || ''};
   margin-left: ${(props) => props.ml || ''};
  margin-right:${(props) => props.mr || ''};;
  display: ${(props) => props.display || 'block'};
  flex-direction:  ${(props) => props.fd || ' '};
  justify-content:${(props) => props.jc || ' '}; 
  height: ${(props) => props.height || '48px'};
  padding: ${(props) => props.p || '13px 20px'}; 
 
  outline: none;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
   &:hover {
    cursor: pointer;
  } 
  ${(props) =>
    props.typeBtn === 'active' &&
    css`
      border: none;
      background-color: ${props.color || '#f64282'};
      color: ${props.colorText || '#fff'};
    `}
  ${(props) =>
    props.typeBtn === 'inactive' &&
    css`
      border: none;
      background-color: #cdcdcd;
      color: #fff;
      &:hover {
        cursor: auto;
      }
    `};
  ${(props) =>
    props.typeBtn === 'inactive-text' &&
    css`
      border: none;
      background: none;
      color: #cdcdcd;
      &:hover {
        cursor: initial;
      }
    `};
  ${(props) =>
    (props.typeBtn === 'secondary' || props.typeBtn === 'image') &&
    css`
      border: solid 1px ${props.color || '#f64282'};
      background-color: transparent;
      color: ${props.color || '#f64282'};
    `}
  ${(props) =>
    props.typeBtn === 'text' &&
    css`
      border: none;
      background-color: transparent;
      color: ${props.color || '#f64282'};
      text-decoration: underline;
    `}
    ${(props) =>
    props.typeBtn === 'filter' &&
    css`
      border: 1px solid #cdcdcd;
      background-color: #fff;
    `}
    
 
  
  
  
  @media (max-width: 700px) {
     
    width: ${(props) => props.w700 || ""};
    margin-left:${(props) => props.ml700 || "14px"};
    font-size: ${(props) => props.fontS || '16px'};
 }
    @media (max-width: 425px) {
     width: ${(props) => props.w425 || ""};
     margin-left:${(props) => props.ml425 || ""};
   
  }

 @media (max-width: 390px) {
      width: ${(props) => props.w390 || ""};
      margin-left:${(props) => props.ml390 || ""};
   
  }
   @media (max-width: 375px) {
     width: ${(props) => props.w375 || ""};
     margin-left:${(props) => props.ml375 || ""};
   
  }
  @media (max-width: 320px) {
      width: ${(props) => props.w320 || ""};
  }
`;

const Icon = styled.i`
  margin-right: 20px;
`;

function KandaButton({
  typeBtn,
  children,
  action,
  maxWidth,
  margin,
  width,
  display,
  height,
  color,
  borderColor,
  colorText,
  ...otherProps
}) {
  return (
    <Button
      {...otherProps}
      typeBtn={typeBtn}
      onClick={action}
      disabled={typeBtn === 'inactive'}
      maxWidth={maxWidth}
      margin={margin}
      Width={width}
      display={display}
      height={height}
      color={color}
      borderColor={borderColor}
      colorText={colorText}
    >
      {typeBtn === 'image' ? <Icon className="fas fa-home" /> : null}
      {children}
    </Button>
  );
}

KandaButton.propTypes = {
  typeBtn: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  color: PropTypes.string,
};

export default KandaButton;
