import React, { useState, useEffect, Fragment } from 'react';

import { useMutation, useLazyQuery } from '@apollo/client';
import { KandaAvatar } from '../../assets/styles/kanda/KandaAvatar';
import { KandaLogo } from '../../assets/styles/kanda/KandaLogo';
import {
  Button,
  HeaderKanda,
  KandaStyle,
  Message,
  Messages,
  MessagesContainer,
  PicKandaBot,
  Text,
  TextList,
} from '../../assets/styles/kanda/KandaContent.style';
import { GET_STATUS_VISIT_KANDA, GET_URL_KANDA } from '../../queries';
import { SET_VISIT_KANDA } from '../../mutations';
import ClickButton from '../../Helpers/HookHelpers';

const Kanda = ({ goToDiagnostico = '' }) => {
  const [clickeado, setClickeado] = useState(false);
  const [urlKanda, setUrlKanda] = useState('');
  const { actions } = ClickButton();
  const goToKanda = (name) => {
    const pathnameP = window.location.pathname;
    const pathname = pathnameP.split('/')[1];
    const idUser = sessionStorage.getItem('idUser');
    const idCont = sessionStorage.getItem('idCont');
    actions({
      action: 'ClickButtonCoruHome',
      variables: name,
      redirection: `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda#${idUser}#${idCont}`,
      href: true,
    });
  };

  const [getStatusVisitKanda] = useLazyQuery(GET_STATUS_VISIT_KANDA, {
    onCompleted({ getStatusVisitKanda }) {
      try {
        const json = JSON.parse(getStatusVisitKanda.response);
        if (json.length > 0) {
          if (json[0].WoKanda === true) {
            setClickeado(true);
          }
        }
      } catch (error) {
        console.log('Error during query !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getUrlKanda] = useLazyQuery(GET_URL_KANDA, {
    onCompleted({ getUrlKanda }) {
      try {
        const obj = JSON.parse(getUrlKanda.response)[0] || '';
        setUrlKanda(obj.Path);
        sessionStorage.setItem('url_kanda', obj.Path);
      } catch (error) {
        console.log('Error during query !!! ==>>', error);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [setVisitKanda] = useMutation(SET_VISIT_KANDA, {
    onCompleted({ setVisitKanda }) {
      if (setVisitKanda.message === 'success') {
        setClickeado(true);
        goToKanda('Hacermidiagnóstico');
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    const idUser = parseInt(sessionStorage.getItem('idUser') || 0);
    if (idUser) {
      getStatusVisitKanda({
        variables: { idUser },
      });
    }
  }, [sessionStorage.getItem('idUser')]);

  useEffect(() => {
      getUrlKanda({
        variables: { appName: goToDiagnostico.length > 0 ?  goToDiagnostico : 'Kanda'}, // Los tipos de goToDiagnostico son: ['Diagnostico1', 'Diagnostico2']
      });
  }, []);

  const handleClick = () => {
    const pathnameP = window.location.pathname;
    const pathname = pathnameP.split('/')[1];
    const idUser = parseInt(sessionStorage.getItem('idUser') || 0);
    if (idUser) {
      setVisitKanda({
        variables: {
          kandaEntered: 1,
          idUser,
        },
      });
    } else if (pathname === '') {
      actions({
        action: 'ClickButtonCoruHome',
        variables: 'Hacermidiagnóstico',
        redirection: `${urlKanda}?utm_medium=coru&utm_source=Home&utm_campaign=Kanda`,
        href: true,
      });
      // window.location.href = `${urlKanda}?utm_medium=coru&utm_source=Home&utm_campaign=Kanda`;
    } else {
      actions({
        action: 'ClickButtonCoruHome',
        variables: 'Hacermidiagnóstico',
        redirection: `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda`,
        href: true,
      });
      // window.location.href = `${urlKanda}?utm_medium=coru&utm_source=${pathname}&utm_campaign=Kanda`;
    }
  };
  return (
    <KandaStyle hizoClick={clickeado}>
      <HeaderKanda>
        {/* <KandaLogo /> */}
        <Text header>Tu Plan Financiero</Text>
      </HeaderKanda>
      <MessagesContainer>
        <PicKandaBot>
          <KandaAvatar />
        </PicKandaBot>
        <Messages>
          {clickeado ? (
            <Fragment>
              <Message>
                <Text>¡Hola! 🖐</Text>
                <Text>¡Recupera tu relajación financiera!</Text>
              </Message>
              <Button onClick={() => goToKanda('IramiPlan')}>
                <Text button>{/* Ir a Kanda */}Ir a mi plan</Text>
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Message>
                <Text>¡Hola! 🖐</Text>
                <Text>
                  ¿Te gustaría sentirte más tranquilo con tu salud financiera?
                </Text>
              </Message>
              <Message>
                <Text>
                  En {/* Kanda */} Coru te ayudaremos a recuperar tu
                  tranquilidad financiera en 3 sencillos pasos:
                </Text>
              </Message>
              <Message>
                <Text list>
                  <TextList>1</TextList>Realiza tu diagnostico
                </Text>
                <Text list>
                  <TextList>2</TextList>Descubre tu plan personalizado
                </Text>
                <Text list>
                  <TextList>3</TextList>
                  <b>¡Dile adiós al estrés financiero! 🙌</b>
                </Text>
              </Message>
              <Button onClick={() => handleClick()}>
                <Text button>¡Hacer mi diagnóstico!</Text>
              </Button>
            </Fragment>
          )}
        </Messages>
      </MessagesContainer>
    </KandaStyle>
  );
};

export default Kanda;
