import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../assets/styles/Home.css';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Image } from '../../assets/styles/Home.styled';
import History_Clients from '../../components/Home/Carousel';
import {
  Container1,
  Rombo,
  ContainerBottons,
  Rectangle20,
  TitleEntrenamientos,
  SubTexto,
  ContentHomeSection,
  Shapes,
  Rectangle23,
  Rectangle25,
  Rectangle24,
  Vector1,
  Title1,
  Subtitle,
} from '../../components/common/Home.styled';
import {
  Button,
  Container,
  Col6,
} from '../../components/common/common.styled';
import CarrouselEmpresas from '../../components/Home/CarouselEmpresas';
import { CREATE_SESSION, CREATE_CLICK } from '../../mutations';
import { GET_DAY_OF_WEEK } from '../../queries';
import Pesas from '../../assets/img/home/FIT.svg';
import WOlayout from '../../components/layouts/WOlayout';
// Modal
import ClickButton from '../../Helpers/HookHelpers';

const tagManagerArgs = {
  gtmId: 'GTM-NT59K8W',
  dataLayer: {
    pageName: 'home',
  },
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-53999647-2');
ReactGA.pageview(window.location.pathname + window.location.search);

export function debounce(func, wait = 20, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
function analyzeCookie() {
  let galletita = document.cookie;
  let source = '';
  let medium = '';
  let campaign = '';
  let variableEstado = '';
  let date = '';
  let content = '';
  let term = '';
  if (galletita.includes('ReturningSession')) {
    variableEstado = 'ReturningSession';
  } else if (galletita.includes('FirstSession')) {
    variableEstado = 'FirstSession';
  } else {
    variableEstado = 'noencontrada';
  }
  let elementos = galletita.split(';');
  let posicion = 0;
  for (const elem in elementos) {
    if (elementos[elem].includes(variableEstado)) {
      break;
    } else {
      posicion += 1;
    }
  }
  galletita = decodeURIComponent(elementos[posicion]).replace(
    `${variableEstado}=`,
    '',
  );
  elementos = galletita.split('&');
  for (const elem in elementos) {
    if (elementos[elem].includes('source=')) {
      source = elementos[elem].replace('source=', '');
    } else if (elementos[elem].includes('medium=')) {
      medium = elementos[elem].replace('medium=', '');
    } else if (elementos[elem].includes('campaign=')) {
      campaign = elementos[elem].replace('campaign=', '');
    } else if (elementos[elem].includes('term=')) {
      term = elementos[elem].replace('term=', '');
    } else if (elementos[elem].includes('content=')) {
      content = elementos[elem].replace('content=', '');
    } else if (elementos[elem].includes('date=')) {
      date = elementos[elem].replace('date=', '');
    }
  }

  source = source != null && source !== '' ? source : 'direct';
  medium = medium != null && medium !== '' ? medium : 'none';
  campaign = campaign != null && campaign !== '' ? campaign : 'direct';
  sessionStorage.setItem('source', source);
  sessionStorage.setItem('medium', medium);
  sessionStorage.setItem('campaign', campaign);
  sessionStorage.setItem('content', content);
  ReactGA.ga((tracker) => {
    const idGa = tracker.get('clientId');
    sessionStorage.setItem('ID_GA', idGa);
  });
}

function Home({ location }) {
  const { actions } = ClickButton();
  console.log('fijate aqui');
  console.log(process.env.REACT_APP_SERVERTYPE);
  // const [MorePrensa, setMorePrensa] = useState(false);
  const { search } = window.location;
  let params = '';
  let source = '';
  let content = '';
  let medio = '';
  let campaign = '';
  if (search === '' || search == null) {
    sessionStorage.setItem('SearchSource', 'Sin source');
    analyzeCookie();
  } else {
    sessionStorage.setItem('SearchSource', search);
    params = new URLSearchParams(search);
    source = params.get('utm_source');
    medio = params.get('utm_medium');
    content = params.get('utm_content');
    campaign = params.get('utm_campaign');
    sessionStorage.setItem('source', source);
    sessionStorage.setItem('medium', medio);
    sessionStorage.setItem('campaign', campaign);
    sessionStorage.setItem('content', content);
  }
  sessionStorage.setItem('showModal', false);
  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted({ dynamicMongoSession }) {
      if (dynamicMongoSession.statusCode === 200) {
        console.log(dynamicMongoSession.response);
        sessionStorage.setItem('id_session', dynamicMongoSession.response);
        localStorage.setItem('id_session', dynamicMongoSession.response);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [createClickFront] = useMutation(CREATE_CLICK, {
    onCompleted({ createClick }) {
      localStorage.setItem('activityDate', new Date());
    },
    onError(err) {
      console.log(err);
    },
  });
  const history = useHistory();
  const [setScrollY] = useState(window.scrollY);

  const [getDayOfWeek] = useLazyQuery(GET_DAY_OF_WEEK, {
    onCompleted({ getDayOfWeek }) {
      const { today } = getDayOfWeek;
      sessionStorage.setItem('dayOfWeek', today);
    },
    onError(err) {
      console.log(err);
    },
  });

  useEffect(() => {
    getDayOfWeek();
  }, []);

  useEffect(() => {
    ReactGA.ga((tracker) => {
      const idga = tracker.get('clientId');
      console.log(idga);
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) dd = `0${dd}`;
      if (mm < 10) mm = `0${mm}`;
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();
      today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
      const dataJson = {
        idga,
        campaign: sessionStorage.getItem('campaign'),
        medium: sessionStorage.getItem('medium'),
        source: sessionStorage.getItem('source'),
        timestamp: today,
        content: sessionStorage.getItem('content'),
      };
      const dataString = JSON.stringify(dataJson);
      const mongoInput = {
        input: {
          collection: 'sessions',
          data: dataString,
        },
      };
      if (!sessionStorage.getItem('id_session')) {
        createSession({ variables: mongoInput });
      }
    });
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', debounce(handleScroll));
    return () => window.removeEventListener('scroll', debounce(handleScroll));
  }, []);
  sessionStorage.setItem('clickEmpezarAhoraHome', false);
  const initializeNow = (e) => {
    e.preventDefault();
    e.stopPropagation();
    sessionStorage.setItem('clickEmpezarAhoraHome', true);
    const tagManagerArgs = {
      dataLayer: {
        event: 'clickEmpezarAhoraHomeLPFirstInversion',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    today = `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
    const timestamp = today;
    const createClickInput = {
      input: {
        id: sessionStorage.getItem('id_session'),
        column: 'clickEmpezarAhoraHome',
        collection: 'sessions',
        timeStamp: timestamp,
      },
    };
    createClickFront({ variables: createClickInput });
    actions({
      action: 'ClickButtonCollection',
      variables: 'EmpezarAhoraLPFirstInversion',
    });
    history.push(
      '/wo/first-investment?utm_source=facebook&utm_medium=paid&utm_campaign=test-1era-inv&utm_content=landing-1era-inv',
    );
  };
  return (
    <WOlayout
      backgroundColor={'#FAFAFA'}
      pathname={window.location.pathname}
      headerType="HeaderLP"
    >
      <Container1>
        <Shapes
          id="Shapes1"
          background="#FAFAFA"
          key={1}
          top="100px"
          left="20px"
          heightD="555px"
          height="373px"
          width="100%"
          position=" "
        >
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="0rem"
          >
            <ContentHomeSection leftD=" " floatD=" " width="100%">
              <Rectangle23
                Ptop="100px"
                Pleft="80%"
                position="absolute"
                displayD=" "
                displayM="none"
              />
              <Rectangle25
                positionD="absolute"
                left="63%"
                top="107px"
                displayM="none"
                displayD=" "
              />
              <Rectangle24
                positionD="absolute"
                Ptop="120px"
                Pleft="97%"
                displayM="none"
                displayD=" "
                left=" "
                top=" "
              />
              <Vector1
                positionD="absolute"
                Pleft="57%"
                Ptop="2.9%"
                displayM="none"
                displayD=" "
              />
              <Title1 id="Title1" mtopM="80px" className="roboto cabin">
                Aprende a invertir <br /> por primera vez
              </Title1>
              <div className="d-flex flex-column">
                <div className="p-2">
                  <Subtitle
                    id="Subtitle"
                    className="roboto"
                    mtopM="10%"
                    mbottomM="5%"
                  >
                    Prueba nuestra asesoría gratuita y encuentra lugares seguros
                    para invertir.
                  </Subtitle>
                </div>
              </div>
              <ContainerBottons
                position=" "
                floatD="left"
                displayD="block"
                displayM="none"
                onClick={initializeNow}
              >
                <Button className="roboto" onClick={initializeNow}>
                  Comenzar
                </Button>
              </ContainerBottons>
              <Rombo
                displayD=" "
                displayM="none"
                marginleft="50%"
                margintop="0%"
              />
              <Rectangle20 displayD=" " displayM="none" marginleft="103%" />
              <Rectangle23 top="-125%" displayD="none" displayM="block" />
              <Rectangle25 displayD="none" displayM="block" />
              <Rectangle24 displayD="none" displayM="block" />
            </ContentHomeSection>
          </Container>
          <ContentHomeSection
            leftD="0px"
            floatD="right"
            rigthD="0%"
            topD="-485px"
            width="40%"
            displayD=" "
            displayM="none"
          >
            <Shapes
              id="Shapes-medio"
              positionD=" "
              position="absolute"
              zindex="-1"
            >
              <Image
                src={Pesas}
                maxwidth="430px"
                width="25%"
                position="absolute"
                left="0%"
                top="4%"
                displayMobile="none"
              />
            </Shapes>
          </ContentHomeSection>
        </Shapes>
        <div className="row justify-content-center">
          <div className="col-12 col-xs-8">
            <ContainerBottons
              position=" "
              displayD="none"
              displayM="block"
              marginW="auto 1%"
            >
              <Button className="d-block roboto" onClick={initializeNow}>
                Comenzar
              </Button>
            </ContainerBottons>
            <ContainerBottons
              position=" "
              displayD="none"
              displayM="block"
              marginW="auto 1%"
            >
              <Image
                src={Pesas}
                maxwidth="430px"
                margin="auto"
                width="100%"
                position=""
                left="0%"
                top="0%"
                displayMobile="block"
                display="none"
              />
            </ContainerBottons>
          </div>
        </div>
        <Rombo displayD="none" displayM="block" />
        <Rectangle20 displayD="none" displayM="block" />
        <Shapes
          id="Aliados"
          top=" "
          left=" "
          heightD="auto"
          height=" "
          width="100%"
          position=" "
        >
          <Container
            width="80%"
            mt="0rem"
            widthMobile="100%"
            mb="0%"
            mtMobile="0rem"
          >
            <Col6
              pt="0px"
              flex="0 0 100%"
              maxwidth="100%"
              display="inline!important"
              displayM="block"
            >
              <div style={{ margin: 'auto' }}>
                <TitleEntrenamientos top="5%" topD=" " className="roboto">
                  Soluciones financieras personalizadas
                </TitleEntrenamientos>
                <SubTexto
                  bottom="28px"
                  className="roboto"
                  heightD="auto"
                  heightmb1="150px"
                >
                  Cada objetivo es diferente y para cada uno existe un producto
                  financiero que te ayuda a solucionarlo.{' '}
                  <b>
                    {' '}
                    Al registrarte en Coru cuentas con el respaldo de las
                    instituciones más sólidas{' '}
                  </b>{' '}
                  para ayudarte a cumplir tus metas sin comprometer tu
                  estabilidad.
                </SubTexto>
              </div>
              <ContainerBottons
                position=" "
                floatD="left"
                displayD="block"
                displayM="block"
                onClick={initializeNow}
              >
                <Button className="roboto" onClick={initializeNow}>
                  Comenzar
                </Button>
              </ContainerBottons>
            </Col6>
            <Col6
              id="CarruselEmpresasContent"
              pt="0px"
              flex="0 0 100%"
              maxwidth="100%"
              display="flex"
            >
              <CarrouselEmpresas />
            </Col6>
          </Container>
        </Shapes>
        <Shapes
          top=" "
          left=" "
          heightD="auto"
          height=" "
          width="100%"
          position=" "
        >
          <div className="d-flex flex-column ">
            <div className="p-2">
              <ContentHomeSection
                leftD="10% "
                rigthD="10%"
                floatD=" "
                width="80%;"
                displayD=" "
                displayM=" "
              >
                <TitleEntrenamientos
                  top=""
                  topD="5%"
                  widthD="65%"
                  className="roboto"
                  aling="center"
                  displayD="flex"
                  displayM="flex"
                >
                  Juntos somos el cambio financiero
                </TitleEntrenamientos>
                <SubTexto
                  alingD="center"
                  height="98px"
                  bottom="0px"
                  className="roboto"
                >
                  <b>
                    Más de 2.5 millones de mexicanas y mexicanos forman parte de
                    la comunidad de Coru.
                  </b>{' '}
                  Estas son las historias de cómo superaron sus obstáculos y le
                  dijeron adiós al estrés financiero:
                </SubTexto>
              </ContentHomeSection>
            </div>
            <div className="p-2">
              <ContentHomeSection
                leftD="10% "
                rigthD="10%"
                floatD=" "
                width="80%;"
                displayD=" "
                displayM=" "
              >
                <History_Clients></History_Clients>
              </ContentHomeSection>
            </div>
          </div>
        </Shapes>
        <ContainerBottons top="20px" position=" ">
          <Button
            className="d-block mt-5 mt-md-4 roboto"
            onClick={initializeNow}
          >
            Comenzar
          </Button>
        </ContainerBottons>
      </Container1>
      {/* <Whatsapp url={""} id="Whatsapp"/> */}
    </WOlayout>
  );
}

export default Home;
