import React from 'react';
// import { scroller } from 'react-scroll';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

const List = styled.ul`
   white-space: nowrap;
  display: flex;
  
  margin-top: 15px;
  margin-left:0px;
  padding:0px;
  justify-content: ${(props) => props.justifyCS || 'end'};
  flex-direction: row;
  margin-right: ${(props) => props.mr || ' '};
  margin-left: ${(props) => props.left || '210px '};
  gap: ${(props) => props.gap || ''};
  @media (max-width: 1550px) {
   margin-left: ${(props) => props.left || '180px '};
   justify-content: ${(props) => props.justifyCX || ''};
  }
  @media (max-width: 1450px) {
   margin-left: ${(props) => props.left || 'auto '};
   justify-content: ${(props) => props.justifyC || 'end'};
  
  }
  @media (max-width: 1372px) {
   margin-left: ${(props) => props.left || '60px '};
  
  }
  @media (max-width: 1281px) {
   margin-left: ${(props) => props.left || '10px '};
  
  }
   @media (max-width: 1024px) {
    display: flex;
    flex-direction:column;
  }
  @media (max-width: 992px) {
   margin-left: ${(props) => props.left || ' 5px '};
  
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction:column;
    gap: ${(props) => props.gapM || ''};
    justify-content: ${(props) => props.justifyCMi || ''};
    margin-left: ${(props) => props.leftMi || ''};
  }
@media (max-width: 425px) {
    display: flex;
    flex-direction:column;
  }
 @media (max-width: 395px) {
    display: flex;
    flex-direction:column;
  }
  @media (max-width: 375px) {
    display: flex;
    flex-direction:column;
  }
 
`;

const ListItem = styled.li`
   display: inline-block;
  float: none;
  margin: 0 -3px 0 0;
  position: relative;
  list-style: none;
  font-size: 16px;

  flex-wrap: nowrap;
  margin-right: ${(props) => props.mr || ' '};
  width: ${(props) => props.width || ' '};
  margin-left: ${(props) => props.left || ' '};

   @media (max-width: 1024px) {
   margin-top:${(props) => props.mr375 || '20px '};
   display:flex;
   flex-direction:row;
  }

  @media (max-width: 768px) {
   margin-top:${(props) => props.mr375 || '20px '};
   display:flex;
   flex-direction:row;
  }

@media (max-width: 425px) {
   margin-top:${(props) => props.mr375 || '20px '};
   display:flex;
   flex-direction:row;
  }

  @media (max-width: 390px) {
   margin-top:${(props) => props.mr375 || '20px '};
   display:flex;
   flex-direction:row;
  }
  @media (max-width: 375px) {
   margin-top:${(props) => props.mr375 || '20px '};
   display:flex;
   flex-direction:row;
  }

`;

const ItemProx = styled.p`
display:none;
flex-direction: row;

padding: 0px 10px 5px;

position: static;
width: 107px;
height: 29px;
left: 106px;
top: 0px;
font-size: 14px;
background: rgba(107, 213, 175, 0.3);

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 12px;

@media (max-width: 1024px) {
display: flex;

  }
@media (max-width: 768px) {
display: flex;

  }

@media (max-width: 425px) {
display: flex;

  }

@media (max-width: 390px) {
display: flex;

  }
@media (max-width: 375px) {
display: flex;

  }

`;

const NavBar = (props) => {
  const { handleClickCoru, setMenu } = props;
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  const handleClickScroll = (e) => {
    e.preventDefault();
    debugger;
    const target = e.target.getAttribute('href');
    // const location = document.querySelector(target).offsetTop;
    // const winmed = window.matchMedia("(max-width: 700px)");

    // if (winmed.matches){
    // window.scrollTo({ left: 0,top: location - 300,});
    gsap.to(window, { duration: 0.2, scrollTo: target });
  };

  return (
      <List justifyCMi="left" justifyCS="center" justifyC="center" gap="30px" gapM="0" leftMi="0" left="auto" justifyCX="center" style={{ width: '70%' }}>
        <ListItem className="" mr375="0px" mr="" onClick={() => handleClickCoru('TuPlan') && setMenu(false) } >
          <AnchorLink className="roboto a1 " href="#tuplanMobile" onClick={handleClickScroll} >Tu Plan</AnchorLink>
        </ListItem>

        <ListItem className="" mr="s" onClick={() => handleClickCoru('Soluciones') && setMenu(false)} >
          <AnchorLink className="roboto a2" href="#SolucionesMobile" onClick={handleClickScroll}>Soluciones</AnchorLink>
        </ListItem>
        {window.screen.width > 768 ? (
          <ListItem className="" mr="s" onClick={() => handleClickCoru('CatalogoDeProductos') && setMenu(false)} >
            {sessionStorage.getItem('hasSession') !== null ?
              <a className="roboto a4" href="/kanda/catalogo-de-productos?utm_source=coru.com&utm_medium=organic&utmcampaign=menuhome_logged&utm_content=menuhome" target="_blank" rel="noreferrer">Productos</a>
              :
              <a className="roboto a4" href="/kanda/catalogo-de-productos?utm_source=coru.com&utm_medium=organic&utmcampaign=menuhome_notlogged&utm_content=menuhome" target="_blank" rel="noreferrer">Productos</a>
            }
          </ListItem>
        ) : null}
        {window.screen.width <= 768 ? (
          <ListItem className="" mr="s" onClick={() => handleClickCoru('CatalogoDeProductos') && setMenu(false)} >
            <a
              className="roboto a2"
              href={
                sessionStorage.getItem('hasSession') !== null ?
                "/kanda/catalogo-de-productos?utm_source=coru.com&utm_medium=organic&utmcampaign=menuhome_logged&utm_content=menuhome"
                :
                "/kanda/catalogo-de-productos?utm_source=coru.com&utm_medium=organic&utmcampaign=menuhome_notlogged&utm_content=menuhome"
              }
              target="_blank"
            >
              Catálogo de Productos
            </a>
          </ListItem>
        ) : null}
        <ListItem className="" mr="s" onClick={() => handleClickCoru('ForBusiness') && setMenu(false)} >
          <a className="roboto a4" href="/for-business" target="_blank" rel="noreferrer">For Business</a>
        </ListItem>
        <ListItem className="" mr="s" onClick={() => handleClickCoru('Nosotros') && setMenu(false)} >
          <a className="roboto a4" href="/about-us" target="_blank" rel="noreferrer">Nosotros</a> 
        </ListItem>
        {/* <ListItem className="" mr="s" onClick={() => handleClickCoru('CoruMasterClass') && setMenu(false)}>
          <AnchorLink className="roboto a3" href="#masterclassMobile" onClick={handleClickScroll}>Coru Master Class®</AnchorLink>
          <ItemProx className="roboto">Próximamente </ItemProx>
        </ListItem>
        <ListItem className="" mr="s" onClick={() => handleClickCoru('XCoach') && setMenu(false)} >
          <AnchorLink className="roboto a4" href="#xcoachmobile" onClick={handleClickScroll}>XCoach</AnchorLink>
          <ItemProx className="roboto">Próximamente </ItemProx>
        </ListItem> */}
        <ListItem className="" onClick={() => handleClickCoru('PreguntasFrecuentes') && setMenu(false)} >
          <AnchorLink className="roboto a5" href="#preguntasMobile" onClick={handleClickScroll}>FAQs</AnchorLink>
        </ListItem>
      </List>

  );
};

export default NavBar;
